import React, { useState, useRef, useEffect } from 'react'; // useState 추가
import styled from 'styled-components';
import HeroContainer from '../../Layouts/HeroContainer';
import { IoIosAddCircleOutline } from "react-icons/io";
import ImgComponent from '../../Layouts/ImgComponent';
import Swiper from './MembersLayout/Swiper';
import EmptyPart from '../../Layouts/EmptyPart';
import DepartmentSwiper from './MembersLayout/DepartmentSwiper';

function Members() {
  const [isHovered, setIsHovered] = useState('');

  return (
    <Container>
      <HeroContainer imgPath={"/ImgSrc/MembersHero.webp"} pageName={"MEMBERS"} />
      <DepartmentContainer>
        <div style={{ width: "100%" }}>
          <Text_Hero>Aurum Department</Text_Hero>
        </div>
        <DepartmentDivider>
          {Department.map((Dep) => (
            <DepartmentCard BackGroundImage={Dep.BackGround}>
              <Text_Message>{Dep.Title}</Text_Message>
              <Text_SubMessage>{Dep.sub}</Text_SubMessage>
            </DepartmentCard>
          ))}
        </DepartmentDivider>
        <With>With</With>
        <DepartmentSlide>
          <DepartmentSwiper />
        </DepartmentSlide>
      </DepartmentContainer>
      <EmptyPart />

      <MembersContainer>
        <LineDiv>
          <Line />
          <BrandTag>
            구성원 소개
          </BrandTag>
        </LineDiv>
        <MemberDiv>
          <Swiper />
        </MemberDiv>
      </MembersContainer>
      <EmptyPart />
    </Container>
  );
}

export default Members;

const Container = styled.div`
  background: ${({ theme }) => theme.colors.BoxColor};
  ${({ theme }) => theme.common.flexCenterColumn};
  width: 100%;
  height: auto;
  color: white;
`;
const MembersContainer = styled.div`
  width: 100%;
  height: auto; /* 이 부분을 조정하세요 */ 
  background : transparent;
  position : relative;
`



//-----------------------------------------------------------


const Text_Message = styled.h3`
  ${({ theme }) => theme.fontStyle.Text_Message};
  text-align : left;
  `
const Text_SubMessage = styled.p`
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
  text-align : left;
`

const Text_Hero = styled.h1`
${({ theme }) => theme.fontStyle.Text_Hero};
text-align : left;
`


const LineDiv = styled.div`
  width: 100%;
  position : absolute;
  top : 0;
`
const Line = styled.div` 
  width: 100%;
  border : 2px solid #fff;
  position : absolute;
  top : 50%;
  transform: translateY(-50%);
`
const BrandTag = styled.div`
  ${({ theme }) => theme.fontStyle.Text_Hero};
  margin :0;
  border-radius: 40px;
  background: #000;
  position : absolute;
  top : 50%;
  left : 50%;
  transform: translate(-50%,-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border : 2px solid #fff;
  padding : 20px 40px;
  @media screen and (max-width: 900px) {
    padding : 10px 20px;
  }
`
const MemberDiv = styled.div`
  width:100%;
  height : auto;
  ${({ theme }) => theme.common.flexCenterColumn};
  padding : 20%;
  @media screen and (max-width: 900px) {
    padding : 30% 2%;
  }
  border : none;
`

/////시험용 디자인

const DepartmentContainer = styled.div`
  width : 100%;
  display:flex;
  flex-flow : column nowrap;
  height : auto;
  padding : 10% 20%;
  color : white;
  gap : 30px;
  @media screen and (max-width: 900px) {
    padding : 10% 5%;
  }
  `
const DepartmentDivider = styled.div`
width : 100%;
display:grid;
grid-template-columns : 1fr 1fr;
@media screen and (max-width: 900px) {
  grid-template-columns : 1fr;
}
gap : 30px;
`
const DepartmentCard = styled.div`
display:flex;
flex-flow : column wrap;
justify-content : center;
align-items : start;
border-bottom : 3px solid grey;
border-right : 3px solid grey;
border-radius : 25px;
text-align : left;
padding : 10% 20% 10% 5%;
background: linear-gradient(	
  115deg, 
  rgba(0, 255, 0, 0.2) 0%, 
  rgba(0, 50, 0, 0.5) 10%, 
  rgba(0, 0, 0, 0.6) 20%, 
rgba(0, 0, 0, 0.8) 100%), 
url(${({ BackGroundImage }) => BackGroundImage});
background-size: cover;
background-position: center;
background-repeat: no-repeat;
@media screen and (max-width: 900px) {
  padding : 10%;
}
transition : all ease 0.2s;
  &:hover{
    transform : scale(1.1);
  }
`
const DepartmentSlide = styled.div`
display:flex;
width : 100%;
height : 400px;
border-radius : 50px;
overflow : hidden;
`
const With = styled.div`
  ${({ theme }) => theme.fontStyle.Text_Hero};
  margin : 0;
  display:flex;
  width : 100%;
  font-style :italic;
  line-height: 100%;
  border-bottom : 3px solid white;
`
const Department = [
  {
    index: 1,
    Title: "인프라 사업부",
    sub: "다양한 브랜드/서비스들이 고객들에게 더 효과적으로 나아갈 수 있는 인프라를 준비합니다.",
    BackGround: "/ImgSrc/Members1.webp"
  },
  {
    index: 2,
    Title: "마케팅 사업부",
    sub: "오럼의 브랜드/서비스들의 가치와 기능이 온라인과 메타버스로 구현되도록 서버를 구축합니다.",
    BackGround: "/ImgSrc/Members2.webp"
  },
  {
    index: 3,
    Title: "IT 사업부",
    sub: "오럼의 브랜드/서비스들의 가치와 기능이 온라인과 메타버스로 구현되도록 서버를 구축합니다.",
    BackGround: "/ImgSrc/Members3.webp"
  },
  {
    index: 4,
    Title: "미디어 사업부",
    sub: "새로운 미디어 트렌드를 선도하고 가장 효과적인 방식의 broadcast 전략을 추구합니다.",
    BackGround: "/ImgSrc/Members4.webp"
  }
];
