import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import ImgComponent from '../../Layouts/ImgComponent';
import EmptyPart from '../../Layouts/EmptyPart';

const Recruitment1 = () => {
    // 모바일 판별
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        // 모바일 판별 : 화면 창 트래킹
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <Container>
            <FirstContainer>
                <BeforeFirstContainer>
                    <div style={{ padding: "10%"}}><Text_Message>“우리는 <br />이런 인재와 함께 합니다”</Text_Message></div>
                    {isMobile ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="700" viewBox="0 0 12 660" fill="none">
                            <path d="M0.666667 6C0.666667 8.94552 3.05448 11.3333 6 11.3333C8.94552 11.3333 11.3333 8.94552 11.3333 6C11.3333 3.05448 8.94552 0.666666 6 0.666667C3.05448 0.666667 0.666666 3.05448 0.666667 6ZM0.66685 4193C0.66685 4195.95 3.05466 4198.33 6.00018 4198.33C8.9457 4198.33 11.3335 4195.95 11.3335 4193C11.3335 4190.05 8.9457 4187.67 6.00018 4187.67C3.05466 4187.67 0.666849 4190.05 0.66685 4193ZM5 6L5.00018 4193L7.00018 4193L7 6L5 6Z" fill="url(#paint0_linear_419_2367)" />
                            <defs>
                                <linearGradient id="paint0_linear_419_2367" x1="6.5" y1="6" x2="6.50018" y2="4193" gradientUnits="userSpaceOnUse">
                                    <stop offset="0.0645538" stop-color="#25D366" />
                                    <stop offset="0.992079" stop-color="#022F13" />
                                </linearGradient>
                            </defs>
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="2500" viewBox="0 0 12 4199" fill="none">
                            <path d="M0.666667 6C0.666667 8.94552 3.05448 11.3333 6 11.3333C8.94552 11.3333 11.3333 8.94552 11.3333 6C11.3333 3.05448 8.94552 0.666666 6 0.666667C3.05448 0.666667 0.666666 3.05448 0.666667 6ZM0.66685 4193C0.66685 4195.95 3.05466 4198.33 6.00018 4198.33C8.9457 4198.33 11.3335 4195.95 11.3335 4193C11.3335 4190.05 8.9457 4187.67 6.00018 4187.67C3.05466 4187.67 0.666849 4190.05 0.66685 4193ZM5 6L5.00018 4193L7.00018 4193L7 6L5 6Z" fill="url(#paint0_linear_419_2367)" />
                            <defs>
                                <linearGradient id="paint0_linear_419_2367" x1="6.5" y1="6" x2="6.50018" y2="4193" gradientUnits="userSpaceOnUse">
                                    <stop offset="0.0645538" stop-color="#25D366" />
                                    <stop offset="0.992079" stop-color="#022F13" />
                                </linearGradient>
                            </defs>
                        </svg>
                    )}
                </BeforeFirstContainer>
                <AfterFirstContainer>
                </AfterFirstContainer>
            </FirstContainer>
            {ColumnList.map((col, index) => (

                <ColumnContainer>
                    <ImgDiv BackGroundImage={col.BackGround} />

                     <Index>
                        {col.index}.{col.Title}
                    </Index>

                    <InColumn>
                        <Author>{col.sub}</Author>
                        -{col.author}-
                        <InColumnText>
                            {col.content}
                        </InColumnText>
                    </InColumn>
                </ColumnContainer>
            ))}
        <EmptyPart/>
        </Container>
    );
};

export default Recruitment1;
const Container = styled.div`
  background: ${({ theme }) => theme.colors.BoxColor};
  ${({ theme }) => theme.common.flexCenterColumn};
  width: 100%;
  height: auto;
  color: white;
`;

const FirstContainer = styled.div`
  width: 100%;
  height: 300vh; 
  position : relative;
  padding : 10% 0;
  color: white;
  @media screen and (max-width: 900px) {
      height: 200vh; 
    padding : 3% 0;
    }
`;

const BeforeFirstContainer = styled.div`
  ${({ theme }) => theme.common.flexCenterColumn};
  width: 100%;
  height: auto;
  position : absolute;
  top : 50%;
  left : 50%;
  transform : translate(-50%,-50%);
 
`;
const AfterFirstContainer = styled.div`
  ${({ theme }) => theme.common.flexCenterColumn};
  width: 100%;
  height: auto;
  position : absolute;
  top : 50%;
  left : 50%;
  transform : translate(-50%,-50%);
  z-index : 5;
`;

const ColumnContainer = styled.div`
  width: 100%;
  height: auto;
  display : flex;
  flex-flow: row wrap;
  justify-content : flex-start;
  align-items : flex-start;
  text-align : left;
  padding : 5% 30%;
  @media screen and (max-width: 900px) {
    padding : 5% 0%;
    border-bottom : 1px solid grey;
}

`;
const Index = styled.div`
  width: 300px;
  height: auto;
  display : flex;
  flex-flow: row wrap;
  color : #25D366;
  justify-content : flex-start;
  ${({ theme }) => theme.fontStyle.Text_Message};
  text-align : left;
 @media screen and (max-width: 900px) {
    padding : 5%;
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: 0;
    order: 0;
    margin-bottom: 20px;
    width: 100%;
  }
`;
const InColumn = styled.div`
  width: 100%;
  height: auto;
  justify-content : flex-start;
  max-width: 680px;
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
  text-align : left;
  display : flex;
  flex-flow : column;
  @media screen and (max-width: 900px) {
    padding : 0 5%;
    -webkit-box-ordinal-group:1;
    -ms-flex-order: 1;
    order: 1;
  }
`;
const Author = styled.div`
  width: 80%;
  ${({ theme }) => theme.fontStyle.Text_Point};
  text-align : left;
  margin-bottom : 0;
`;
const InColumnText = styled.div`
  width: 100%;
  height: auto;
  display : flex;  
  flex-flow: column wrap;
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
  text-align : left;
  margin : 20px 0;
`;
const ImgDiv = styled.div`
  background:url(${({ BackGroundImage }) => BackGroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width : 980px;
  height : 600px;
  -webkit-box-ordinal-group:0;
  -ms-flex-order: 0;
  order: 0;
  margin-bottom: 80px;

  @media screen and (max-width: 900px) {
    width : 100%;
    height : 300px;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 1;
    order: 1;
  }
`

const Text_Message = styled.h3`
  ${({ theme }) => theme.fontStyle.Text_Message};
  text-align : center;
`

const ColumnList = [
    {
        index: "01",
        Title: "꿈을 꾸는 자, Dreamer",
        sub: "꿈을 꿀 수 있다면, 꿈을 실현할 수도 있다",
        author: "월트 디즈니",
        content: "오럼은 ‘세상을 더 나은 곳으로 바꾸려는’ 꿈을 꾸는 사람들로 구성되어 있습니다. 어떤 꿈은 뾰족하고, 어떤 꿈은 둥그렇습니다. 똑같은 꿈도 어떨 때는 뜨겁고, 어떨 때는 차갑습니다. 하지만 우리가 꾸는 꿈은 항상 세상에 이(利)로워야 하고, 우리 주변의 친구들, 가족들 그리고 우리가 속한 공동체의 행복에 도움이 되는 꿈이어야 합니다. 좋은 꿈을 꾸는 사람들이, 저희와 함께 하길 바랍니다.",
        BackGround: "/ImgSrc/Recruitment1_1.webp"
    },
    {
        index: "02",
        Title: "말하고 듣는 자, Communicator",
        sub: "나의 언어의 한계는, 나의 세계의 한계를 의미한다",
        author: "비트겐슈타인",
        content: "오럼은 말할 수 없는 것 조차 소통하려는 ‘수다쟁이’ 들로 이루어져 있습니다. 우리의 수다는, 입으로, 글자로, 자료로, 문서로, 영상으로, 그림으로, 기호로, 도식으로, SNS로, 문자로, 카톡으로, 리플로, 게시글로, 태그로, 몸짓으로, 눈짓으로, 손짓으로, 고함으로, 침묵으로, 눈물과 웃음으로.때로는 치열하게, 때로는 건조하게, 때로는 그 대화에 참여하는 모든 사람의 인생을 바꿔 놓을 이야기가 매일매일 일상적으로 펼쳐집니다. 우리의 수다는 스토리를 만들고, 이야기를 풀어가며, 세상을 향한 이야기가 됩니다. 대화의 힘을 움켜쥔 사람들이, 저희와 함께 하길 바랍니다.",
        BackGround: "/ImgSrc/Recruitment1_2.webp"
    },
    {
        index: "03",
        Title: "움직이는 자, Performancer",
        sub: "알고도 행하지 않으면, 정말 아는 것이 아니다",
        author: "왕양명",
        content: "오럼은 ‘충분히 숙고하고, 뚜렷하게 움직이는‘ 사람들이 만들어갑니다. 많은 기획안이, 많은 아이디어가, 많은 가능성이 매일같이 펼쳐지는 회사는 시끄럽습니다. 하지만 그런 모든 것보다 시끄러운 것은, 한 구성원의 작지만 확실한 액션, 신속하고 명확한 행동입니다. 저희는 한 때의 이목을 부여잡는 멋드러진 말보다, 고객의 마음을 울리는 본질적인 행동과 결과로 보여줍니다. 먼 미래의 큰 생각보다, 당장의 작은 행동을 즐기는 분들이, 저희와 함께 하길 바랍니다.",
        BackGround: "/ImgSrc/Recruitment1_3.webp"
    }
];
