import styled from 'styled-components';
import HeroContainer2 from '../../Layouts/HeroContainer2';
import ImgComponent from '../../Layouts/ImgComponent';
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import EmptyPart from '../../Layouts/EmptyPart';

function Entertainment() {
  const navigate = useNavigate();
  const handleClick = (url) => {
    navigate(url);
  };

  return (
    <Container>
      <HeroContainer2 imgPath={"/ImgSrc/EntertainmentHero.webp"}>
        <HeroMessage>
          <Text_SubMessage>너무 재미있는,</Text_SubMessage>
          <Text_SubMessage>페인 킬러가 아닌 비타민?</Text_SubMessage>
          <Text_SubMessage>아니아니..</Text_SubMessage>
          <Text_Point>우리는, 캔디와 초콜렛</Text_Point>
          <Text_SubMessage>계속 맛보고 싶은,<br />
            우리의 엔터테인먼트</Text_SubMessage>

          <Text_Message>“심지어 눈물도 즐거운”</Text_Message>
          <Text_Paragraph>Aurum, <br />
            -marketing department representative, cheif-</Text_Paragraph>

        </HeroMessage>
      </HeroContainer2>
      <ProductContainer>
        <AskToDiv>
          <AskFor>
            <Text_Hero>ASK FOR <br />ENTERTAINMENT</Text_Hero>
          </AskFor>

          <StyledContainer>
            <StyledBox width={"15px"} color={'#00FF00'} />
            <StyledBox width={"30px"} color={'#00FF00'} />
            <StyledBox width={"80px"} color={'#00FF00'} />
            <StyledBox width={"180px"} color={'#00FF00'} />
          </StyledContainer>
          <ContactDiv onClick={() => handleClick('/QnA')}>
            <Text_Message style={{ marginBottom: "0" }} >CONTACT US</Text_Message>
            <ContactArrow />
          </ContactDiv>

        </AskToDiv>
        <CodeContainer>
          <CodeDiv>
            <Text_Paragraph>***</Text_Paragraph>
            <Text_Paragraph>
              오럼 고유 코드가 있으신 고객사분들은 본 창구가 아닌 사전에 안내된 창구를 활용해주시기 바랍니다
            </Text_Paragraph>
          </CodeDiv>
        </CodeContainer>
        <ProductDiv>
          <ProductWrap color={'#FFFEF8'}>
          <ProductCard >
            <CardItem>
              <Text_Message>시즈닝 [Seasoning]</Text_Message>
              <>
                시즈닝은, 엔터테인먼트 분야와 스타일 라인을 결합하여,
                트렌디한 패션 제품들을 고객들에게 선보이는, 마케팅 서비스입니다.
              </>
            </CardItem>
            <CardImg imagePath={"Entertainment1.webp"}/>
          </ProductCard>
          </ProductWrap>
          <ProductWrap color={'#FFF8F8'}>
          <ProductCard >
            <CardItem>
              <Text_Message>달콤 [dalkomm]</Text_Message>
              <>
                달콤은, 엔터테인먼트 분야와 코스메틱 라인을 결합하여
                신뢰성 있는 뷰티 제품을 고객들에게 선보이는, 마케팅 서비스 입니다.
              </>
            </CardItem>
            <CardImg imagePath={"Entertainment2.webp"}/>
          </ProductCard>
          </ProductWrap>
          <ProductWrap color={'#F8FCFF'}>

          <ProductCard>
            <CardItem>
              <Text_Message>다즐링 [dhazzling]</Text_Message>
              <>
                다즐링은, 엔터테인먼트 분야와 이벤트 라인을 결합하여
                매력적인 신상품들을 고객들에게 선보이는, 마케팅 서비스 입니다.</>
            </CardItem>
              <CardImg imagePath={"Entertainment3.webp"}/>
          </ProductCard>
          </ProductWrap>

        </ProductDiv>
        <EmptyPart />
      </ProductContainer>
    </Container>
  );
}

export default Entertainment;

const Container = styled.div`
    background : ${({ theme }) => theme.colors.BoxColor};
    ${({ theme }) => theme.common.flexCenterColumn};
    width : 100%;
    height : auto;
    color : black;
    overflow-x : hidden;
`
const HeroMessage = styled.div`
  ${({ theme }) => theme.common.flexCenterColumn};
  width : 100%;
  gap : 10px;
  padding : 
`
const ProductContainer = styled.div`
    ${({ theme }) => theme.common.flexCenterColumn};
    width : 100%;
    height : auto;
    background : #fff;
`
const AskToDiv = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;
    padding : 10%;
    text-align : left;
    @media screen and (max-width: 900px) {
      flex-direction : column;
      align-items : flex-start;
      background : #fff;
    }
`
const AskFor = styled.div`
    margin : 0 20% 0 0;
    @media screen and (max-width: 900px) {
      margin : 0;
      color : black;
    }
   
`

const StyledContainer = styled.div`
    display : flex;
    gap : 20px;
    @media screen and (max-width: 900px) {
      display : none;
    }
`
const StyledBox = styled.div`
    width :   ${({ width }) => width};
    height : 164px;
    background :   ${({ color }) => color};
`

const ContactDiv = styled.div`
  margin : 0 0 0 -2%;
  @media screen and (max-width: 900px) {
    width : 90%;
    margin : 5% 0 0 0%;
    color : #fff;
    background : #000;
    border-radius:15px;
    padding : 15px;
    display : flex;
    justify-content : space-between;
    align-items : center;
  }
`

const ContactArrow = styled(IoIosArrowForward)`
  display:none;
  color : #fff;
  @media screen and (max-width: 900px) {
    display:flex;
    font-size : 6vmin;
  }
`
const CodeContainer = styled.div`
  width : 100%;
  height : auto;
  display : flex;
  justify-content : center;
  padding : 10%;
  @media screen and (max-width: 500px) {
    padding : 5% 2%;
  }
`
const CodeDiv = styled.div`
  width : 80%;
  height : auto;
  display : flex;
  flex-direction : column;
  align-items : center;
  text-align : center;
  background : #FBFBFB;
  padding : 5% 10%;
  @media screen and (max-width: 500px) {
    padding : 5% 2%;
  }
`
const ProductDiv = styled.div`
  width : 100%;
  height : auto;
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content :center; 
`
const ProductWrap= styled.div`
  width : 100%;
  height : auto;
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content :center; 
  background :  ${({ color }) => color};
  padding : 10% 30%;
  gap : 200px;
  @media screen and (max-width: 900px) {
    gap : 150px;
  padding : 10% 5%;
  }
`
const ProductCard = styled.div`
  width : 100%;
  height : auto;
  display : flex; 
  justify-content : center;
  align-items : center;
  flex-flow : row wrap;
  padding : 10%;
  gap : 50px;
  @media screen and (max-width: 900px) {
    display : flex; 
    justify-content : center;
    align-items : center;
    flex-flow : column wrap;
    padding : 10% 0%;
  }
`
const CardItem = styled.div`
  display : flex; 
  flex : 1;
  flex-flow : column wrap;
  justify-content : center;
  align-items : flex-start;
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
  text-align : left;
  @media screen and (max-width: 900px) {
    width : 100%;
    flex : 1;
  }
`

const ImageContainer = styled.div`
  object-fit: cover;
  background-color: transparent;
  display : flex; 
  flex : 1;
  justify-content : center;
  align-items : center;
   @media screen and (max-width: 900px) {
    width : 100%;
  }
`;

const CardImg = ({ imagePath}) => {
  return (
    <ImageContainer>
      <img
        src={`${process.env.PUBLIC_URL}/ImgSrc/${imagePath}`}
        alt="페이지 이미지"
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
        }}
      />
    </ImageContainer>
  );
};

const Text_Message = styled.h3`
  ${({ theme }) => theme.fontStyle.Text_Message};
`
const Text_SubMessage = styled.p`
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
`
const Text_Hero = styled.h2`
  ${({ theme }) => theme.fontStyle.Text_Hero};
  text-align : left;
`
const Text_Paragraph = styled.p`
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
`
const Text_Point = styled.p`
  ${({ theme }) => theme.fontStyle.Text_Point};
`
