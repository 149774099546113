import React from 'react';
import styled from 'styled-components';
import { Navigate, useNavigate } from 'react-router-dom';

const LoggedPage = ({ setUser, user }) => {
  const navigate = useNavigate();

    return (
        <Container>
            <Header>게시글 카테고리
                <Text_SubMessage>작성하실 글의 카테고리를 선택해주세요!</Text_SubMessage>
            </Header>
            <ButtonDiv>
                {Menulist.map((menu, index) => (
                    <Button onClick={() => navigate(menu.Url)}>
                        {menu.Title}
                    </Button>

                ))}
                    {user.Role &&<Button onClick={() => navigate('/MemberManage')}>
                       승인 관리
                    </Button>}
            </ButtonDiv>
        </Container>
    );
};

export default LoggedPage;
const Container = styled.div`
background: white;
${({ theme }) => theme.common.flexCenterColumn};
width: 100%;
color: black;
padding : 20%;
@media screen and (max-width: 900px) {
    padding : 20% 2%;
  }
`;
const Header = styled.div`
${({ theme }) => theme.fontStyle.Text_Message};
text-align : center;
width :100%;
${({ theme }) => theme.common.flexCenterColumn};

`

const ButtonDiv = styled.div`
width: 100%;
display : flex;
flex-flow : row wrap;
justify-content : center;
align-items : center;
`;

const Button = styled.button`
${({ theme }) => theme.fontStyle.Text_Point};
border-radius: 12px;
border: 1px solid #E2E2E2;
background: #F9F9F9;
width : 35%;
height : 100px;
justify-content: center;
align-items: center;
margin : 20px;
`;


const Text_SubMessage = styled.p`
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
`
const Menulist = [
    {
        index: 1,
        Title: "안내드립니다",
        Url: "/TextEditor",
    },
    {
        index: 2,
        Title: "CAREERS",
        Url: "/CareerEdit",
    },
    {
        index: 3,
        Title: "마이페이지",
        Url: "/Mypage",
    },
    {
        index: 4,
        Title: "오럼 갤러리",
        Url: "/GalleryEditor",
    },
    {
        index: 5,
        Title: "문의 게시판",
        Url: "/QnAList",
    },
    {
        index: 6,
        Title: "사내게시판",
        Url: "/CommunityPost",
    },
    {
        index: 7,
        Title: "흔적들",
        Url: "/LegacyEditor",
    }
];
