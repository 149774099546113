import React from 'react';
import styled from 'styled-components';

const EmptyPart = () => {
    return (
        <Empty>
            
        </Empty>
    );
};

export default EmptyPart;

const Empty = styled.div`
width : 100%;
height : 200px;
background: linear-gradient(to bottom, rgba(242,242,242,0) 98%, rgba(242,242,242,1) 100%);
`