import React, { useState } from 'react';
import styled from 'styled-components';
import api from '../../utils/api';
import { Navigate, useNavigate } from 'react-router-dom'
import { IoLockClosedOutline, IoPersonOutline } from "react-icons/io5";

const Login = ({ setUser, user }) => {

  const [logid, setLogid] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const onIDHandler = (event) => {
    setLogid(event.currentTarget.value);
  }
  const onPasswordHandler = (event) => {
    setPassword(event.currentTarget.value);
  }



  const onSubmitHandler = async (event) => {

    event.preventDefault();
    try {
      const response = await api.post('/user/login', { logid, password });
      if (response.status == 200) {
        setUser(response.data.user)
        sessionStorage.setItem("token", response.data.token)
        api.defaults.headers["authorization"] = "Bearer " + response.data.token;
        setError("")
        navigate('/LoggedPage')
      }
      else {
        throw new Error(response.data.error)
      }
    } catch (error) {
      setError(error.message);
    }

  }
  if (user) {
    return <Navigate to="/" />
  }
  return (
    <Container>
      <ResisterBox>
        <PageInfo>
          <h1>로그인 </h1>
          <p>오럼의 구성원만 로그인이 가능합니다.</p>
          {error && <p style={{ color: "red" }}><b>{error}</b></p>}
        </PageInfo>
        <FormBox onSubmit={onSubmitHandler}>
          <ResisterDiv>
            <User />
            <PlaceHold>아이디</PlaceHold>
            <InputBox
              type='text'
              name='ID'
              placeholder='아이디를 입력해주세요'
              value={logid}
              onChange={onIDHandler}
            />
          </ResisterDiv>
          <ResisterDiv>
            <Loked />
            <PlaceHold>비밀번호 </PlaceHold>
            <InputBox
              type='password'
              name='비밀번호'
              placeholder='비밀번호를 입력해주세요'
              value={password}
              onChange={onPasswordHandler}
            />
          </ResisterDiv>
          <StyleBtn type="submit">
            로그인
          </StyleBtn>
          <RegisterDiv>
            <p>아직 계정이 없으신가요?</p>
            <GotoRegister onClick={()=>navigate('/Register')}>회원가입</GotoRegister>
          </RegisterDiv>
        </FormBox>
      </ResisterBox>
    </Container>
  );
};

export default Login;

const Container = styled.div`
    background : ${({ theme }) => theme.colors.BoxColor};
    ${({ theme }) => theme.common.flexCenterColumn};
    width : 100%;
    height : 100vh;
    color : white;
    overflow-x : hidden;
    
`

const ResisterBox = styled.div`
  ${({ theme }) => theme.common.flexCenterColumn};
  width : 100%;
  padding : 10% 30%;
  @media screen and (max-width: 900px) {
    padding : 5%;
  }
`
const PageInfo = styled.div`
  ${({ theme }) => theme.common.flexCenterColumn};
  width : 100%;
  padding : 3% 10%;
  text-align : center;
  gap : 20px;
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
`

const FormBox = styled.form`
  ${({ theme }) => theme.common.flexCenterColumn};
  gap: 20px;
  width : 100%;
  padding : 3% 30%;
  @media screen and (max-width: 900px) {
    padding : 0%;
  }
`

const ResisterDiv = styled.div`
  display:flex;
  justify-content : center;
  align-items : center;
  width : 100%;
  border: 1px solid red;
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  border: 1px solid #F0BB00;
  background: #212121;
  border-radius: 36px;
  padding: 14px;
  gap : 10px;
`
const PlaceHold = styled.label`
  display: flex;
  align-items: center;
  color: white;
  background: transparent;
  ${({ theme }) => theme.fontStyle.Text_Paragraph};

`
const InputBox = styled.input`
  width: 100%;
  color: white;
  display: flex;
  background-color: transparent;
  border : none;
  height : 100%;
  outline:none;
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
`
const StyleBtn = styled.button` 
  width: 100%;
  background: #F0BB00;
  color : black;
  border-radius: 4px;
  justify-content: center;
  align-items: center;  
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  border-radius: 36px;
  outline:none;
  border : none;
  padding: 14px;
`
const Loked = styled(IoLockClosedOutline)` 
font-size : 2.5vmin;
@media screen and (max-width: 900px) {
  font-size : 3vmax;
}
`
const User = styled(IoPersonOutline)` 
font-size : 2.5vmin;
@media screen and (max-width: 900px) {
  font-size : 3vmax;
}
`
const RegisterDiv = styled.div` 
  width: 100%;
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  display : flex;
  justify-content : space-between;
  margin : 20px 0;
`

const GotoRegister = styled.button` 
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  display : flex;
  border : none;
  background : transparent;
  color : white;
  padding : 0 10px;
`