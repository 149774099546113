import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import HeroContainer from '../../Layouts/HeroContainer';
import ImgComponent from '../../Layouts/ImgComponent';
import { IoLogoTwitter, IoLogoYoutube, IoLogoInstagram, IoIosText } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
const { kakao } = window

function KaKao() {
 

  useEffect(() => {
    // 마커가 표시될 위치입니다 
    const markerPosition = new kakao.maps.LatLng(37.58395017880105, 127.03019200820412);

    // 마커를 생성합니다
    const marker = new kakao.maps.Marker({
      position: markerPosition,
      text: '오럼이 도사리는'
    });

    const container = document.getElementById('map');
    const options = {
      center: new kakao.maps.LatLng(37.58395017880105, 127.03019200820412),
      level: 1,
      marker: marker
    };
    const map = new kakao.maps.Map(container, options);
    // 마커가 지도 위에 표시되도록 설정합니다
    marker.setMap(map);
    // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
    const iwPosition = new kakao.maps.LatLng(markerPosition); //인포윈도우 표시 위치입니다
    const iwContent = `
    <div style='color:black; text-align : center; margin : 10px;'>
        <p style='font-size: 1rem;'>오럼이 도사리는 곳</p>
        <p style='font-size: 0.8rem; color: gray;'>서울시 성북구 고려대로 26길 49, 태종빌딩 2층</p>
    </div>
    `;

    // 인포윈도우를 생성합니다
    const infowindow = new kakao.maps.InfoWindow({
      position: iwPosition,
      content: iwContent
    });

    // 마커 위에 인포윈도우를 표시합니다. 두번째 파라미터인 marker를 넣어주지 않으면 지도 위에 표시됩니다
    infowindow.open(map, marker);
  }, []);


  return (
    <div id='map' style={{ width: "100%", height: "100%" }}>
    </div>
  )
}
function CoopInfo() {
  const imageList = ["/ImgSrc/Coop1.webp", "/ImgSrc/Coop2.webp", "/ImgSrc/Coop3.webp", "/ImgSrc/Coop5.webp", "/ImgSrc/Coop6.webp", "/ImgSrc/Coop5.webp",
    "/ImgSrc/Coop4.webp", "/ImgSrc/Coop1.webp", "/ImgSrc/Coop2.webp", "/ImgSrc/Coop3.webp",
    "/ImgSrc/Coop4.webp", "/ImgSrc/Coop6.webp",]

  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 6;
  const totalPages = Math.ceil(imageList.length / imagesPerPage);
  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = imageList.slice(indexOfFirstImage, indexOfLastImage);
  const navigate = useNavigate();
  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Container>
      <HeroContainer imgPath={"/ImgSrc/CoopinfoHero.webp"} pageName={"회사INFO"} />
      <InfoContainer>
        <div style={{ marginBottom: "50px" }}><Text_large>CONTACT US</Text_large></div>
        <ContactContainer>
          <ContactInfoContainer>
            <InfoDiv>
              <InfoSpan>ADRESS</InfoSpan>
              서울시 성북구 고려대로 26길 49, 태종빌딩 2층
            </InfoDiv>
            <InfoDiv>
              <InfoSpan>E-MAIL</InfoSpan>
              ask@aurum.nexus
            </InfoDiv>
            <InfoDiv>
              <InfoSpan>POST</InfoSpan>
              02856
            </InfoDiv>
          </ContactInfoContainer>
          <AskBtn onClick={() => navigate("/QnA")}>문의하기</AskBtn>
        </ContactContainer>
        <SNSDiv>
          <SNSFooter color={"#F00073"}><IoLogoInstagram /></SNSFooter>
          <SNSFooter color={"#FF0000"}><IoLogoYoutube /></SNSFooter>
          <SNSFooter color={"#1DA1F2"}><IoLogoTwitter /></SNSFooter>
          <SNSFooter color={"#25D366"}><IoIosText /></SNSFooter>
        </SNSDiv>
        <KaKaoApi>
          <KaKao />
        </KaKaoApi>
        <div style={{color:"black", borderRadius : "15px"}}><p style={{fontSize :"1em"}}>오럼이 도사리는 곳</p><p style={{fontSize:"0.6em", color:"gray"}}>서울시 성북구 고려대로 26길 49, 태종빌딩 2층</p></div>
        <GalleryDiv>
          {currentImages.map((index) => (
            <Example BackGroundImage={index}></Example>
          ))}
        </GalleryDiv>
        <Pagination>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <PageNumber
              key={page}
              active={page === currentPage}
              onClick={() => handleClick(page)}
            >
              <p>{page}</p>
            </PageNumber>
          ))}
        </Pagination>
      </InfoContainer>
    </Container>
  );
}

export default CoopInfo;

const Container = styled.div`
  background: ${({ theme }) => theme.colors.BoxColor};
  ${({ theme }) => theme.common.flexCenterColumn};
  width: 100%;
  height: auto;
  color: white;
  overflow-x:hidden;
`;

const InfoContainer = styled.div`
width: 100%;
padding :10% 30%;

@media screen and (max-width: 900px) {
  padding : 5% 5%;
}
`;


const ContactContainer = styled.div`
width: 100%;
display : flex;
flex-flow : row wrap;
justify-content: space-between;
align-items : center;
margin-bottom : 60px;
`;


const ContactInfoContainer = styled.div`
width: auto;
display : flex;
flex-flow : column wrap;
`
const InfoDiv = styled.div`
display : flex;
flex-flow : row wrap;
align-items : center;
${({ theme }) => theme.fontStyle.Text_SubMessage};
gap : 10px;
text-align : left;
margin-bottom : 20px;

`
const InfoSpan = styled.div`
display : flex;
flex-flow : row wrap;
${({ theme }) => theme.fontStyle.Text_Point};
border-right : 3px solid grey;
width : 8vmax;
padding: 10px;
margin:  0 10px 0 0;
@media screen and (max-width: 900px) {
  width : 100%;
  border: none;
  border-left : 3px solid grey;

}
`
const AskBtn = styled.button`
  width: 150px;
  background: #F0BB00;
  color : black;
  border-radius: 4px;
  justify-content: center;
  align-items: center;  
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  border-radius: 16px;
  outline:none;
  border : none;
  padding: 14px;
`
const SNSDiv = styled.div`
  display : flex;
  flex-flow : row wrap;
  justify-content: flex-start;
  width : 100%;
  padding: 5% 0;
  gap : 10px;
`
const SNSFooter = styled.p`
    color : #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: ${({ color }) => color};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size  : 30px;
    cursor : pointer;
    @media screen and (max-width: 900px) {
      width: 30px;
      height: 30px;
      font-size  : 20px;

    }
`
//--------------------------------------------------------------
const GalleryDiv = styled.div`
padding : 10% 0;
display : grid;
@media screen and (min-width: 901px) {
  grid-template-columns : 1fr 1fr 1fr;
  gap : 8%;

}
  @media screen and (max-width: 900px) {
    grid-template-columns : 1fr 1fr;
    gap : 2%;
  }
`



const Example = styled.div`
  height: 200px;
  border-radius: 20px;
  background : red;
  @media screen and (max-width: 900px) {
    height: 150px;
  }
  background:url(${({ BackGroundImage }) => BackGroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageNumber = styled.span`
  margin: 0 5px;
  cursor: pointer;
  color: ${({ active }) => (active ? '#F0BB00' : 'white')};
  ${({ theme }) => theme.fontStyle.Text_Point}
`;

const KaKaoApi = styled.div`
  width : 100%;
  height : 600px;
  @media screen and (max-width: 900px) {
  height : 300px;
}
`
const Text_Message = styled.h3`
  ${({ theme }) => theme.fontStyle.Text_Message};
`
const Text_SubMessage = styled.p`
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
`
const Text_large = styled.p`
${({ theme }) => theme.fontStyle.Text_large};
`
const Text_Point = styled.p`
${({ theme }) => theme.fontStyle.Text_Point};
`