import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import HeroContainer from '../../Layouts/HeroContainer';
import { FlipSection } from '../../Global/OnViewAnimation';
import ImgComponent from '../../Layouts/ImgComponent';
import { IoChevronForwardSharp, } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import EmptyPart from '../../Layouts/EmptyPart';

function Recruitment() {
  const navigate = useNavigate();
  const handleInView = () => {
    // 뷰포트에 들어왔을 때 실행할 함수
    console.log("Onview");
  };
  const handleClick = (link) => {
    navigate(link)
  };
  return (
    <Container>
      <HeroContainer imgPath={'/ImgSrc/RecruitmentHero.webp'} pageName={'인재영입'} />

      <CardDiv>
        <FlipSection onInView={handleInView} color={"white"}
          Dwidth={"388px"} Dheight={"520px "}
          Mwidth={"300px"} Mheight={"520px "}>
          <Front className="front" color='#F26716'>
            <h2> 뜨거움</h2>
            <ImgComponent style={{ justifySelf: "center" }}
              imagePath={"Recruitment1.webp"}
              Mheight={"200px"}
              Mwidth={"200px"}
              Dwidth={"100%"}
              Dheight={""} />
          </Front>
          <Back className="back" color='#FFEBE0 ' color2="#F26716"
            BackGroundImage={"/ImgSrc/Recruitment1.webp"}>
            <p></p>오럼은..<br/>
            <p>우리 사회의 해결해야 하는 <br/>문제에서 뜨겁습니다.</p>
            <p>지켜져야 하는 정의를 <br/>추구하는 것에서 뜨겁습니다.<br/></p>
            <p>모두가 가장 추워하는 곳에서<br/> 뜨겁습니다.</p>
            <Text_Message style={{ alignSelf: "end" }}>뜨거움</Text_Message>
          </Back>
        </FlipSection>

        <FlipSection onInView={handleInView} color={"white"}
          Dwidth={"388px"} Dheight={"520px "}
          Mwidth={"300px"} Mheight={"520px "}>
          <Front className="front" color='#526AF2'>
            <h2> 차가움</h2>
            <ImgComponent style={{ justifySelf: "center" }}
              imagePath={"Recruitment2.webp"}
              Mheight={"200px"}
              Mwidth={"200px"}
              Dwidth={"100%"}
              Dheight={"300px"} />
          </Front>
          <Back className="back" color='#E7EAFF' color2="#526AF2"
            BackGroundImage={"/ImgSrc/Recruitment2.webp"}>
            <p> 오럼은..</p>
            <p>듣기 좋은 말들이 난무하는 <br />상황에서 차갑습니다.</p>
            <p>본질이 무엇인가를 탐구하는<br />
              자리에서 차갑습니다.</p>
            <p>무언가가 널부러지고, <br />흐트러진 곳에서 차갑습니다.</p>
            <Text_Message style={{ alignSelf: "end" }}>차가움</Text_Message>
          </Back>
        </FlipSection>
        <FlipSection onInView={handleInView} color={"white"}
          Dwidth={"388px"} Dheight={"520px "}
          Mwidth={"300px"} Mheight={"520px "}>
          <Front className="front" color='#D61AD9'>
            <h2> 진중함</h2>
            <ImgComponent style={{ justifySelf: "center" }}
              imagePath={"Recruitment3.webp"}
              Mheight={"200px"}
              Mwidth={"200px"}
              Dwidth={"100%"}
              Dheight={"300px"} />
          </Front>
          <Back className="back" color='#FFEDFF' color2="#D61AD9"
            BackGroundImage={"/ImgSrc/Recruitment3.webp"}>
            <p> 오럼은..</p>
            <p>가장 유혹적인 상황에서 한번<br />
              물러납니다.</p>
            <p>모두가 맞다고 할 때, <br />다시 한번 호흡을 가다듬고자 합니다.</p>
            <p>휘몰아치는 폭풍의 안에 있을
              때의 <br />결정이, 누군가에게 상처가<br />
              되지 않을까 고심합니다.</p>
            <Text_Message style={{ alignSelf: "end" }}>진중함</Text_Message>
          </Back>
        </FlipSection>
      </CardDiv>
      <LinkBtnDiv>
        <LinkBtn onClick={() => handleClick("/Recruitment1")}>
          <p>우리가 함께하는 인재</p>
          <IoChevronForwardSharp />
        </LinkBtn>
        <LinkBtn onClick={() => handleClick("/Recruitment2")}>
          <p>학벌, 스펙보다 우리에게 중요한 것</p>
          <IoChevronForwardSharp />
        </LinkBtn>
      </LinkBtnDiv>
      <EmptyPart/>
    </Container>
  );
}

export default Recruitment;

const Container = styled.div`
  background: ${({ theme }) => theme.colors.BoxColor};
  ${({ theme }) => theme.common.flexCenterColumn};
  width: 100%;
  height: auto;
  color: white;
`;

const CardDiv = styled.div`
  width: 100%;
  height: auto;
  padding : 5% 10%;
  gap : 50px;
  justify-items : center;
  @media screen and (min-width: 901px) {
  display : grid;
  grid-template-columns : 1fr 1fr 1fr;
  }
  @media screen and (max-width: 900px) {
    display : grid;
    grid-template-rows : 1fr 1fr 1fr;
    padding : 5% 5%;
  }
`;

const Front = styled.div`
width: 100%;
height : 100%;
padding : 10% 5%;
border-radius: 36px;
${({ theme }) => theme.common.flexCenterColumn};
background: ${({ color }) => color}; 
background-image: url(${({ BackGroundImage }) => `${process.env.PUBLIC_URL}${BackGroundImage}`});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

const Back = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  padding : 10%;
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
  text-align : left;
  border-radius: 36px;
  position: relative; /* 부모 요소 설정 */
  background: ${({ color }) => color}; 
  color : black;
  border: 4px solid ${({ color2 }) => color2}; 
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${({ BackGroundImage }) =>
    `${process.env.PUBLIC_URL}${BackGroundImage}`});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 
    opacity: 0.2; /* 이미지 투명도 설정 */
    z-index: -1;
    border-radius: 36px; /* 이미지와 일치하도록 border-radius 설정 */
  }
  @media screen and (max-width: 900px) {
    padding : 2% 5%;

  }
`;

const LinkBtnDiv = styled.div`
  width: 100%;
  height: auto;
  padding : 5% 15%;
  gap : 50px;
  @media screen and (min-width: 901px) {
  display : grid;
  grid-template-columns : 1fr 1fr;
  }
  @media screen and (max-width: 900px) {
    display : grid;
    grid-template-rows : 1fr 1fr;
  }
`
const LinkBtn = styled.button`
  display: flex;
  padding: 40px 50px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  border: 4px solid #E5E5E5;
  background: #FFF;
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
  @media screen and (max-width: 900px) {
    padding: 5px 5px;
  }
`
const Text_Message = styled.h3`
  ${({ theme }) => theme.fontStyle.Text_Message};
`
const Text_SubMessage = styled.p`
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
`
const Text_Point = styled.p`
${({ theme }) => theme.fontStyle.Text_Point};
`