import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import HeroContainer from '../../Layouts/HeroContainer';
import ImgComponent from '../../Layouts/ImgComponent';
import { Link } from "react-scroll/modules"
import EmptyPart from '../../Layouts/EmptyPart';

function AboutUs() {
  return (
    <Container>
      <HeroContainer imgPath={"/ImgSrc/AboutusHero.webp"} pageName={"우리입니다"} />
      <SubContainer BackGroundImage="/ImgSrc/Aboutus2.webp">
        <SubjectDiv>
          <Link to="0" smooth="true" duration={1000}>
            <Subject color={'#6BF8A0'} >01. RULES</Subject>
          </Link>
          <Link to="1" smooth="true" duration={1000}>
            <Subject color={'#90F073'}>02. SHARED</Subject>

          </Link>
          <Link to="2" smooth="true" duration={1000}>
            <Subject color={'#EEF86B'}>03. CULTURE</Subject>
          </Link>
        </SubjectDiv>
        <ImgComponent style={{ justifySelf: "start" }}
          imagePath={"Aboutus3.webp"}
          Mheight={""}
          Mwidth={"100%"}
          Dwidth={"100%"}
          Dheight={""} />
      </SubContainer>
      {ContentList.map((con, index) => (
        <CultureContainer id={index} background={con.background} color={con.color}>
          <CultureTitle>
            <Link to="0" smooth="true" duration={1000}>
              <Title isNow={index === 0} color={con.color} >RULES</Title>
            </Link>
            <Link to="1" smooth="true" duration={1000}>
              <Title isNow={index === 1} color={con.color}>SHARED</Title>
            </Link>
            <Link to="2" smooth="true" duration={1000}>
              <Title isNow={index === 2} color={con.color}>CULTURE</Title>
            </Link>
          </CultureTitle>
          {con.Detail.map((Det, index) => (
            <ColumnStyle key={index}>
              {Det.Img &&
                  <ContentImg src={Det.Img} />}
              <ColumnTitle>{Det.Paragraph} {Det.Title}</ColumnTitle>
                <Column>
                  {Det.Content}
                </Column>
            </ColumnStyle>
          ))}
        </CultureContainer>
      ))}


      <EmptyPart />
    </Container>
  );
}

export default AboutUs;

const Container = styled.div`
background: ${({ theme }) => theme.colors.BoxColor};
${({ theme }) => theme.common.flexCenterColumn};
width: 100%;
height: auto;
color: white;
overflow-x : hidden;
`;
const SubContainer = styled.div`
width: 100%;
height: 100vh;
color: white;
display : flex;
flex-flow : row wrap;
justify-content:flex-start;
gap : 50px;
align-items:center;
padding : 10% 30%;
background-image: linear-gradient(to bottom, 
  rgba(0, 0, 0, 0.1) 0%, 
  rgba(0, 0, 0, 0.8) 100%), 
  url(${({ BackGroundImage }) => `${process.env.PUBLIC_URL}${BackGroundImage}`});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
@media screen and (max-width: 900px) {
  padding : 5%;
  justify-content:flex-start;
  gap : 0px;
}
`;

const SubjectDiv = styled.div`
cursor : pointer;
display : flex;
flex-flow : row wrap;
justify-content:center;
align-items:center;
@media screen and (max-width: 900px) {
  flex-flow : column wrap;
  justify-content:center;
  align-items:flex-start;
 }
`
const Subject = styled.div`
color: ${({ color }) => color};
${({ theme }) => theme.fontStyle.Text_Point};
font-weight: 600;
text-decoration-line: underline;
margin : 5px 15px;

`
const CultureContainer = styled.div`
width: 100%;
height: auto;
color: white;
padding : 5% 30%;
background: ${({ background }) => background};
color: ${({ color }) => color};

@media screen and (max-width: 900px) {
  padding : 10% 0%;
}
  
`;

const CultureTitle = styled.div`
  display : flex;
  flex-flow : row wrap;
  justify-content : flex-start;
  gap : 25px;
  width : 100%;
  @media screen and (max-width: 900px) {
justify-content : center;
}
`;

const Title = styled.div`
  color: ${({ isNow, color }) => (isNow ? '#6BF8A0' : color)};
  border-bottom : 3px solid ${({ isNow, color }) => (isNow ? '#6BF8A0' : color)};
  ${({ theme }) => theme.fontStyle.Text_Point};
  cursor : pointer;
  margin :20px 0;
`

const ColumnStyle = styled.div`
  display : flex;
  flex-flow : row wrap;
  width : 100%;
  height : auto;
  padding-bottom: 80px;
  margin-bottom : 80px;
  @media screen and (max-width: 900px) {
    margin :0;
    padding-bottom: 80px;
  }
`;
const ColumnTitle = styled.div`
  width: 300px;
  max-width : 400px;
  height: auto;
  display : flex;
  flex-flow: row wrap;
  justify-content : flex-start;
  ${({ theme }) => theme.fontStyle.Text_Point};
  margin : 0;
  text-align : left;
 @media screen and (max-width: 900px) {
    padding : 5%;
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: 0;
    order: 0;
    margin-bottom: 80px;
    width: 100%;
  }
`;


const ContentImg = styled.img`
  width : 100%;
  height : 400px;
  -webkit-box-ordinal-group:0;
  -ms-flex-order: 0;
  order: 0;
  margin-bottom: 80px;
  @media screen and (max-width: 900px) {
    width : 100%;
    height : 300px;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  object-fit : cover;
`
const Column = styled.div`
  height: auto;
  justify-content : flex-start;
  max-width: 680px;
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  text-align : left;
  display : flex;
  flex :1;
  @media screen and (max-width: 900px) {
    padding : 0 5%;
    -webkit-box-ordinal-group:1;
    -ms-flex-order: 1;
    order: 1;
  }
`;
const ContentList = [
  {
    index: 1,
    background: "black",
    color: "white",
    Culture: "RULES",
    Detail: [
      {
        Paragraph: "01.",
        Title: "우리의 차가움은 세상의 뜨거움보다 더 뜨겁다",
        Content: "팔레스타인 남서부, 이집트와 이스라엘 사이의 지중해 해안을 따라 길이 약 50km, 폭5~8km에 걸쳐 가늘고 길게 뻗은 총면적 약 362km2에 이르는 지역이다. 요르단강 서안지역(웨스트뱅크)과 함께 팔레스타인 자치지구를 구성한다.가자지구는 이 구역 내에 있는 최대의 도시 가자시(Gaza市)의 이름을 따서 가자지구로 불려지게 됐는데, 가자시 외에도 남부의 한유니스KhanYunis)·라파(Rafa) 등의 도시가 있다. 인구 대부분이 팔레스타인인으로 오랫동안 대이스라엘 저항세력의 중요한 거점이 돼 왔고, 팔레스타인과 유대인 정착민이 서로 격리된 채 살아가고 있다. 팔레스타인 남서부, 이집트와 이스라엘 사이의 지중해 해안을 따라 길이 약 50km, 폭 5~8km에 걸쳐 가늘고 길게 뻗은 총면적 약 362km2에 이르는 지역이다. 요르단강 서안지역(웨스트뱅크)과 함께 팔레스타인 자치지구를 구성한다.가자지구는 이 구역 내에 있는 최대의",
        Img: "/ImgSrc/Aboutus12.webp"
      },
      {
        Paragraph: "02.",
        Title: "우리의 차가움은 세상의 뜨거움보다 더 뜨겁다",
        Content: "팔레스타인 남서부, 이집트와 이스라엘 사이의 지중해 해안을 따라 길이 약 50km, 폭5~8km에 걸쳐 가늘고 길게 뻗은 총면적 약 362km2에 이르는 지역이다. 요르단강 서안지역(웨스트뱅크)과 함께 팔레스타인 자치지구를 구성한다.가자지구는 이 구역 내에 있는 최대의 도시 가자시(Gaza市)의 이름을 따서 가자지구로 불려지게 됐는데, 가자시 외에도 남부의 한유니스KhanYunis)·라파(Rafa) 등의 도시가 있다. 인구 대부분이 팔레스타인인으로 오랫동안 대이스라엘 저항세력의 중요한 거점이 돼 왔고, 팔레스타인과 유대인 정착민이 서로 격리된 채 살아가고 있다. 팔레스타인 남서부, 이집트와 이스라엘 사이의 지중해 해안을 따라 길이 약 50km, 폭 5~8km에 걸쳐 가늘고 길게 뻗은 총면적 약 362km2에 이르는 지역이다. 요르단강 서안지역(웨스트뱅크)과 함께 팔레스타인 자치지구를 구성한다.가자지구는 이 구역 내에 있는 최대의",
        Img: "/ImgSrc/Aboutus12.webp"
      },
      {
        Paragraph: "03.",
        Title: "우리의 차가움은 세상의 뜨거움보다 더 뜨겁다",
        Content: "팔레스타인 남서부, 이집트와 이스라엘 사이의 지중해 해안을 따라 길이 약 50km, 폭5~8km에 걸쳐 가늘고 길게 뻗은 총면적 약 362km2에 이르는 지역이다. 요르단강 서안지역(웨스트뱅크)과 함께 팔레스타인 자치지구를 구성한다.가자지구는 이 구역 내에 있는 최대의 도시 가자시(Gaza市)의 이름을 따서 가자지구로 불려지게 됐는데, 가자시 외에도 남부의 한유니스KhanYunis)·라파(Rafa) 등의 도시가 있다. 인구 대부분이 팔레스타인인으로 오랫동안 대이스라엘 저항세력의 중요한 거점이 돼 왔고, 팔레스타인과 유대인 정착민이 서로 격리된 채 살아가고 있다. 팔레스타인 남서부, 이집트와 이스라엘 사이의 지중해 해안을 따라 길이 약 50km, 폭 5~8km에 걸쳐 가늘고 길게 뻗은 총면적 약 362km2에 이르는 지역이다. 요르단강 서안지역(웨스트뱅크)과 함께 팔레스타인 자치지구를 구성한다.가자지구는 이 구역 내에 있는 최대의",
        Img: "/ImgSrc/Aboutus12.webp"
      },

    ]
  },
  {
    index: 2,
    background: "white",
    color: "black",
    Culture: "SHARED",
    Detail: [
      {
        Paragraph: "01.",
        Title: "우리의 차가움은 세상의 뜨거움보다 더 뜨겁다",
        Content: "팔레스타인 남서부, 이집트와 이스라엘 사이의 지중해 해안을 따라 길이 약 50km, 폭5~8km에 걸쳐 가늘고 길게 뻗은 총면적 약 362km2에 이르는 지역이다. 요르단강 서안지역(웨스트뱅크)과 함께 팔레스타인 자치지구를 구성한다.가자지구는 이 구역 내에 있는 최대의 도시 가자시(Gaza市)의 이름을 따서 가자지구로 불려지게 됐는데, 가자시 외에도 남부의 한유니스KhanYunis)·라파(Rafa) 등의 도시가 있다. 인구 대부분이 팔레스타인인으로 오랫동안 대이스라엘 저항세력의 중요한 거점이 돼 왔고, 팔레스타인과 유대인 정착민이 서로 격리된 채 살아가고 있다. 팔레스타인 남서부, 이집트와 이스라엘 사이의 지중해 해안을 따라 길이 약 50km, 폭 5~8km에 걸쳐 가늘고 길게 뻗은 총면적 약 362km2에 이르는 지역이다. 요르단강 서안지역(웨스트뱅크)과 함께 팔레스타인 자치지구를 구성한다.가자지구는 이 구역 내에 있는 최대의",
        Img: "/ImgSrc/Aboutus12.webp"
      },
      {
        Paragraph: "02.",
        Title: "우리의 차가움은 세상의 뜨거움보다 더 뜨겁다",
        Content: "팔레스타인 남서부, 이집트와 이스라엘 사이의 지중해 해안을 따라 길이 약 50km, 폭5~8km에 걸쳐 가늘고 길게 뻗은 총면적 약 362km2에 이르는 지역이다. 요르단강 서안지역(웨스트뱅크)과 함께 팔레스타인 자치지구를 구성한다.가자지구는 이 구역 내에 있는 최대의 도시 가자시(Gaza市)의 이름을 따서 가자지구로 불려지게 됐는데, 가자시 외에도 남부의 한유니스KhanYunis)·라파(Rafa) 등의 도시가 있다. 인구 대부분이 팔레스타인인으로 오랫동안 대이스라엘 저항세력의 중요한 거점이 돼 왔고, 팔레스타인과 유대인 정착민이 서로 격리된 채 살아가고 있다. 팔레스타인 남서부, 이집트와 이스라엘 사이의 지중해 해안을 따라 길이 약 50km, 폭 5~8km에 걸쳐 가늘고 길게 뻗은 총면적 약 362km2에 이르는 지역이다. 요르단강 서안지역(웨스트뱅크)과 함께 팔레스타인 자치지구를 구성한다.가자지구는 이 구역 내에 있는 최대의",
        Img: "/ImgSrc/Aboutus12.webp"
      },
      {
        Paragraph: "03.",
        Title: "우리의 차가움은 세상의 뜨거움보다 더 뜨겁다",
        Content: "팔레스타인 남서부, 이집트와 이스라엘 사이의 지중해 해안을 따라 길이 약 50km, 폭5~8km에 걸쳐 가늘고 길게 뻗은 총면적 약 362km2에 이르는 지역이다. 요르단강 서안지역(웨스트뱅크)과 함께 팔레스타인 자치지구를 구성한다.가자지구는 이 구역 내에 있는 최대의 도시 가자시(Gaza市)의 이름을 따서 가자지구로 불려지게 됐는데, 가자시 외에도 남부의 한유니스KhanYunis)·라파(Rafa) 등의 도시가 있다. 인구 대부분이 팔레스타인인으로 오랫동안 대이스라엘 저항세력의 중요한 거점이 돼 왔고, 팔레스타인과 유대인 정착민이 서로 격리된 채 살아가고 있다. 팔레스타인 남서부, 이집트와 이스라엘 사이의 지중해 해안을 따라 길이 약 50km, 폭 5~8km에 걸쳐 가늘고 길게 뻗은 총면적 약 362km2에 이르는 지역이다. 요르단강 서안지역(웨스트뱅크)과 함께 팔레스타인 자치지구를 구성한다.가자지구는 이 구역 내에 있는 최대의",
        Img: "/ImgSrc/Aboutus12.webp"
      },

    ]
  },
  {
    index: 3,
    background: "black",
    color: "white",
    Culture: "SHARED",
    Detail: [
      {
        Paragraph: "01.",
        Title: "우리의 차가움은 세상의 뜨거움보다 더 뜨겁다",
        Content: "팔레스타인 남서부, 이집트와 이스라엘 사이의 지중해 해안을 따라 길이 약 50km, 폭5~8km에 걸쳐 가늘고 길게 뻗은 총면적 약 362km2에 이르는 지역이다. 요르단강 서안지역(웨스트뱅크)과 함께 팔레스타인 자치지구를 구성한다.가자지구는 이 구역 내에 있는 최대의 도시 가자시(Gaza市)의 이름을 따서 가자지구로 불려지게 됐는데, 가자시 외에도 남부의 한유니스KhanYunis)·라파(Rafa) 등의 도시가 있다. 인구 대부분이 팔레스타인인으로 오랫동안 대이스라엘 저항세력의 중요한 거점이 돼 왔고, 팔레스타인과 유대인 정착민이 서로 격리된 채 살아가고 있다. 팔레스타인 남서부, 이집트와 이스라엘 사이의 지중해 해안을 따라 길이 약 50km, 폭 5~8km에 걸쳐 가늘고 길게 뻗은 총면적 약 362km2에 이르는 지역이다. 요르단강 서안지역(웨스트뱅크)과 함께 팔레스타인 자치지구를 구성한다.가자지구는 이 구역 내에 있는 최대의",
        Img: "/ImgSrc/Aboutus12.webp"
      },
      {
        Paragraph: "02.",
        Title: "우리의 차가움은 세상의 뜨거움보다 더 뜨겁다",
        Content: "팔레스타인 남서부, 이집트와 이스라엘 사이의 지중해 해안을 따라 길이 약 50km, 폭5~8km에 걸쳐 가늘고 길게 뻗은 총면적 약 362km2에 이르는 지역이다. 요르단강 서안지역(웨스트뱅크)과 함께 팔레스타인 자치지구를 구성한다.가자지구는 이 구역 내에 있는 최대의 도시 가자시(Gaza市)의 이름을 따서 가자지구로 불려지게 됐는데, 가자시 외에도 남부의 한유니스KhanYunis)·라파(Rafa) 등의 도시가 있다. 인구 대부분이 팔레스타인인으로 오랫동안 대이스라엘 저항세력의 중요한 거점이 돼 왔고, 팔레스타인과 유대인 정착민이 서로 격리된 채 살아가고 있다. 팔레스타인 남서부, 이집트와 이스라엘 사이의 지중해 해안을 따라 길이 약 50km, 폭 5~8km에 걸쳐 가늘고 길게 뻗은 총면적 약 362km2에 이르는 지역이다. 요르단강 서안지역(웨스트뱅크)과 함께 팔레스타인 자치지구를 구성한다.가자지구는 이 구역 내에 있는 최대의",
        Img: "/ImgSrc/Aboutus12.webp"
      },
      {
        Paragraph: "03.",
        Title: "우리의 차가움은 세상의 뜨거움보다 더 뜨겁다",
        Content: "팔레스타인 남서부, 이집트와 이스라엘 사이의 지중해 해안을 따라 길이 약 50km, 폭5~8km에 걸쳐 가늘고 길게 뻗은 총면적 약 362km2에 이르는 지역이다. 요르단강 서안지역(웨스트뱅크)과 함께 팔레스타인 자치지구를 구성한다.가자지구는 이 구역 내에 있는 최대의 도시 가자시(Gaza市)의 이름을 따서 가자지구로 불려지게 됐는데, 가자시 외에도 남부의 한유니스KhanYunis)·라파(Rafa) 등의 도시가 있다. 인구 대부분이 팔레스타인인으로 오랫동안 대이스라엘 저항세력의 중요한 거점이 돼 왔고, 팔레스타인과 유대인 정착민이 서로 격리된 채 살아가고 있다. 팔레스타인 남서부, 이집트와 이스라엘 사이의 지중해 해안을 따라 길이 약 50km, 폭 5~8km에 걸쳐 가늘고 길게 뻗은 총면적 약 362km2에 이르는 지역이다. 요르단강 서안지역(웨스트뱅크)과 함께 팔레스타인 자치지구를 구성한다.가자지구는 이 구역 내에 있는 최대의",
        Img: "/ImgSrc/Aboutus12.webp"
      },

    ]
  },
];
