import React, { useState, useEffect } from 'react';
import api from '../../utils/api';
import styled from 'styled-components';

const MemberManage = () => {
    const [apply, setApply] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updateApp,setUpdateApp] = useState(null);
    const GetUser = async () => {
        try {
            const responseUser = await api.get("/user/manage");
            setApply(responseUser.data.data);
            console.log("유저어레이", responseUser.data.data);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching users all data:", error);
        }
    }
    useEffect(() => {
        GetUser();
    }, []);

    const Permission = async (apper) => {
        try {
            const response = await api.put("/user/permise", {
               Permission : true,
               userId : apper
            });

            console.log("User updated successfully:", response.data);
            alert("승인 완료")
        } catch (error) {
            console.error("Error updating User:", error);
        }
    }
    return (
        <Container>
            {loading ? (
                <LoadingText>Loading...</LoadingText>
            ) : apply.length === 0 ? (
                <NoDataText>No data available</NoDataText>
            ) : (
                apply.map((app) => (
                    <MenBlock>
                        <ItemP>이름 : {app.name}</ItemP>
                        <ItemP>번호 : {app.phonenumber}</ItemP>
                        <ItemP>부서/단계 :{app.Department}/{app.Level}</ItemP>
                        <StyleBtn onClick={()=>Permission(app._id)}>회원 승인</StyleBtn>
                    </MenBlock>
                ))
            )}

        </Container>
    );
};

export default MemberManage;

const Container = styled.div`
  background: ${({ theme }) => theme.colors.BoxColor};
  ${({ theme }) => theme.common.flexCenterColumn};
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  width: 100%;
  min-height: 600px;
  height: auto;
  color: white;
  padding: 10% 20%;
  @media screen and (max-width: 900px) {
    padding : 20% 0;
  }
`
const MenBlock = styled.div`
    display : flex;
    flex-flow : row wrap;
    min-width : 300px; 
    justify-content : center;
    align-items : center;
    border : 1px solid grey;
    padding : 35px;
    color: white;
    gap : 20px;
`

const StyleBtn = styled.button`
    width: 100%;
  background: #F0BB00;
  color : black;
  border-radius: 4px;
  justify-content: center;
  align-items: center;  
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  border-radius: 36px;
  outline:none;
  border : none;
  padding: 10px;
`
const ItemP = styled.div`
    display: flex;
    width : 100%;
    text-align : left;
    ${({ theme }) => theme.fontStyle.Text_Paragraph};
    border-bottom: 1px solid grey;
`
const LoadingText = styled.p`
    color: #fff;
`;

const NoDataText = styled.p`
    color: #fff;
`;
