import { useRef, useEffect } from "react";
import { useInView,motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";

export function FlipSection({ children,onInView, Dwidth, Dheight, Mheight, Mwidth  }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, threshold: 1.5 });

  
  useEffect(() => {
    if (isInView && onInView) {
      onInView();
    }
  }, [isInView, onInView]);

  return (
    <section ref={ref}>
      <Card
        // style={{
        //   transform: isInView ? "rotateY(180deg)" : " ",
        //   transition: "all ease 2s",
          
        // }}
        variants={{
            first: { rotateY: 0 },
            animationEnd: { rotateY: 180 }
          }}
          initial="first"
          animate={isInView ? "animationEnd" : "first"}
          transition={{
            ease: "easeIn",
            duration: 1,
            delay : 0.3,
          }}
        Mwidth={Mwidth}
        Dwidth={Dwidth}
        Mheight={Mheight}
        Dheight={Dheight}
      >
        {children}
      </Card>
    </section>
  );
}
export function SlideSection({ children,onInView}) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, threshold: 1.5 });

  useEffect(() => {
    if (isInView && onInView) {
      onInView();
    }
  }, [isInView, onInView]);

  return (
    <span ref={ref}>
      <motion.span
        variants={{
            first: { opacity: 0.2, },
            animationEnd: { opacity: 1,}
          }}
          initial="first"
          animate={isInView ? "animationEnd" : "first"}
          transition={{
            ease: "easeIn",
            duration: 0.3,
            delay : 0,
          }}
      >
        {children}
      </motion.span>
    </span>
  );
}


const Card = styled(motion.div)`
transform-style: preserve-3d; 
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 5% 5% 10% 5%;
  &.flipped {
    transform: rotateY(180deg);
  }
  .front,
  .back {
    position: absolute;
    top: 0;
    left: 0;
    backface-visibility: hidden;
  }

  .back {
    transform: rotateY(180deg);
  }
  @media screen and (min-width: 501px) {
    width: ${({ Dwidth }) => Dwidth};
    height: ${({ Dheight }) => Dheight};
  }
  @media screen and (max-width: 500px) {
    width: ${({ Mwidth }) => Mwidth};
    height: ${({ Mheight }) => Mheight};
  }
`