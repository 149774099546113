import { useEffect } from "react";
import { useLocation } from "react-router-dom";
/**
 * uri 변경 추적 컴포넌트
 * uri가 변경될 때마다 pageview 이벤트 전송
 */
const RouteChangeTracker = () => {

    const {pathname} = useLocation();

    useEffect(()=>{
        window.scrollTo(0,0);
    }, [pathname]);

    return null;
  
};

export default RouteChangeTracker;