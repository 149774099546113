import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import HeroContainer from '../../Layouts/HeroContainer';
import ImgComponent from '../../Layouts/ImgComponent';
import { IoMdMenu, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../utils/api';

function Careers() {

  const [loading, setLoading] = useState(true);
  const [careers, setCareers] = useState([]); // 초기값을 빈 배열로 변경
  const navigate = useNavigate();
  const GotoDetail = (id) => {
    navigate(`/careers/${id}`)
  }

  const getCareers = async () => {
    try {
      const response = await api.get("/careers");
      console.log(response.data);
      setCareers(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching careers:", error);
      setLoading(false); // 데이터를 불러오지 못한 경우에도 로딩을 false로 변경
    }
  }
  
  useEffect(() => {
    getCareers();
  }, []);

  const formattedDate = (date) => new Date(date).toLocaleDateString('ko-KR');
    //검색 기능 구현
   //유저 입력값
   const [userInput, setUserInput] = useState('');

  // 입력값을 가져옴
  const getSearchValue = (e) => {
    setUserInput(e.target.value)};
  // 입력값을 가져옴
  //셀렉트 박스 가져옴
  //직무
  const [AreaTag, setAreaTag] = useState('');

  //경력 형태
  const [TargetTag, setTargetTag] = useState('');

  //고용 형태
  const [EmploymentTypeTag, setEmploymentTypeTag] = useState('');

  const handleTagClick = (e) => {
    console.log("선택 태그",e.target.id)
    console.log("선택 값",e.target.value)

    if(e.target.id==="직무"){
      if(e.target.value === ""){
        setAreaTag("")
      }else{
        setAreaTag(e.target.value)
      }
    }
    else if(e.target.id==="경력 형태"){
      if(e.target.value === ""){
        setTargetTag("")
      }else{
        setTargetTag(e.target.value)
      }
    }
    else if(e.target.id==="고용 형태"){
      if(e.target.value === ""){
        setEmploymentTypeTag("")
      }else{
        setEmploymentTypeTag(e.target.value)
      }
    }
  };

  // 데이터 목록중, Title / 태그 &&로 사용자 입력값이 있는 데이터만 불러오기
  const searched = careers.filter((item) =>
    item.Title.includes(userInput) &&
    (!AreaTag || item.Area.includes(AreaTag)) &&
    (!TargetTag || item.Target.includes(TargetTag)) &&
    (!EmploymentTypeTag || item.EmploymentType.includes(EmploymentTypeTag))
  );
  
   //페이지네이션
   const [currentPage, setCurrentPage] = useState(1);
   const carrerPerPage = 5;
   const indexOfLastCareer = currentPage * carrerPerPage;//8까지
   const indexOfFirstCareer = 0;//0부터
   //9개씩 나누기
   const currentCareer = searched.slice(indexOfFirstCareer, indexOfLastCareer);

   const Moreclick =()=>{
       setCurrentPage(currentPage + 1);
   }

   
  return (
    <Container>
      <HeroContainer imgPath={"/ImgSrc/CareersHero.webp"} pageName={"CAREERS"} />
      <HeadLine>
        <Text_Message>저희는 이런 사람을 원해요!</Text_Message>
        <ImgComponent imagePath={"Search.webp"}
          Mheight={"40px"}
          Mwidth={"40px"}
          Dwidth={"60px"}
          Dheight={"60px"} />
        <SearchBar placeholder='찾으시는 공고의 키워드를 입력해보세요' value={userInput} onChange={getSearchValue}/>
      </HeadLine>
      <NoticeContainer>
        <NoticeHead>
          {TagList.map(tag => (
            <TagDiv>
            <TagBlock key={tag.index} onChange={handleTagClick} id={tag.Title}>
                  <TagItem value={""} >{tag.Title}</TagItem>
                  {tag.SubTag.map((subtag, index) => (
                     <TagItem key={index} value={subtag.tag}>{subtag.tag}</TagItem>
                  ))}
            </TagBlock>
            <Down/>
            </TagDiv>
          ))}
        </NoticeHead>

        <NoticeDiv>
          {loading ? (
            <LoadingText>Loading...</LoadingText>
          ) : currentCareer && currentCareer.length > 0 ? (
            currentCareer.filter(career => career).map((career) => (
              <NoticeBlock key={career._id} onClick={() => GotoDetail(career._id)}>
                <NoticeTitle>
                  <WorkBox className={career.Target}>{career.Target}</WorkBox>
                  <p><b>[{career.Area}] </b></p>
                  <p>{career.Title}</p>
                </NoticeTitle>
                <NoticeSub>
                  <p>#{career.EmploymentType}</p>
                  <p>#{career.Area}</p>
                  <p>{formattedDate(career.ApplicationDeadline)}까지</p>
                </NoticeSub>
              </NoticeBlock>
            ))
          ) : (
            <NoDataText>No data available</NoDataText>
          )}
          
           
          <StyleBtn onClick={() => Moreclick()}><p>더보기</p><IoIosArrowDown></IoIosArrowDown></StyleBtn>

        </NoticeDiv>

      </NoticeContainer>
    </Container>
  );
}

export default Careers;


const Container = styled.div`
  background: white;
  ${({ theme }) => theme.common.flexCenterColumn};
  width: 100%;
  height: auto;
  color: black;
`;

const HeadLine = styled.div`
  width: 100%;
  height: auto;
  background: #fff;
  ${({ theme }) => theme.common.flexCenterColumn};
  padding : 5% 30%;
  gap : 50px;
  @media screen and (max-width: 900px) {
    padding : 10% 5%;
    gap : 20px;
    border-bottom : 4px solid #F4F4F4;
  }
`
const SearchBar = styled.input`
display: flex;
padding: 28px 48px;
align-items: center;
width : 100%;
gap: 10px;
border: 1px solid black;
background: #F9F9F9;
outline : none;
${({ theme }) => theme.fontStyle.Text_SubMessage};
text-align : left;
color: #A3A3A3;
@media screen and (max-width: 900px) {
  padding: 10px 18px;
}
`
const NoticeContainer = styled.div`
  width: 100%;
  height: auto;
  padding : 5% 30%;
  background :#F4F4F4;
  @media screen and (max-width: 900px) {
    padding : 10% 2%;
  }
`
const NoticeHead = styled.div`
display: flex;
align-items: flex-start;
align-content: flex-start;
flex: 1 0 0;
flex-wrap: wrap;
margin : 0 0 50px 0;
gap: 34px;
@media screen and (max-width: 900px) {
  gap: 10px;
}
`
const TagDiv = styled.div`
display: flex;
min-width: 100px;
justify-content: space-between;
align-items: center;
border-radius: 20px;
border: 1px solid #CBCBCB;
background: #FFF;
${({ theme }) => theme.fontStyle.Text_SubMessage};
padding:0 10px 0 0px;
`
const TagBlock = styled.select`
padding: 14px;
border-radius: 20px;
border : none;
position: relative;
-webkit-appearance: none; /* 기본 화살표 숨기기 */
-moz-appearance: none;
appearance: none;
outline:none;
background : transparent;
color:black;
`
const Down = styled(IoIosArrowDown)`
`
const TagItem = styled.option`
    cursor: pointer;
    transition: background 0.3s;
    &:hover {
        background: #e6e6e6;
    }
`;
const NoticeDiv = styled.div`
width: 100%;
height: auto;
${({ theme }) => theme.common.flexCenterColumn};
gap : 20px;
`
const NoticeBlock = styled.button`
display: grid;
padding: 2% 2%;
align-items: flex-start;
gap: 10px;
align-self: stretch;
border-radius: 20px;
border: 1px solid #C5C5C5;
background: #FFF;
display: flex;
flex-flow : column wrap;
color :black;
`
const NoticeTitle = styled.div`
display: flex;
flex-flow : row wrap;
justify-content : flex-start;
align-items: center;
gap : 10px;
${({ theme }) => theme.fontStyle.Text_SubMessage};
`
const NoticeSub = styled.div`
display: flex;
justify-content : flex-start;
align-items: center;
gap : 10px;
color : #363636;
${({ theme }) => theme.fontStyle.Text_Xsmall};
`

const WorkBox = styled.div`
display: flex;
width : auto;
justify-content: center;
align-items: center;
border-radius: 12px;
color : white;
padding : 5px 20px;
&.경력 {
  background:#F0BB00;
}
&.신입 {
  background:#5C8AFF;
}
`

const LoadingText = styled.div`
  text-align: center;
  font-size: 24px;
`;

const NoDataText = styled.div`
  text-align: center;
  font-size: 24px;
  color: red;
${({ theme }) => theme.fontStyle.Text_SubMessage};
`;
const StyleBtn = styled.button`
    padding : 10px;
    ${({ theme }) => theme.fontStyle.Text_Paragraph};
    border : none;
    color : black;
    background : transparent;
`

const Text_Message = styled.h3`
  ${({ theme }) => theme.fontStyle.Text_Message};
`
const TagList = [
  {
    index: "1",
    Title: "직무",
    SubTag: [
      { tag: "Marketing" },
      { tag: "Tech" },
      { tag: "H&R" },
    ]
  },
  {
    index: "2",
    Title: "경력 형태",
    SubTag: [
      { tag: "경력" },
      { tag: "신입" },
    ]
  },
  {
    index: "3",
    Title: "고용 형태",
    SubTag: [
      { tag: "계약직" },
      { tag: "정규직" },
      { tag: "인턴" },
      { tag: "파트타임" },
      { tag: "일용직" },
    ]
  },
];