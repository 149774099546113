import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import HeroContainer from '../../../Layouts/HeroContainer';
import api from '../../../utils/api';
import { useNavigate } from 'react-router-dom';
import { IoLockClosedOutline } from "react-icons/io5";
const QnAList = ({ user }) => {
  const [qna, setQna] = useState([]); // 초기값을 빈 배열로 변경
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const GotoDetail = (id) => {
    navigate(`/qna/${id}`);
  };

  const getQnA = async () => {
    try {
      const response = await api.get("/qna");
      setQna(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching qna:", error);
      setLoading(false); // 데이터를 불러오지 못한 경우에도 로딩을 false로 변경
    }
  };

  useEffect(() => {
    getQnA();
  }, []);

  return (
    <Container>
      <HeroContainer imgPath={"/ImgSrc/QNAHero.webp"} pageName={"문의사항"} />
      <Wrapper>
      <HeadLine>
        오럼이 궁금해요
        <Text_Paragraph>오럼 문의 게시판</Text_Paragraph>
      </HeadLine>
      <QnAContainer>

        <QnABox2>
          <QnAHeader scope="col">Number</QnAHeader>
          <QnAHeader scope="col" className='Content'>문의 사항</QnAHeader>
          <QnAHeader scope="col">작성자</QnAHeader>
          <QnAHeader scope="col">작성일</QnAHeader>
        </QnABox2>
        {loading ? (
          <LoadingText>Loading...</LoadingText>
        ) : qna.length === 0 ? (
          <NoDataText>No data available</NoDataText>
        ) : (

          qna.map((QnA, index) => (
            <QnABox key={QnA._id} onClick={() => GotoDetail(QnA._id)}>
              <QnAContent className='Number'>{index + 1}</QnAContent>
              <QnAContent className='Content'><Lock/>비밀글입니다.</QnAContent>
              <QnAContent className='Name'>{QnA.Name}</QnAContent>
              <QnAContent className='Date'>{new Date(QnA.createdAt).toLocaleDateString('ko-KR')}</QnAContent>
            </QnABox>
          ))

        )}

      </QnAContainer>
      <StyleBtn style={{alignSelf:"flex-end"}} onClick={()=>navigate("/QnA")}>
            문의 남기기
      </StyleBtn>
      </Wrapper>
    </Container>
  );
};

export default QnAList;

const Container = styled.div`
  ${({ theme }) => theme.common.flexCenterColumn};
  width: 100%;
  height: auto;
  color: black;
  background: #fff;

`;
const Wrapper = styled.div`
  ${({ theme }) => theme.common.flexCenterColumn};
  width: 100%;
  height: auto;
  color: black;
  padding: 5% 30%;
 @media screen and (max-width: 900px) {
    padding: 20% 2%;
  }
`
const HeadLine = styled.div`
  width: 100%;
  height: auto;
  background: #fff;
  ${({ theme }) => theme.fontStyle.Text_Message};
  margin : 0;
  display : flex;
  flex-flow : column nowrap;
  justify-content : flex-start;
  border-left : 4px solid grey;
  padding : 10px;
`;

const QnAContainer = styled.table`
  ${({ theme }) => theme.common.flexCenterColumn};
  width: 100%;
  height: auto;
  position: relative;
  border-collapse : collapse;
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
`;

const QnABox = styled.tr`
  ${({ theme }) => theme.common.flexCenterRow};
  width: 100%;
  height: auto;
  flex-flow :row wrap;
  justify-content: flex-start;
  align-items: center;
  border-collapse : collapse;
  cursor :pointer;
  @media screen and (max-width: 900px) {
      border: none;
      flex-flow :row wrap;
      justify-content: flex-start;
      align-items: flex-start;
      padding : 10px;
      gap : 10px;
      border : 1px solid grey;
      border-collapse : collapse;
      margin : 5px 0;
      border-radius : 10px;
  }
`;
const QnABox2 = styled.tr`
  ${({ theme }) => theme.common.flexCenterRow};
  width: 100%;
  height: auto;
  flex-flow :row wrap;
  justify-content: flex-start;
  align-items: center;
  border-collapse : collapse;
 @media screen and (max-width: 900px) {
   display:none;
  }
`;
const QnAHeader = styled.th`
  text-align : left;
  width: 130px;
  border-bottom :3px solid black;
  padding :20px;
  &.Content {
    flex : 1;
  }
  @media screen and (max-width: 900px) {
   display:none;
  }
`;

const QnAContent = styled.td`
  text-align : left;
  padding :20px;
  width: 130px;
  display: flex;
  flex-flow :row nowrap;
  justify-contetn : flex-start;
  align-items : center;
  border-bottom :1px solid grey;
  height: 100%;
  &.Content {
    flex : 1;
  }
  @media screen and (max-width: 900px) {
    border : none;
    width: auto;
    padding : 0;
    &.Date {
      ${({ theme }) => theme.fontStyle.Text_Xsmall};
      width : 80px;
    }
    &.Name {
      ${({ theme }) => theme.fontStyle.Text_Xsmall};
      width : 80px;
    }
  }
`;
const Lock = styled(IoLockClosedOutline)`

`
const Text_Paragraph = styled.p`
      ${({ theme }) => theme.fontStyle.Text_Paragraph};
`;
const LoadingText = styled.p`
  color: #fff;
`;

const NoDataText = styled.p`
  color: #fff;
`;

const StyleBtn = styled.button` 
  background: #F0BB00;
  color : black;
  border-radius: 4px;
  justify-content: center;
  align-items: center;  
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  border-radius: 16px;
  outline:none;
  border : none;
  padding: 5px 14px;
  margin : 10px 0;
  justify-self : flex-end;
`