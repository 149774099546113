import React, { useState } from 'react';
import styled from 'styled-components';
import HeroContainer from '../../Layouts/HeroContainer';
import EmptyPart from '../../Layouts/EmptyPart';
import { IoArrowDown } from 'react-icons/io5';
import {IoIosArrowDropdown,IoIosArrowDropright } from "react-icons/io";

function FootPrint() {
  const [isYear, setIsYear] = useState("2023");
  const YearDetail = YearList.find(year => year.Year === isYear);

  return (
    <Container>
      <HeroContainer imgPath={"/ImgSrc/FootprinHero.webp"} pageName={"발자국"} />
      <WayDiv>
        <Text_Hero>WAY WE RUN</Text_Hero>
        <Text_Message>2020~현재</Text_Message>
        <Text_Message><IoArrowDown/></Text_Message>
      </WayDiv>

      <DivisionDiv>
        <Division>
        <Line />
          {YearList.map((Year, index)=>(
          <ButtonDiv key={index} onClick={() => setIsYear(Year.Year)}  isYear={isYear===Year.Year}>
            <YearTxtDiv >
              {Year.Year}
            <Down/>
            <Right/>
            </YearTxtDiv>
          </ButtonDiv>
          ))} 
        </Division>
        <YearContent BackGroundImage="/ImgSrc/FootPrint3.webp">
        {YearDetail && (
            <Content>
              {YearDetail.Detail.map((item, index) => (
                <ContentComponent key={index}>
                  <ContentDiv><MonthSpan>{item.Month} : {item.Content}</MonthSpan></ContentDiv>
                  {item.Image && 
                  <ContentImg BackGroundImage={item.Image} />}
                </ContentComponent>
              ))}
            </Content>
          )}
        </YearContent>
      </DivisionDiv>

      <BlueDiv>
        <h3>사회의 일원으로서, 주어진 책임을 다하겠습니다</h3>
        <ImgComponent imagePath={"FootPrint_9.webp"}
          Mheight={"200px"}
          Mwidth={"200px"}
          Dwidth={"600px"}
          Dheight={"500px"} />
      </BlueDiv>



      <SponserDiv>
        <Text_Message style={{margin : 0}}>With Us 제휴 기관/단체/협회</Text_Message>
        <WIth >함께하고 있어요!</WIth>
        <Association >Association Relationship</Association>

        <Sponser>
          <SponserCard color={"#6BF8A0"}>
            <p>중소 벤처 기업부</p>
          </SponserCard>
          <SponserCard color={"#5DD969"}>
            <p>한국 콘텐츠 진흥원(Kocca)</p>
          </SponserCard>
          <SponserCard color={"#90F073"}>
            <p>서울시 창조경제혁신센터(용산)</p>
          </SponserCard>
          <SponserCard color={"#EEF86B"}>
            <p>고려대학교 스타트업 캠퍼스타운</p>
          </SponserCard>
          <SponserCard color={"#EEF86B"}>
            <p>서울시 아스피린 센터</p>
          </SponserCard>
          <SponserCard color={"#90F073"}>
            <p>한국 백혈병 소아암 협회</p>
          </SponserCard>
          <SponserCard color={"#5DD969"}>
            <p>전국대학연합 클럽 후원위원회</p>
          </SponserCard>
          
          <SponserCard color={"#EEF86B"}>
            <p> K-ICT</p>
          </SponserCard>

        </Sponser>
      </SponserDiv>
      <EmptyPart/>

    </Container>
  );
}

export default FootPrint;

const Container = styled.div`
background : ${({ theme }) => theme.colors.BoxColor};
  ${({ theme }) => theme.common.flexCenterColumn};
  width: 100%;
  height: auto;
  color: white;
`;

const WayDiv = styled.div`
  width :100%;
  background: #5C8AFF;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-items : center;
  text-align : center;
  padding : 5%;
`

const DivisionDiv = styled.div`
  width: 100%;
  height : auto;
  display : flex;
  flex-flow : row nowrap;
  justify-content : center;
  align-items : flex-start;
  position : relative;
  padding : 5% 20%;
  gap : 50px;
  @media screen and (max-width: 900px) {
    flex-flow : column nowrap;
    padding : 10% 2%;
  }
`
const Division = styled.div`
  height : 1000px;
  overflow-y : auto;
  background: transparent;
  display: flex;
  flex-flow: column nowrap;  
  justify-content : flex-start;
  align-items : flex-end;
  gap : 50px;
  position : relative;
  padding : 0% 5%;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
  @media screen and (max-width: 900px) {
    height : 50px;
    border-bottom : 1px solid grey;
    flex-flow : row nowrap;
    justify-content : flex-start;
    align-items : center;
    gap : 0px;
    padding : 0 25px 0 0px;
    width : 100%;
    overflow-x : auto;
    overflow-y : hidden;
  }
`
const ButtonDiv = styled.div`
  display: flex;
  flex-flow: row wrap;  
  justify-content : center;
  align-items : center;
  color: ${({isYear}) => isYear ? "#5C8AFF" : "white"};
  border-bottom: ${({isYear}) => isYear ? "2px solid #5C8AFF" : "none"};
  cursor:pointer;
  transition : all ease 0.3s;
  margin : 20px;
`

const Line = styled.div`
  position : absolute;
  width: 4px;
  height:100%;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  background: linear-gradient(to bottom, #77FFE7, #070707);
  @media screen and (max-width: 900px) {
   display:none;
  }
`

const YearTxtDiv = styled.div`
${({ theme }) => theme.fontStyle.Text_Point};
display:flex;
align-items : center;
gap : 5px;
`

const Down = styled(IoIosArrowDropdown)`
  @media screen and (max-width: 900px) {
   display:none;
  }
`
const Right = styled(IoIosArrowDropright)`
  display:none;
  @media screen and (max-width: 900px) {
   display:flex;
  }
`
const YearContent = styled.div`
flex : 1;
height : 100%;
color : black;

`
const Content = styled.div`
height : 100%;
width: 100%;
${({ theme }) => theme.common.flexCenterColumn};
justify-content : flex-start;
gap : 20px;
`
const ContentComponent = styled.div`
height : auto;
width: 100%;
${({ theme }) => theme.common.flexCenterColumn};
justify-content : start;
align-items : start;
${({ theme }) => theme.fontStyle.Text_SubMessage};
gap : 10px;
`
const ContentDiv = styled.div`
height : auto;
width : 100%;
padding : 15px 15px;
text-align : left;
border-radius : 10px; 
${({ theme }) => theme.common.flexCenterRow};
justify-content : flex-start;
gap : 10px;
background : transparent; 
box-shadow: rgba(255, 255, 255, 0.16) 0px 3px 6px, rgba(255, 255, 255, 0.23) 0px 3px 6px;
@media screen and (max-width: 900px) {
  ${({ theme }) => theme.common.flexCenterColumn};
  align-items : start;
  gap : 0px;

}
`
const ContentImg = styled.div`
background: url(${({ BackGroundImage }) => BackGroundImage});
width : 20vmax;
height : 11vmax;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
border-radius : 15px;
border : 1px solid white;
@media screen and (max-width: 900px) {
  width : 30vmax;
  height : 20vmax;
 }
`
const MonthSpan = styled.span`
color : white;
  ${({ theme }) => theme.fontStyle.Text_Point};

`
//-------------------------------------------------------------------------
const BlueDiv = styled.div`
  width :100%;
  background: #5C8AFF;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items : center;
  text-align : center;
  padding : 10% 5% 0 5%;
  gap : 50px;
  ${({ theme }) => theme.fontStyle.Text_Point};
`

const SponserDiv = styled.div`
  width :100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items : flex-start;
  padding : 5% 20%;
  gap : 20px;
  @media screen and (max-width: 900px) {
    padding : 10% 2%;
   }
  `

const Sponser = styled.div`
  width :100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items : flex-start;
  ${({ theme }) => theme.fontStyle.Text_Xsmall};
  gap: 15px;
  `
  const WIth = styled.div`
  padding : 0px 10px;
  background : #90F073;
  color : black;
  ${({ theme }) => theme.fontStyle.Text_Xsmall};
  gap: 15px;
  border-radius : 15px;
  `
  const Association = styled.div`
  width : 100%;
  display : flex;
  padding : 5px 0px;
  border-bottom : 1px solid #EEF86B;
  color : #EEF86B;
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
  `

const SponserCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ color }) => color};
  text-align : center;
  padding : 5px 15px 5px 0px;
  
`
const ImageContainer = styled.div`
  background-color: transparent;
  display:flex;
  align-items : flex-end;
  width : 100%;
  padding : 0% 30%;
  @media screen and (max-width: 900px) {
    padding : 0% 10%;
   }
`;

const ImgComponent = ({ imagePath}) => {
  return (
    <ImageContainer
    >
      <img
        src={`${process.env.PUBLIC_URL}/ImgSrc/${imagePath}`}
        alt="페이지 이미지"
        style={{
          maxHeight : "100%",
          maxWidth : "100%"
        }}
      />
    </ImageContainer>
  );
};


const YearList = [
  
  {
    index: 4,
    Year: "2023",
    Detail: [
      { Month: "10월", Content: "법인 외부 채널 공시 (page, board)", Image: "" },
      { Month: "08월", Content: "고객사 전문 통합 채널(코드 포함)런칭", Image: "/ImgSrc/FootPrint_8.webp" },
      { Month: "05월", Content: "핵심 기능 기획 및 런칭 / 돈키호테, 알렉산드리아, 사트라프 등", Image: "" },
      { Month: "03월", Content: "라이프 플랫폼 사업부 출범 / IT전환 시작", Image: "/ImgSrc/FootPrint_7.webp" },
    ]
  }, {
    index: 3,
    Year: "2022",
    Detail: [
      { Month: "10월", Content: "엔터테인먼트 Corp.서비스–헥사르, 아이네스, 리프 / 고객사 14개 社 돌파", Image: "/ImgSrc/FootPrint_6.webp" },
      { Month: "06월", Content: "마케팅-뷰티/코스메틱 서비스, 달콤 런칭", Image: "" },
      { Month: "03월", Content: "인프라 사업부 출범, 제휴사 8개 社 달성 (이랜드 ESI 등)", Image: "" },
      { Month: "01월", Content: "K-ICT 지원 기업 선정", Image: "/ImgSrc/FootPrint_5.webp" },
    ]
  }, {
    index: 2,
    Year: "2021",
    Detail: [
      { Month: "11월", Content: "마케팅-스타일/패션 서비스, 시즈닝 런칭", Image: "" },
      { Month: "08월", Content: "사옥 이전 (성북구 안암로)", Image: "/ImgSrc/FootPrint_4.webp" },
      { Month: "04월", Content: "¼분기 매출 목표, 첫 달성 및 고객사 30개 社 돌파 (254%)", Image: "/ImgSrc/FootPrint_3.webp" },
      { Month: "02월", Content: "엔터테인먼트-마케팅 온라인 서비스, 브랜디드 런칭", Image: "" },
    ]
  }, {
    index: 1,
    Year: "2020",
    Detail: [
      { Month: "10월", Content: "DalGona브랜드 런칭 (on Instagram)", Image: "/ImgSrc/Brand3.webp" },
      { Month: "05월", Content: "ASIA Entertainment Minar참여 (in Singapore)", Image: "/ImgSrc/FootPrint_2.webp" },
      { Month: "02월", Content: "엔터테인먼트 팬덤 모델 추진 (with CGV)", Image: "" },
      { Month: "01월", Content: "법인 설립", Image: "/ImgSrc/FootPrint_1.webp" },
    ]
  },
];

const Text_Message = styled.h3`
  ${({ theme }) => theme.fontStyle.Text_Message};
`
const Text_SubMessage = styled.p`
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
`
const Text_Hero = styled.h1`
${({ theme }) => theme.fontStyle.Text_Hero};
`
const Text_Xsmall = styled.h1`
${({ theme }) => theme.fontStyle.Text_Xsmall};
`