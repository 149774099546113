import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { IoLogoTwitter, IoLogoYoutube, IoLogoInstagram, IoIosText } from "react-icons/io";
import { IoChevronDownOutline } from "react-icons/io5";

const Footer = () => {
    const navigate = useNavigate();
    const handleClick = (url) => { navigate(url); 
    window.scrollTo(0,0);

    };

    return (
        <FooterContainer>
            <ContainerGrid>
                <LinkGrid>
                        {FooterNav.map((nav, index) => (
                            <MenuDiv
                                id={`menu-box-${index}`} // 각 메뉴박스에 고유한 ID 부여
                                key={index}>
                                <b>{nav.Title}</b>
                                {nav.subMenu.map((subItem, subIndex) => (
                                    <FooterSub key={subIndex} onClick={() => handleClick(subItem.url)}>
                                        {subItem.title}
                                    </FooterSub>
                                ))}
                            </MenuDiv>
                        ))}
                    <SNSBox>
                        <SNSMain>Social Media</SNSMain>
                        <SNSFooter><IoLogoYoutube />YOUTUBE</SNSFooter>
                        <SNSFooter><IoLogoInstagram />INSTAGRAM</SNSFooter>
                        <SNSFooter><IoIosText />NAVER BLOG</SNSFooter>
                        <SNSFooter><IoLogoTwitter />TWITTER</SNSFooter>
                    </SNSBox>
                </LinkGrid>
                <InfoGrid>
                    <div>
                        <FooterMain>Aurum.inc</FooterMain>
                        <InfoDiv>
                            <FooterlighrGrey>사업자 등록번호 : 538-86-01639<DivideSpan> | </DivideSpan></FooterlighrGrey>
                            <FooterlighrGrey>대표 메일 : ask@aurum.nexus<DivideSpan> | </DivideSpan></FooterlighrGrey>
                            <FooterlighrGrey>주소 : 서울시 성북구 고려대로 26길 49, 태종빌딩 2층<DivideSpan> | </DivideSpan></FooterlighrGrey>
                            <FooterlighrGrey>Post : 02856</FooterlighrGrey>
                        </InfoDiv>
                    </div>
                    <SiteBox>
                        <>관련 사이트</><IoChevronDownOutline/>
                    </SiteBox>
                </InfoGrid>
            </ContainerGrid>
        </FooterContainer>
    );
};

export default Footer;



//   이게 아니라, 미디어 쿼리로 푸터 네비게이션이 없어지고
//   좀더 깔끔한 SNS 푸터 형태가 맞겠다
const FooterContainer = styled.div`
    display: flex;
    width : 100%;
    height : auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color : #F2F2F2;
    padding-top: 60px;
    padding-right: 24px;
    padding-bottom: 85px;
    padding-left: 24px;
    ${({ theme }) => theme.fontStyle.Text_Paragraph};
    color :black;
`

const ContainerGrid = styled.div`
    display: flex;
    width : 100%;
    height : 100%;
    flex-flow : row wrap;
    position: relative;
    max-width: 1186px;
    margin: 0 auto;
    padding: 0 7px;
`

const LinkGrid = styled.div`
    display: flex;
    width : 100%;
    flex-flow : row wrap;
    justify-content : flex-start;
    align-items : flex-start;
`
const MenuDiv = styled.div`
    word-break: keep-all;
    margin-top: 8px;
    padding-right: 8px;
    flex : 1;
    line-height: 1.4;
    letter-spacing: -.02em;
    font-size:16px;
    vertical-align: baseline;
    word-spacing:0px;
    cursor:pointer;
    ${({ theme }) => theme.fontStyle.Text_Paragraph};
    @media screen and (max-width: 900px) {
       min-width : 33%;
       flex : 0;
    }
`

const FooterSub = styled.p`
    margin-top: 8px;
    padding-right: 8px;
    ${({ theme }) => theme.fontStyle.Text_Xsmall};
    font-size : 14px;
    line-height:19.6px;
    vertical-align:baseline;
    letter-spacing: -.02em;
    word-spacing:0px;
    color:#6c6d6f;
`
const SNSBox = styled.div`
    word-break: keep-all;
    margin-top: 8px;
    padding-right: 8px;
    flex : 1;
    line-height: 1.4;
    letter-spacing: -.02em;
    font-size:16px;
    vertical-align: baseline;
    word-spacing:0px;
    @media screen and (max-width: 900px) {
        flex-basis: 100%;
        margin-top: 6px;
        padding-top: 40px;
        border-top: 1px solid #d6d7da;
        border-bottom: 1px solid #d6d7da;
    }
  
`
const SNSFooter = styled.p`
    margin-top: 8px;
    padding-right: 8px;
    ${({ theme }) => theme.fontStyle.Text_Xsmall};
    font-size : 14px;
    line-height:19.6px;
    vertical-align:baseline;
    letter-spacing: -.02em;
    word-spacing:0px;
    color:#6c6d6f;
    cursor: pointer;
    display: flex;
    align-items:center;
`
const SNSMain = styled.div`
    color : black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    ${({ theme }) => theme.fontStyle.Text_Paragraph};
`

const InfoGrid = styled.div`
    display: flex;
    flex-flow : row wrap;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 28px;
    width : 100%;
    @media screen and (max-width: 900px) {
        gap : 20px;
     }
`
const InfoDiv = styled.div`
    display: flex;
    flex-flow : row wrap;
    justify-content: flex-start;
    align-items: center;
`
const SiteBox = styled.button`
    max-height : 100px;
    min-height : 40px;
    min -width : 250px;
    border-radius: 10px;
    border: 1px solid #838383;
    background: #FFF;
    font-weight: 500;
    ${({ theme }) => theme.fontStyle.Text_Xsmall};
    display : flex;
    align-items : center;
    justify-content : space-between;
    padding : 0 20px;
`

const FooterMain = styled.h2`
    margin-bottom: 8px;
    word-break: keep-all;
    ${({ theme }) => theme.fontStyle.Text_Point};
`

const FooterlighrGrey = styled.p`
    color : #37373a;
    ${({ theme }) => theme.fontStyle.Text_Xsmall};
    padding : 0 5px 0 0;

    `
const DivideSpan = styled.span`
    @media screen and (max-width: 900px) {
       display:none;
    }
`

const FooterNav = [
    {
        index: "1",
        Title: "WeAre",
        subMenu: [
            { title: "기업 소개", url: "/Introduce" },
            { title: "메시지", url: "/Messages" },
            { title: "으랏차차", url: "/CoreValue" },
            { title: "브랜드", url: "/Brand" }
        ]
    },
    {
        index: "2",
        Title: "WeMake",
        subMenu: [
            { title: "마케팅", url: "/Marketing" },
            { title: "엔터테인먼트", url: "/Entertainment" },
            { title: "라이프 플랫폼", url: "/LifePlatform" }
        ]
    },
    {
        index: "3",
        Title: "OurStory",
        subMenu: [
            { title: "안내드립니다", url: "/Columns" },
            { title: "MEMBERS", url: "/Members" },
            { title: "발자국", url: "/FootPrint" },
            { title: "우리입니다", url: "/AboutUs" }
        ]
    },
    {
        index: "4 ",
        Title: "WithUs",
        subMenu: [
            { title: "회사 INFO", url: "/CoopInfo" },
            { title: "인재영입", url: "/Recruitment" },
            { title: "Careers", url: "/Careers" },
            { title: "문의사항", url: "/QnAList" }
        ]
    },
    {
        index: "5",
        Title: "Report",
        subMenu: [
            { title: "오럼 갤러리", url: "/Gallery" },
            { title: "흔적들", url: "/Legacy" },
            { title: "사내게시판", url: "/Community" }
        ]
    },
];
