import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useEffect, useState } from 'react';
import api from '../../../utils/api';
import { Navigation, Pagination, EffectCoverflow} from 'swiper/modules';
import styled from 'styled-components';
import 'swiper/css/effect-coverflow';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default () => {
  const [usersData, setUsersData] = useState([]); // 초기값을 빈 배열로 변경
  const [Datalength, setDatalength] = useState(false); // 초기값을 빈 배열로 변경

  const GetUser = async () => {
    try {
      const responseUser = await api.get("/user/all");
      setUsersData(responseUser.data.data);
      console.log("유저어레이", responseUser.data.data);
      if(
        usersData.length < 6
      ){
        setDatalength(false)
      }
      else{
        setDatalength(true)
      }
    } catch (error) {
      console.error("Error fetching users all data:", error);
    }
  }
  useEffect(() => {
    GetUser();
  }, []);

  
    return (
        <SwiperDiv
        breakpoints={{
            900: {
                slidesPerView: 3,
                spaceBetween:50
              }
        }}
        spaceBetween={0}
        slidesPerView={1.2}
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        coverflowEffect={{
          rotate: 30,
          stretch: 30,
          depth: 150,
          modifier: 1.3,
          slideShadows: true,
        }}
        slideToClickedSlide={true}
        loop={true}
        modules={[EffectCoverflow]}
      >
        {usersData&&usersData.map((mem, index) => (
        <SwiperSlider>
            <Card>
            <div style={{display:"flex", justifyContent :"center", width:"100%"}}>
                <Property>Level_{mem.Level}</Property>
                <Property>{mem.Department}</Property>
            </div>
            <ImgComponent imagePath={mem.Profile}/>
            <Text_SubMessage>[{mem.Introduce}]<br/></Text_SubMessage>
            <Text_Message><span style={{fontStyle:"italic", textDecoration:"underline"}}>{mem.nickname}</span></Text_Message>
            <CommentDiv>
                "{mem.Slogan}"
            </CommentDiv>
            </Card>
        </SwiperSlider>
        ))}

      </SwiperDiv>
    );
  };

  const Text_Message = styled.h3`
  ${({ theme }) => theme.fontStyle.Text_Message};
  margin : 0;
`
const Text_SubMessage = styled.h3`
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
  margin : 0;
`
const Card = styled.div`
    width : 100%;
    height :  100%;
    border : 1px solid white;
    border-radius : 15px;
    display: flex;
    flex-flow : column nowrap;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    border-radius : 25px;
    border : 2px solid white;
    color: white;
    background : #000;
    transition : all ease 0.4s;
    overflow : hidden;
    &:hover {
        border-color: rgb(244, 172, 39);
        color: rgb(244, 172, 39);
      }
`
const ImageContainer = styled.div`
  background-color: transparent;
  overflow : hidden;
  border : 1px solid grey;
  border-radius : 15px;
  width : 300px;
  height: 300px;
  display : flex;
  justify-content : center;
  align-items : center;
`;

const ImgComponent = ({ imagePath }) => {
    return (
        <ImageContainer>
            <img
                src={imagePath}
                alt="페이지 이미지"
                style={{
                   width : "300px",
                   height : "300px",
                }}
            />
        </ImageContainer>
    );
};

const CommentDiv = styled.div`
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
    width : 100%;
    background : rgba(255,255,255,0.3);
    padding : 10%;
    min-height: 100px;
    border-radius : 5px;
`
const Property = styled.div`
${({ theme }) => theme.fontStyle.Text_Paragraph};
color : white;
width : 125px;
height : 30px;
display : flex;
justify-content : center;
align-items : center;
border-radius : 5px;
`
const SwiperSlider = styled(SwiperSlide)`
width : 350px;
height : 550px;
display : flex;
align-items: center;
justify-content: center;
`

const SwiperDiv = styled(Swiper)`
display : flex;
width : 100%;
`