import React, { useState } from 'react';
import styled from 'styled-components';
import HeroContainer from '../../Layouts/HeroContainer';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/api';
import EmptyPart from '../../Layouts/EmptyPart';
import { IoChevronForward } from "react-icons/io5";

function QnA() {
  const [Title, setTitle] = useState("");
  const [Name, setName] = useState("");
  const [Content, setContent] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (!Title || !Name || !Content || !Email || !Password) {
      alert("모든 필드를 입력해주세요.");
      return;
    }

    try {
      const response = await api.post('/qna/post', { Title, Name, Content, Email, Password });
      if (response.status === 200) {
        navigate("/QnAList");
      } else {
        throw new Error(response.data.error);
      }
    } catch (error) {
      setError(error.message);
    }
  }
  const handleContentKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();//새로 고침 막기
      setContent(prevValue => `${prevValue}\n`);
    }
  };
  return (
    <Container>
      <HeadLine>
        <AskForm onSubmit={onSubmitHandler}>
          <FormHead>
            <h2>문의 사항</h2>
            <ToList onClick={() => navigate("/QnAList")}>게시판으로<IoChevronForward /></ToList>
          </FormHead>
          <InputDiv className='Title'>
            <p>문의 제목 &nbsp;<span style={{ fontSize: "16px", color: "#5C8AFF" }}>필수</span></p>
            <InputBox
              type='text'
              name='Title'
              placeholder='제목'
              value={Title}
              onChange={(e) => setTitle(e.currentTarget.value)}
            />
          </InputDiv>
          <InputDiv>

            <p>이름 &nbsp;<span style={{ fontSize: "16px", color: "#5C8AFF" }}>필수</span></p>
            <InputBox
              type='text'
              name='Name'
              placeholder='이름'
              value={Name}
              onChange={(e) => setName(e.currentTarget.value)}
            />
          </InputDiv>
          <InputDiv>
            <p>이메일 &nbsp;<span style={{ fontSize: "16px", color: "#5C8AFF" }}>필수</span></p>
            <InputBox
              type='email'
              name='Email'
              placeholder='Email을 입력하세요'
              value={Email}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
          </InputDiv>
          <InputDiv className='Content'>
            <p>문의 내용&nbsp;<span style={{ fontSize: "16px", color: "#5C8AFF" }}>필수</span></p>
            <InputArea
              name='Content'
              placeholder='내용을 입력하세요'
              value={Content}
              onChange={(e) => setContent(e.currentTarget.value)}
              onKeyDown={handleContentKeyDown}
            />
          </InputDiv>
          <InputDiv>
            <p>비밀 번호 &nbsp;<span style={{ fontSize: "16px", color: "#5C8AFF" }}>필수</span></p>
            <InputBox
              type='password'
              name='Password'
              placeholder='식별할 수 있는 번호를 입력하세요'
              value={Password}
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
          </InputDiv>

          <Submit type='submit'><p>문의 제출</p></Submit>
        </AskForm>
      </HeadLine>

    </Container>
  );
}

export default QnA;

const Container = styled.div`
  ${({ theme }) => theme.common.flexCenterColumn};
  width: 100%;
  height: auto;
  color: black;
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
`;

const HeadLine = styled.div`
  width: 100%;
  height: auto;
  background: #fff;
  ${({ theme }) => theme.common.flexCenterColumn};
  padding: 10% 30%;
  gap: 50px;
  @media screen and (max-width: 900px) {
    padding: 20% 2%;
  }
`;
const FormHead = styled.div`
  display : flex;
  width : 100%;
  flex-flow : row nowrap;
  justify-content : space-between;
  align-items : center;
  ${({ theme }) => theme.fontStyle.Text_Point};
   @media screen and (max-width: 900px) {
    padding: 0 5px;
  }
`
const ToList = styled.div`
  display : flex;
  flex-flow : row wrap;
  justify-content : center;
  align-items : center;
  padding : 10px;
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  background : #F0BB00;
  border-radius : 20px;
`;

const AskForm = styled.form`
  width: 100%;
  padding: 30px; 
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items : center;
  border : 3px solid  #F0BB00;
  border-radius : 20px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  @media screen and (max-width: 900px) {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items : flex-start;
    padding: 30px 0;
  }
`;
const InputDiv = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items : flex-start;
  padding : 24px 10px;
  width : 40%;
  &.Content {
    width : 100%;
  }
  &.Title {
    width : 100%;
  }
  @media screen and (max-width: 900px) {
   width: 100%;
   padding :10px;
  }
`
const InputBox = styled.input`
  display: flex;
  width : 100%;
  padding : 24px 10px;
  text-align: start;
  align-items: start;
  gap: 10px;
  border: 1px solid #AFAFAF;
  background: #FDFDFD;
  @media screen and (max-width: 900px) {
    padding: 10px 5px;
  }
`;

const InputArea = styled.textarea`
  display: flex;
  height : 500px;
  width : 100%;
  padding : 24px 10px;
  text-align: start;
  align-items: start;
  min-height : 150px;
  gap: 10px;
  border: 1px solid #AFAFAF;
  background: #FDFDFD;
  margin: 20px 0;
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  @media screen and (max-width: 900px) {
    padding: 15px;
    height : 200px;
  }
`;

const Submit = styled.button`
  display: flex;
  padding: 24px 80px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: #F0BB00;
  color: black;
  border: none;
  @media screen and (max-width: 900px) {
    padding: 14px 20px;
    margin : 5px;
  }
`;
