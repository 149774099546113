import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import api from '../../../utils/api';
import { useParams, useNavigate } from 'react-router-dom';
import EmptyPart from '../../../Layouts/EmptyPart';
import { IoIosArrowBack } from "react-icons/io";

const LegacyDetail = () => {
    const { idx } = useParams();
    const [post, setPost] = useState({});
    const navigate = useNavigate();

    const getContent = async () => {
        try {
            console.log("idx", idx)
            const resp = await api.get(`/legacy/${idx}`);
            const data = resp.data;
            setPost(data.data);
        } catch (error) {
            console.error("Error fetching legacy data:", error);
        }
    };

    useEffect(() => {
        getContent();
    }, [idx]);

    return (
        <Container>
            <ContentDiv>
                <HeaderDiv>
                    <HeaderTagDiv>
                        <Author>{post.Author}</Author>
                        <DateTxt>{new Date(post.createdAt).toLocaleDateString('ko-KR')}</DateTxt>
                    </HeaderTagDiv>
                    <Title>{post.Title}</Title>
                    <Button onClick={() => navigate("/Legacy")}><IoIosArrowBack /><>뒤로 가기</></Button>

                </HeaderDiv>
                <ContentBox>
                    {post.Image&&post.Image.length>0 &&
                    <ImageDiv className={post.Image.length === 1 ? 'single' : 'multiple'}>
                        {post.Image[0]&&<ContentImg src={post.Image[0]} className={post.Image.length === 1 ? 'single' : 'multiple'}/>}
                        {post.Image[1]&&<ContentImg src={post.Image[1]} className={post.Image.length === 1 ? 'single' : 'multiple'}/>}
                    </ImageDiv>}
                    <Content>{post.Content}</Content>
                </ContentBox>
            </ContentDiv>
            <EmptyPart />
        </Container>
    );
};

export default LegacyDetail;


const Container = styled.div`
    background: ${({ theme }) => theme.colors.BoxColor};
    ${({ theme }) => theme.common.flexCenterColumn};
    width: 100%;
    height: auto;
    color: white;
`;

const ContentDiv = styled.div`
    ${({ theme }) => theme.common.flexCenterColumn};
    padding : 5% 30%;
    width : 100%;
    @media screen and (max-width: 900px) {
        padding : 2% ;
    }
`
const HeaderDiv = styled.div`
    ${({ theme }) => theme.common.flexCenterColumn};
    margin : 50px 0px;
    text-align : center;
    width : 100%;
    background :transparent;
    color : white;
    padding : 2% 0%;
    border-bottom : 1px solid grey;
    gap : 25px;
    position : relative;  
`
const HeaderTagDiv = styled.div`
    ${({ theme }) => theme.fontStyle.Text_Xsmall};
    display : flex;
    flex-flow : row wrap;
    justify-content : center;
    align-iems : center;
    width : 100%;
`

const Title = styled.div`
    ${({ theme }) => theme.fontStyle.Text_Message};
    color : white;
    width : 100%;
`
const Author = styled.div`
     border-right : 2px solid grey;
     padding : 0px 10px;
     `
const DateTxt = styled.div`
     padding : 0px 10px;
`
const ContentBox = styled.div`
    text-align : start;
    position: relative;
    width: 100%;
    background :#F7F7F7;
    color : black;
    padding : 5%;
    border-radius : 20px;
    border : 1px solid grey;
    `;

const ImageDiv=styled.div`
    display : flex;
    justify-content : center;
    width : 100%;
    flex-flow : row nowrap;
    border-radius : 20px;
    overflow : hidden;
    margin : 40px 0;
    height: 500px;
    border : 2px solid #FAFAFA;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    @media screen and (max-width: 900px) {
        flex-flow : row wrap;
        height: auto;

    }
`
const ContentImg = styled.img`
    object-fit: contain;
    &.single{
        width : 100%;
    }
    &.multiple{
       width : 100%;
    }
`

const Content= styled.p`
    word-break: break-all;  /* 줄바꿈을 음절로 변경 */
    white-space: pre-line;
    ${({ theme }) => theme.fontStyle.Text_Paragraph};
    
`;



const Button = styled.button`
    background : transparent;
    color : white;
    border: none;
    cursor: pointer;
    display : flex;
    align-items : center;
    align-self : flex-start;
    ${({ theme }) => theme.fontStyle.Text_SubMessage};
    @media screen and (max-width: 900px) {
        padding :  0 10px;
    }
`;


