import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import EmptyPart from '../Layouts/EmptyPart';
import api from '../utils/api';
import styled from 'styled-components';
import { IoChevronForwardSharp,IoChevronBackSharp } from "react-icons/io5";

const ColumnDetail = () => {
    const { idx } = useParams();
    const [column, setColumn] = useState({});
    const [nextColumn, setNextColumn] = useState({});
    const [prevColumn, setPrevColumn] = useState({});

    const [isNew, setisNew] = useState(false);
    const [isLast, setisLast] = useState(false);

    const navigate = useNavigate();

    const getContent = async () => {
        try {
            const resp = await api.get(`/columns/${idx}`);
            const data = resp.data;
            setColumn(data.data);
            if (data.previousColumn) {
                setPrevColumn(data.previousColumn)
                setisLast(false)
            }
            else {
                setisLast(true)
            }
            if (data.nextColumn) {
                setNextColumn(data.nextColumn)
                setisNew(false)

            } else {
                setisNew(true)

            }
        } catch (error) {
            console.error("Error fetching column data:", error);
        }
    };

    useEffect(() => {
        getContent();
    }, [idx]);

    const formattedDate = new Date(column.createdAt).toLocaleDateString('ko-KR');

    const handleNav = (type, id) => {
        if (isNew && type === "next") {
            alert("다음 글이 없습니다람쥐.");
        }
        else if (isLast && type === "pre") {
            alert("이전 글이 없습니다람쥐.");
        }
        else {
            navigate(`/columns/${id}`);

        }
    }

    return (
        <Container>
            <ButtonContainer>
                <Button onClick={() => handleNav("pre", prevColumn._id)}><IoChevronBackSharp/><>이전글</></Button>
                <Button onClick={() => handleNav("목록", "")}><>목록</></Button>
                <Button onClick={() => handleNav("next", nextColumn._id)}><>다음글</><IoChevronForwardSharp/></Button>
            </ButtonContainer>
            <ContentDiv>
                <HeaderDiv>
                    <HeaderTagDiv>
                    <p style={{color : "#5882FA"}}>#{column.Tag}</p>
                    <p>{formattedDate}</p>
                    </HeaderTagDiv>
                    <h2>{column.Title}</h2>
                </HeaderDiv>
                <ContentBox>
                    <ContentP dangerouslySetInnerHTML={{ __html: column.content }} />
                </ContentBox>
            </ContentDiv>
            <EmptyPart/>
        </Container>
    );
};

export default ColumnDetail;

const Container = styled.div`
    background: ${({ theme }) => theme.colors.BoxColor};
    ${({ theme }) => theme.common.flexCenterColumn};
    width: 100%;
    height: auto;
    color: white;
`;

const ContentDiv = styled.div`
    ${({ theme }) => theme.common.flexCenterColumn};
    padding : 0% 30% 5% 30%;
    width : 100%;
    @media screen and (max-width: 900px) {
        padding : 2% ;
    }
`
const HeaderDiv = styled.div`
    ${({ theme }) => theme.common.flexCenterColumn};
    margin : 20px 0px;
    text-align : center;
    width : 100%;
    background :transparent;
    color : white;
    padding : 2% 0%;
    border-bottom : 1px solid grey;
    ${({ theme }) => theme.fontStyle.Text_Point};
    gap : 25px;
`
const HeaderTagDiv = styled.div`
    ${({ theme }) => theme.fontStyle.Text_Xsmall};
    display : flex;
    flex-flow : row wrap;
    justify-content : center;
    align-iems : center;
    gap : 20px;
    text-align : center;
    width : 100%;
    background :transparent;
    color : white;
`
const ContentBox = styled.div`
    ${({ theme }) => theme.common.flexCenterColumn};
    gap : 15px;
    text-align : start;
    width : 100%;
    background :#F7F7F7;
    color : black;
    padding : 5%;
    border-radius : 20px;
    border : 2px solid grey;
    img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        border-radius : 25px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        margin : 20px 0;
      }
    @media screen and (max-width: 900px) {
        padding : 10% 2%;
    }
    `;
const ContentP = styled.p`
    width : 100%;
    white-space: pre-line;
    ${({ theme }) => theme.fontStyle.Text_Paragraph};
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 10% 0% 0% 0;
    padding : 0% 30%;
    @media screen and (max-width: 900px) {
        padding : 5%;
        margin: 15% 0% 0% 0;
    }
`;

const Button = styled.button`
    background : transparent;
    width : auto;
    color : white;
    border: none;
    padding : 20px;
    cursor: pointer;
    margin: 0px 10px;
    &:hover {
        background-color: #3a64d7;
    }
    display : flex;
    align-items : center;
    ${({ theme }) => theme.fontStyle.Text_SubMessage};
`;