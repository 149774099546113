import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ImgComponent from '../../Layouts/ImgComponent';
import { IoChevronBackSharp } from "react-icons/io5";
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../utils/api'

const CareersDetail = () => {
  const { idx } = useParams();
  const [careers, setCareers] = useState({});
  const navigate = useNavigate();

  const getContent = async () => {
    try {
      const resp = await api.get(`/careers/${idx}`);
      const data = resp.data;
      setCareers(data.data);
    } catch (error) {
      console.error("Error fetching careers data:", error);
    }
  };
  const [isOpen, SetisOpen] = useState(false)

  useEffect(() => {
    getContent();
  }, [idx]);
  const formattedDate = new Date(careers.ApplicationDeadline).toLocaleDateString('ko-KR');

  return (
    <Container>
      <HeadLine>
       
      </HeadLine>

      <DetailContainer>
        <SideDiv>
         <GoToBtn onClick={()=>navigate("/Careers")}> 
        <IoChevronBackSharp /> <>이전 페이지</></GoToBtn>
        </SideDiv>

        <ContentDiv>
          <WorkBox className={careers.Target}>{careers.Target}</WorkBox>
            <TitleBox>
              <Text_Point>[{careers.Area}] </Text_Point>
              <Text_large>{careers.Title}</Text_large>
            </TitleBox>
            <Text_Point>#{careers.Target} &nbsp;
            #{careers.EmploymentType} &nbsp;
            #{formattedDate}까지</Text_Point>
            <Text_SubMessage><b>고용형태 : {careers.EmploymentType}</b></Text_SubMessage>
            <Divider/>

          <Text_Paragraph dangerouslySetInnerHTML={{ __html: careers.content}}>
          </Text_Paragraph>
          <Divider/>

          <MoreBtn onClick={() => SetisOpen(!isOpen)}>오럼과 함께하기</MoreBtn>
          {isOpen && <MoreInfo >
            <Text_Paragraph>
              버튼입니다. 이것을 누르면 아래에 설명이 나옵니다.(디폴트로는 숨겨져 있다가, 누르면 아래 뜨는 식) 여기에는, 어디로 지원하는지, 지원 방법 등이 명시되어 있음.)
            </Text_Paragraph>
          </MoreInfo>}
        </ContentDiv>
      <Empty></Empty>

      </DetailContainer>
    </Container>
  );
};

export default CareersDetail;

const Container = styled.div`
    background: ${({ theme }) => theme.colors.BoxColor};
    ${({ theme }) => theme.common.flexCenterColumn};
    width: 100%;
    height: auto;
    color: white;
`;

const HeadLine = styled.div`
  width: 100%;
  height: auto;
  background: #fff;
  ${({ theme }) => theme.common.flexCenterColumn};
  padding : 10% 20%;
  gap : 50px;
  @media screen and (max-width: 900px) {
    padding : 10% 0%;
    gap : 20px;
  }
`

const DetailContainer = styled.div`
width: 100%;
height: auto;
display:flex;
flex-flow : row wrap;
background: #F4F4F4;
color : black;
padding :5% 20%;
gap : 40px;
border : none;
@media screen and (max-width: 900px) {
  padding :0%;
  gap : 10px;
}
`

const SideDiv = styled.div`
width : 100%;
display: flex;
flex-direction: row;
align-items: center;
gap: 10px;
`
const GoToBtn = styled.button`
padding: 10px 0px;
display : flex;
flex-direction: row;
align-items: center;
gap: 5px;
${({ theme }) => theme.fontStyle.Text_Paragraph};
border: none;
`
const ContentDiv = styled.div`
display: flex;
text-align : left;
flex-flow : column wrap;  
width: 100%;
padding: 5% 15%;
align-items: flex-start;
gap: 10px;
background: #FFF;
@media screen and (max-width: 900px) {
  padding :5% 2%;
}
`
const TitleBox = styled.div`
display: flex;
flex-flow : column nowrap;  
text-align : left;
width : 100%;
`
const Divider = styled.div`
width : 100%;
height : 1px;
margin : 5px 0;
background : grey;
`
const MoreBtn = styled.button`
display: flex;
padding: 20px 60px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
background:#070707;
color : white;
align-self : center;
${({ theme }) => theme.fontStyle.Text_SubMessage};
@media screen and (max-width: 900px) {
    padding :5%;
  }
`

const WorkBox = styled.div`
display: flex;
padding:  10px 30px;
justify-content: center;
align-items: center;
border-radius: 20px;
color : white;
${({ theme }) => theme.fontStyle.Text_Point};
&.경력 {
  background:#F0BB00;
}
&.신입 {
  background:#5C8AFF;
}
`

const MoreInfo = styled.div`
    display: flex;
    flex-flow : column wrap;  
    padding: 48px 40px 50px 40px;
    justify-content:center;
    align-items: flex-start;
    background:#F8F8F8;
    border-radius: 20px;
    width : 100%;
    color:#545454;
  ${({ theme }) => theme.fontStyle.Text_SubMessage};

`

const Text_Message = styled.h3`
  ${({ theme }) => theme.fontStyle.Text_Message};
  text-align : left;
`

const Text_large = styled.h1`
  ${({ theme }) => theme.fontStyle.Text_large};
  border-bottom : 3px solid black;
  text-align : left;

`
const Text_SubMessage = styled.h3`
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
  text-align : left;
`
const Text_Paragraph = styled.p`
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
    text-align : left;
    width : 100%;
    word-break: normal;
`
const Text_Point = styled.p`
  ${({ theme }) => theme.fontStyle.Text_Point};
    word-break :normal;
    color : blue;
    text-align : left;
`

const Empty = styled.div`
width : 100%;
height : 200px;
background: linear-gradient(to bottom, rgba(242,242,242,0) 98%, rgba(242,242,242,1) 100%);
`