import styled from 'styled-components';
import HeroContainer2 from '../../Layouts/HeroContainer2';
import ImgComponent from '../../Layouts/ImgComponent';
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import EmptyPart from '../../Layouts/EmptyPart';

function Marketing() {
  const navigate = useNavigate();
  const handleClick = (url) => {
    navigate(url);
  };
  return (
    <Container>
      <HeroContainer2 imgPath={"/ImgSrc/MarketingHero.webp"}>
        <HeroMessage>
          <Text_SubMessage>마케팅은, 다가가는 것이 아니에요</Text_SubMessage>
          <Text_SubMessage>다가'오는' 것</Text_SubMessage>

          <Text_Message>“스며드는 것”</Text_Message>
          <Text_SubMessage>아니, 마케팅이 아니야.<br />
            고객과 함께 살게 되는 것</Text_SubMessage>

          <Text_SubMessage>Aurum, <br />
            -marketing department representative, cheif-</Text_SubMessage>
        </HeroMessage>
      </HeroContainer2>
      <ProductContainer>
        <AskToDiv>
          <AskFor>
            <Text_Hero>ASK FOR <br />MARKETING</Text_Hero>
          </AskFor>

          <StyledContainer>
            <StyledBox width={"15px"} color={'#2EFEF7'} />
            <StyledBox width={"30px"} color={'#2EFEF7'} />
            <StyledBox width={"80px"} color={'#2EFEF7'} />
            <StyledBox width={"180px"} color={'#2EFEF7'} />
          </StyledContainer>
          <ContactDiv onClick={() => handleClick('/QnA')}>
            <Text_Message style={{ marginBottom: "0" }}>CONTACT US</Text_Message>
            <ContactArrow />
          </ContactDiv>
        </AskToDiv>
        <CodeContainer>
          <CodeDiv>
            <Text_Paragraph>***</Text_Paragraph>
            <Text_Paragraph>
              오럼 고유 코드가 있으신 고객사분들은 본 창구가 아닌 사전에 안내된 창구를 활용해주시기 바랍니다
            </Text_Paragraph>
          </CodeDiv>
        </CodeContainer>
        <ProductDiv>
          <ProductCard>
            <CardItem>
              <Text_Message style={{ color: "#FFBFBF" }}>HExar [헥사르, 115]</Text_Message>
              <>
                [헥사르 115] 프로그램은 핵심 타겟의 심장에 각인되어,가장 필요한 가치를
                느끼는 브랜딩 마케팅을, 누구나 접할 수 있는 일상에서 구현합니다.
              </>
            </CardItem>
            <CardImg imagePath={"Marketing1.webp"}/>
          </ProductCard>
          <ProductCard className='reverse'>
          <CardImg imagePath={"Marketing2.webp"}/>

            <CardItem>
              <Text_Message style={{ color: "#A7FF99" }} >Leaf [리프, 032]</Text_Message>
              <>
                [리프 032] 프로그램은 광범위한 미디어 분야의 셀럽, 아트워킹 등 모두를
                즐겁게 하는 새로운 이슈들, 가장 빠르고 강하게 대중들에게 선보입니다.
              </>
            </CardItem>
          </ProductCard>
          <ProductCard>
            <CardItem>
              <Text_Message style={{ color: "#99E0FF" }}>Ainess [아이네스, 030]</Text_Message>
              <>
                [아이네스 030] 프로그램은 매력적인 셀럽들을
                브랜딩하고, 아티스트가 추구하는 유니버스 확장을 통해,
                더 큰 세계 시장으로서의 진출을
                서포트합니다</>
            </CardItem>
            <CardImg imagePath={"Marketing3.webp"}/>
          </ProductCard>
        </ProductDiv>
        <EmptyPart/>
      </ProductContainer>
    </Container>
  );
}

export default Marketing;


const Container = styled.div`
    background : ${({ theme }) => theme.colors.BoxColor};
    ${({ theme }) => theme.common.flexCenterColumn};
    width : 100%;
    height : auto;
    color : white;
    overflow-x : hidden;
`
const HeroMessage = styled.div`
${({ theme }) => theme.common.flexCenterColumn};
  width : 100%;
  gap : 20px;

`

const ProductContainer = styled.div`
    ${({ theme }) => theme.common.flexCenterColumn};
    width : 100%;
    height : auto;
`
const AskToDiv = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;
    padding : 10%;
    text-align : left;
    @media screen and (max-width: 900px) {
      flex-direction : column;
      align-items : flex-start;
      background : #fff;
    }
`
const AskFor = styled.div`
    margin : 0 20% 0 0;
    @media screen and (max-width: 900px) {
      margin : 0;
      color : black;
    }
   
`

const StyledContainer = styled.div`
    display : flex;
    gap : 20px;
    @media screen and (max-width: 900px) {
      display : none;
    }
`
const StyledBox = styled.div`
    width :   ${({ width }) => width};
    height : 164px;
    background :   ${({ color }) => color};
`

const ContactDiv = styled.div`
  margin : 0 0 0 -2%;
  @media screen and (max-width: 900px) {
    width : 100%;
    margin : 5% 0 0 0%;
    color : #fff;
    background : #000;
    border-radius:15px;
    display : flex;
    justify-content : space-between;
    align-items : center;
    padding : 15px;
  }
`

const ContactArrow = styled(IoIosArrowForward)`
  display:none;
  color : #fff;
  @media screen and (max-width: 900px) {
    display:flex;
    font-size : 6vmin;
  }
`
const CodeContainer = styled.div`
  width : 100%;
  height : auto;
  display : flex;
  justify-content : center;
  padding : 10%;
  @media screen and (max-width: 900px) {
    padding : 5% 2%;
  }
`
const CodeDiv = styled.div`
  width : 80%;
  height : auto;
  display : flex;
  flex-direction : column;
  align-items : center;
  text-align : center;
  padding : 5% 10%;
  background: #1B1B1B;
  @media screen and (max-width: 900px) {
    padding : 5% 2%;
  }
`
const ProductDiv = styled.div`
 width : 100%;
  height : auto;
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content :center; 
  padding : 10% 30%;
  gap : 200px;
  @media screen and (max-width: 900px) {
    gap : 150px;
  padding : 10% 5%;

  }
`
const ProductCard = styled.div`
  width : 100%;
  height : auto;
  background :  ${({ color }) => color};
  display : flex; 
  justify-content : center;
  align-items : center;
  flex-flow : row wrap;
  gap : 50px;
  padding : 10%;
  @media screen and (max-width: 900px) {
    padding : 10% 0%;
    border-bottom:1px solid grey;
    display : flex; 
    justify-content : center;
    align-items : center;
    flex-flow : column wrap;
    &.reverse {
      flex-direction: column-reverse;
    }
  }
`
const CardItem = styled.div`
  display : flex; 
  flex : 1;
  flex-flow : column wrap;
  justify-content : center;
  align-items : flex-start;
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
  text-align : left;
  @media screen and (max-width: 900px) {
    width : 100%;
    flex : 1;
  }
`

const ImageContainer = styled.div`
  object-fit: cover;
  background-color: transparent;
  display : flex; 
  flex : 1;
  justify-content : center;
  align-items : center;
  border-radius: 40px;
  @media screen and (max-width: 900px) {
    width : 100%;
  }
`;

const CardImg = ({ imagePath}) => {
  return (
    <ImageContainer>
      <img
        src={`${process.env.PUBLIC_URL}/ImgSrc/${imagePath}`}
        alt="페이지 이미지"
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
        }}
      />
    </ImageContainer>
  );
};


const Text_Message = styled.h3`
  ${({ theme }) => theme.fontStyle.Text_Message};
`
const Text_SubMessage = styled.p`
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
`
const Text_Hero = styled.h2`
  ${({ theme }) => theme.fontStyle.Text_Hero};
  text-align : left;
`
const Text_Paragraph = styled.p`
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
`