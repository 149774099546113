import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import {Navigation } from 'swiper/modules';
export default ({Imaglist}) => {
  return (
      <SwiperDiv
        autoHeight={true}
        navigation={true}
        modules={[ Navigation]}
        className="mySwiper"
      >
         {Imaglist.map((image, index) => (
        <SwiperSlider key={index}>
          <PostImg src={image} alt={`Slide ${index}`} />
        </SwiperSlider>
      ))}
      </SwiperDiv>

  );
}
const SwiperSlider = styled(SwiperSlide)`
text-align: center;
font-size: 15px;
background: #fff;
display: flex;
justify-content: center;
align-items: center;
`

const SwiperDiv = styled(Swiper)`
width : 100%;
height : 100%;
--swiper-theme-color: #F0BB00
`
const PostImg = styled.img`
min-height : 200px;
min-width : 200px;
width : 100%;
height : 100%;
`