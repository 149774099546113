import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IoMdClose } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import api from '../../../utils/api';
import imageCompression from "browser-image-compression";

const CommunityPost = ({ user }) => {
  const navigate = useNavigate();
  //뒤로 가기 버튼
  const [Tag, setCurrentTags] = useState([]);
  const [Caption, setCaption] = useState("");
  const [Image, setImage] = useState([]);
  
  const onImageUpload = async () => {
    try {
      console.log("요청 시작");
      const formData = new FormData();
  
      // 각 파일을 FormData에 추가
      uploadedImage.forEach((image, index) => {
        formData.append('files', image); // 서버에서 기대하는 필드 이름은 'files'입니다.
        console.log(`${index}번째 파일 추가중`);
      });
  
      console.log("formData:", formData);
  
      const response = await axios.post('api/image/uploads', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });
      if (response.status === 200 && response.data.status === 'success') {
        console.log("uploadedImages:", response.data.uploadedImages);
        setImage(prevImages => [...prevImages, ...response.data.uploadedImages]); // 이미지 배열 업데이트
        return response.data.uploadedImages; // 업로드된 이미지 URL 배열 반환
      } else {
        console.error('Error uploading image:', response.statusText);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  }
  
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    console.log("Tag before submission:", Tag); // 여기서 Tag 값 확인
    try {
      if (uploadedImage.length > 0) {
        const uploadedImages = await onImageUpload(); // 이미지 업로드 완료를 기다림
        console.log("리턴값 제대로 먹었나", uploadedImages);  
        // 이미지가 모두 업로드된 후에 게시물을 작성
        const Author = user.nickname;
        const AuthorProfile = user.Profile;
        const response = await api.post('/community/post', { Author, AuthorProfile, Caption, Tag, Image:uploadedImages });
        if (response.status === 200) {
          navigate("/community")
        } else {
          throw new Error(response.data.error);
        }
      }
      else{
        const Author = user.nickname;
        const AuthorProfile = user.Profile;
        const response = await api.post('/community/post', { Author, AuthorProfile, Caption, Tag});
        if (response.status === 200) {
          navigate("/community")
        } else {
          throw new Error(response.data.error);
        }
      }
    } catch (error) {
      console.error('Error uploading Post:', error);
    }
  }
  
//이미지 인풋 함수의 호출은 여기서
const [previewImage, setPreviewgImage] = useState([]);
const [uploadedImage, setUploadedImage] = useState([]);

const handleImage = async(event) => {
    console.log("이미지 받는 중");
    const imageFiles = event.target.files; // 파일 가져오기
   
    //압축 옵션
    const options = {
        maxSizeMB: 10,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
    }
    //기존 State값 받아오기
    let imageUrlLists = [...previewImage];
    let imageFileList = [...uploadedImage];
    console.log("이미지 프리뷰와 업로드 기존 리스트 수령");

    for (let i = 0; i < imageFiles.length; i++) {
        console.log("포문 시작");
        const currentImageUrl = URL.createObjectURL(imageFiles[i]);
        const compressedFile = await imageCompression(imageFiles[i], options);
        console.log("포문 추가");
        imageUrlLists.push(currentImageUrl); // URL 리스트에 추가
        imageFileList.push(compressedFile); // 파일 리스트에 추가
        console.log(i,"개 추가 완료");
        console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    }

    // 이미지 개수 제한 (최대 10개까지)
    if (imageUrlLists.length > 5) {
        imageUrlLists = imageUrlLists.slice(0, 5);
        imageFileList = imageFileList.slice(0, 5);
    }

    // 상태 업데이트
    setPreviewgImage(imageUrlLists);
    setUploadedImage(imageFileList);

    console.log("이미지 목록 :", previewImage);
    console.log("파일 목록 :", uploadedImage);
};

const deleteImage = (index) => {
    event.preventDefault(); // 새로 고침 막기
    setPreviewgImage((prevImages) => prevImages.filter((_, i) => i !== index));
    setUploadedImage((prevFiles) => prevFiles.filter((_, i) => i !== index));
    // 이전 이미지에서, 누른 인덱스의 것만 찾아서 지우기
    console.log("이미지 목록 :", previewImage);
    console.log("파일 목록 :", uploadedImage);
};

  //캡션
  const onCaptionHandler = (event) => {
    setCaption(event.currentTarget.value);
  }
  //해쉬태그 관련
  //해쉬태그 배열
  //해쉬태그 1개
  const [currentTag, setCurrentTag] = useState('');

  //태그 쓸 때 자동으로 #붙게 하기
  const handleTagInput = (event) => {
    const value = event.target.value;
    if (value.startsWith('#')) {
      setCurrentTag(value);
    } else {
      setCurrentTag(`#${value}`);
    }
  };
//줄바꿈
const handleContentKeyDown = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault();//새로 고침 막기
    setCaption(prevValue => `${prevValue}\n`);
  }
};
  //만약 ENTER 누르면, 하나의 태그가 해쉬태그 배열로 편입
  const handleTagKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();//새로 고침 막기
      if (currentTag && !Tag.includes(currentTag)) {
        setCurrentTags([...Tag, currentTag]);
        //배열에 넣은 후에는 현재 해쉬태그 입력창 초기화
        setCurrentTag('');
      }
    }
  };
  //인덱스에 해당하는 해쉬태그 배열에서 필터로 찾아서 삭제
  const deleteTag = (index) => {
    event.preventDefault();//새로 고침 막기
    setCurrentTags(Tag.filter((_, i) => i !== index));
  };

  return (
    <Container>
      <PostContainer>
        <PostDiv onSubmit={onSubmitHandler}
          method="POST"
          encType="multipart/form-data"
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
        >
          {/* 헤더 디브 */}
          <HeaderDiv>
            <HeaderBtn onClick={() => navigate("/LoggedPage")}>뒤로</HeaderBtn>
            <HeaderBtn onClick={() => navigate("/Community")}>사내 게시판</HeaderBtn>
            <HeaderBtn type='submit'>공유하기</HeaderBtn>
          </HeaderDiv>
          <BodyDiv>
            <TextArea>

              <ProfileDiv>
                <Profile>
                  {/* 기본 프로필 혹은 user 프로필 이미지 받아오기 */}
                  <ProfileImage
                    src={user ? user.Profile : "https://aurumnexus-imagebucket.s3.ap-northeast-2.amazonaws.com/userBasic.jpg"}
                    alt="유저 프로필" />
                </Profile>
                <p>{user ? user.nickname : ""}</p>
              </ProfileDiv>

              <CaptionDiv placeholder="자유롭게 글을 작성해보세요 :)" maxLength="2000"
                type="Caption"
                name="Caption"
                value={Caption}
                onChange={onCaptionHandler}
                onKeyDown={handleContentKeyDown}
              />

              <TagContainer>
                <HashDiv
                  type="text"
                  value={currentTag}
                  onChange={handleTagInput}
                  onKeyDown={handleTagKeyDown}
                  placeholder="태그를 입력하세요"
                />
                <Tags>
                  {Tag.map((tag, index) => (
                    <IndividualTag key={index}>
                      {tag}
                      <DeleteTagButton onClick={() => deleteTag(index)}><IoMdClose /></DeleteTagButton>
                    </IndividualTag>
                  ))}
                </Tags>
              </TagContainer>
            </TextArea>
            <ImageArea>
              <ImagePreview>


                {previewImage.map((image, id) => (
                  <ImageDiv key={id}>
                    <DeleteBtn onClick={() => deleteImage(id)}><IoMdClose /></DeleteBtn>
                    <Postimage src={image} alt={`${image}-${id}`} />
                  </ImageDiv>
                ))}
              </ImagePreview>
              <ImageInput
                type='file'
                accept="image/*"
                multiple={true}
                name='files'
                onChange={handleImage} />
            </ImageArea>

          </BodyDiv>
        </PostDiv>
      </PostContainer>

    </Container>
  );
};

export default CommunityPost;

const Container = styled.div`
        background: ${({ theme }) => theme.colors.BoxColor};
        ${({ theme }) => theme.common.flexCenterColumn};
        width: 100%;
        height: auto;
        color: black;
        `;

const PostContainer = styled.div`
        background: ${({ theme }) => theme.colors.BoxColor};
        width: 100%;
        height: auto;
        padding : 20%;
        @media screen and (max-width: 900px) {
          padding : 15% 2% ;
  }
        `
const PostDiv = styled.form`
        width: 100%;
        height: auto;
        background : white;
        border : 1px solid #E9E9E9;
        border-radius :30px;
        display : flex;
        flex-flow : column wrap;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        overflow : hidden;
        `
const HeaderDiv = styled.div`
        width: 100%;
        display : flex;
        flex-flow : row wrap;
        justify-content : space-between;
        align-items : center;
        padding : 2% 10%;
        border-bottom : 1px solid #E9E9E9;
        `
const HeaderBtn = styled.button`
        display : flex;
        flex-flow : row wrap;
        justify-content : center;
        align-items : center;
        ${({ theme }) => theme.fontStyle.Text_Xsmall};
        border : none;
        background : transparent;
        `
const Text_Xsmall = styled.p`
        ${({ theme }) => theme.fontStyle.Text_Xsmall};
        text-align : left;
        `
const BodyDiv = styled.div`
        width: 100%;
        height : 100vh;
        flex-grow : 1;
        display : grid;
        @media screen and (min-width: 901px) {
          grid - template - columns: 1fr 1fr;
}
        @media screen and (max-width: 900px) {
          grid - template - rows: 1fr 1fr;
}
        `
const TextArea = styled.div`
        width: 100%;
        display : flex;
        flex-flow : column wrap;
        justify-content : flex-center;
        align-items : center;
        border-right : 1px solid #E9E9E9;
        ${({ theme }) => theme.fontStyle.Text_Paragraph};
        `
const ProfileDiv = styled.div`
        width : 100%;
        padding : 10px;
        border-bottom : 1px solid #E9E9E9;
        display : flex;
        justify-content : flex-start;
        align-items : center;
        gap : 10px;
        `
const Profile = styled.div`
        width : 35px;
        height : 35px;
        border-radius : 50%;
        padding : 3px;
        background : #5C8AFF;
        display : flex;
        justify-content : center;
        align-items : center;
        `
const ProfileImage = styled.img`
        width : 30px;
        height : 30px;
        border-radius : 50%;
        `
const CaptionDiv = styled.textarea`
        width : 100%;
        height : 50%;
        resize: none;
        border : none;
        border-bottom : 1px solid #E9E9E9;
        outline: none;
        padding : 5%;
        ${({ theme }) => theme.fontStyle.Text_Paragraph};
        @media screen and (max-width: 900px) {
          padding : 3%;
}
        `
const TagContainer = styled.div`
        width : 100%;
        flex-grow : 0.5;
        border-bottom : 1px solid #E9E9E9;
        display : flex;
        flex-flow : column wrap;
        justify-content : flex-start;
        align-items:center;
        `
const HashDiv = styled.textarea`
        width : 100%;
        resize: none;
        border : none;
        padding : 2% 5%;
        color : #5C8AFF;
        ${({ theme }) => theme.fontStyle.Text_Xsmall};
        @media screen and (max-width: 900px) {
          padding : 2%;
}
        border-bottom : 1px solid #E9E9E9;
        `

const Tags = styled.div`
        width : 100%;
        height : 50px;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        margin-top: 8px;
        `;

const IndividualTag = styled.div`
        border-radius: 4px;
        padding: 8px;
        display: flex;
        align-items: center;
        color : #5C8AFF;
        ${({ theme }) => theme.fontStyle.Text_Xsmall};
        `;
const DeleteTagButton = styled.button`
        margin-left: 8px;
        background: none;
        border: none;
        cursor: pointer;
        color: #888;
        &:hover {
          color: #000;
  }
        `;
const ImageArea = styled.div`
        width: 100%;
        display : flex;
        flex-flow : column wrap;
        justify-content : flex-start;
        align-items : center;
        border-right : 1px solid #E9E9E9;
        `
const ImagePreview = styled.div`
        width: 100%;
        display : flex;
        flex-flow : row wrap;
        justify-content : flex-start;
        align-items : center;
        gap : 3px;
        `
const ImageDiv = styled.div`
        width : 90px;
        height : 90px;
        overflow : hidden;
        position : relative;
        display : flex;
        justify-content : center;
        align-items : center;
        `
const Postimage = styled.img`
        width : 80px;
        height : 80px;
        `
const DeleteBtn = styled.button`
        width : 20px;
        height : 20px;
        border-radius: 50%;
        background : rgba(0,0,0,0.6);
        position : absolute;
        top : 0px;
        right : 0px;
        color : white;
        border : none;
        display:flex;
        justify-content : center;
        align-items : center;
        `
const ImageInput = styled.input`
        width : 100%;
        height : 15%;
        border : 1px solid #E9E9E9;
        `
