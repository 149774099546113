import React from 'react';
import styled, { keyframes } from 'styled-components';

const ClippingImg = ({BackGroundImage,children}) => {
    return (
        <BackgroundFixed >
          <BackgroundWrap >
            <BackgroundImage BackGroundImage={BackGroundImage} />
          </BackgroundWrap>
          <ContentWrapper>{children}</ContentWrapper>
        </BackgroundFixed>
      );
    };
    
    const BackgroundFixed = styled.div`
      position: relative;
      width:600px;
      height:800px;
    `;
    
    const BackgroundWrap = styled.div`
      clip-path: circle(250px);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    `;
    
    const BackgroundImage = styled.div`
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-size: contain;
      background-position: center center;
      background-image: url(${({ BackGroundImage }) => BackGroundImage});
    `;
    
    const ContentWrapper = styled.div`
      position: relative;
      z-index: 1;
    `;

export default ClippingImg;