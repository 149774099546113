import React from 'react';
import { Navigate } from 'react-router-dom';

const SecreatRoute = ({ user, children }) => {
    return (
        <div>
            {(user&&user.Role) ? children : <Navigate to="/" replace />}
        </div>
    );
};

export default SecreatRoute;