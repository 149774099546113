import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { IoIosArrowDown } from "react-icons/io";

import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

const DepartmentSwiper = () => {
  const swiper = useSwiper();

  const ScrollDown = () => {
    swiper.slideNext();
  };
    return (
      <>
        <SwiperDiv
         direction='vertical'>
          {DepartmentListLeft.map((dep)=>(
            <SwiperSlider back={dep.back} pos={"center right"}>
              <Index1><IoIosArrowDown/></Index1>
            <DepTitle Shadow={dep.Shadow} style={{borderLeft : "none", justifyContent:"flex-end"}}>{dep.Title}</DepTitle>
        </SwiperSlider>
          ))}   
        </SwiperDiv>
         <SwiperDiv2
         direction='vertical'>
          {DepartmentListRight.map((dep)=>(
            <SwiperSlider back={dep.back} pos={"center left"}>
                  <Index2><IoIosArrowDown/></Index2>
                <DepTitle Shadow={dep.Shadow} style={{borderLeft : "none", justifyContent:"flex-start"}}>{dep.Title}</DepTitle>
            </SwiperSlider>
          ))}
        </SwiperDiv2>
        </>
    );
};

export default DepartmentSwiper;

const SwiperDiv = styled(Swiper)`
display : flex;
flex : 1;
color: white;
`

const SwiperDiv2 = styled(Swiper)`
display : flex;
flex : 1;
color: white;
`


const SwiperSlider = styled(SwiperSlide)`
display : flex;
width : 100%;
justify-content: center;
align-items : center;
padding: 5%;
background: ${({ back }) => back};
background-size: cover;
background-position: ${({ pos }) => pos};
background-repeat: no-repeat;
position : relative;
`
const DepTitle = styled.div`
${({ theme }) => theme.fontStyle.Text_Hero};
margin:0;
display : flex;
width : 100%;
justify-content: center;
align-items : center;
text-shadow: ${({ Shadow }) => Shadow};
`
const Index1 = styled.div`
${({ theme }) => theme.fontStyle.Text_Point};
position : absolute;
display : flex;
top : 10%;
left : 10%;
width : 6vmin;
height : 6vmin;
font-size : 5vmin;
border-radius : 50%;
border : 2px solid white;
justify-content: center;
align-items : center;
background : rgb(0,0,0,0.8);
`
const Index2 = styled.div`
${({ theme }) => theme.fontStyle.Text_Point};
position : absolute;
display : flex;
top : 10%;
right : 10%;
width : 6vmin;
height : 6vmin;
font-size : 5vmin;
border-radius : 50%;
border : 2px solid white;
justify-content: center;
align-items : center;
background : rgb(0,0,0,0.8);
`
const DepartmentListLeft = [
    {
      index: 1,
      Title: "전략",
      back:"url(/ImgSrc/Department1_1.webp)",
      Shadow : "0px 0px 10px red;"
    },
    {
      index: 2,
      Title: "경영",
      back:"url(/ImgSrc/Department2_1.webp)",
      Shadow : "0px 0px 10px green;"
    },
    {
      index: 3,
      Title: "문화 ",
    back:"url(/ImgSrc/Department3_1.webp)",
      Shadow : "0px 0px 10px orange;"
    },
    {
      index: 4,
      Title: "데이터",
    back:"url(/ImgSrc/Department4_1.webp)",
      Shadow : "0px 0px 10px blue;"
    }
  ];

  const DepartmentListRight = [
    {
      index: 1,
      Title: "기획실",
      back:"url(/ImgSrc/Department1_2.webp)",
      Shadow : "0px 0px 10px red;"

    },
    {
      index: 2,
      Title: "지원실",      
     back:"url(/ImgSrc/Department2_2.webp)",
      Shadow : "0px 0px 10px green;"
    },
    {
      index: 3,
      Title: "콘텐츠LAB",
     back:"url(/ImgSrc/Department3_2.webp)",
      Shadow : "0px 0px 10px orange;"
    },
    {
      index: 4,
      Title: "아카이빙센터",
     back:"url(/ImgSrc/Department4_2.webp)",
      Shadow : "0px 0px 10px blue;"
    }
  ];
