import  { styled, keyframes }  from 'styled-components';
import { Navigate, useNavigate } from 'react-router-dom';
const Glitch = keyframes`
  0% {
    text-shadow: 0 0 black;
  }
  10% {
    text-shadow: 0 0 black, 2px 2px yellow;
  }
  20% {
    text-shadow: 0 0 black, -2px -2px green;
  }
  30% {
    text-shadow: 0 0 black, 2px 2px red;
  }
  40% {
    text-shadow: 0 0 black, -2px -2px white;
  }
  50% {
    text-shadow: 0 0 black, 2px 2px blue;
  }
  60% {
    text-shadow: 0 0 black, -2px -2px green;
  }
  70% {
    text-shadow: 0 0 black, 2px 2px yellow;
  }
  80% {
    text-shadow: 0 0 black, -2px -2px white;
  }
  90% {
    text-shadow: 0 0 black, 2px 2px blue;
  }
  100% {
    text-shadow: 0 0 black;
  }
`;

function Home() {
  const navigate = useNavigate();

  return (
    <HomeContainer>
      <DeskVideoPlayer  autoPlay muted loop playsInline>
        <source src={process.env.PUBLIC_URL + '/VideoSrc/Intro.mp4'} />
      </DeskVideoPlayer>
      <MobileVideoPlayer  autoPlay muted loop playsInline>
        <source src={process.env.PUBLIC_URL + '/VideoSrc/IntroM.mp4'} />
      </MobileVideoPlayer>
      <IntroDiv>
        <GoToButton onClick={() => navigate("/Introduce")}>
          AURUM
        </GoToButton>
      </IntroDiv>
    </HomeContainer>
  );
}

export default Home;

const HomeContainer = styled.div`
  background: ${({ theme }) => theme.colors.BoxColor};
  ${({ theme }) => theme.common.flexCenterRow};
  width: 100%;
  height: 100vh;
  color: white;
`;

const DeskVideoPlayer = styled.video`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  @media screen and (max-width: 900px) {
    display : none;
  }
`;
const MobileVideoPlayer = styled.video`
  display : none;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  @media screen and (max-width: 900px) {
    display : flex;
  }
`;

const IntroDiv = styled.div`
  position: absolute;
  height: 30%;
  width: 100%;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${({ theme }) => theme.common.flexCenterColumn};
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
  gap: 50px;
`;

const GoToButton = styled.div`
  ${({ theme }) => theme.common.flexCenterColumn};
  ${({ theme }) => theme.common.StyledButton};
  ${({ theme }) => theme.fontStyle.Text_Hero};
  padding: 2%;
  border-radius: 30px;
  font-size: 88px;
  background: transparent;
  animation: ${Glitch} 2s linear infinite;
  @media screen and (max-width: 900px) {
    font-size: 48px;
    line-height: 36px;
    padding: 5%;
    border-radius: 20px;
  }

  &:hover {
    padding: 8%;
    border: 3px solid white;
    border-radius: 40px;
    letter-spacing: 20px;
    background-color: rgba(0, 0, 0, 0.3);
  animation:none;
  }
`;

