import {styled,keyframes,css} from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { IoMdMenu, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoPersonOutline, IoPersonSharp, IoClose, IoCloudDownloadOutline, IoLanguage, IoPersonRemoveOutline } from "react-icons/io5";

function Header({ setUser, user }) {
  const navigate = useNavigate();
  const handleClick = (url) => {
    //세부 메뉴 이동 후 state 초기화
    MenuReset();
    navigate(url);
    window.scrollTo(0, 0);
  };
  const goToMine = (link) => {
    if (user) {
      navigate(link);
      MenuReset();
    }
    else {
      alert("오럼의 구성원만 글을 작성할 수 있습니다.")
    }
  };
  // 모바일 판별
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  // 햄버거 오픈 : 모바일
  const [isMenuOpen, setisMenuOpen] = useState(false);
  
  //세부 메뉴 인덱스 확인 : 모바일
  const [openIndex, setOpenIndex] = useState(null);
  //세부 메뉴 초기화 : 모바일
  const MenuReset = () => {
    setisMenuOpen(false);
    setOpenIndex(null);
  };
  // 후버 확인:데스크탑
  const [isHovered, setIsHovered] = useState(false);
  // 후버 확인:데스크탑인덱스
  const [isHoveredIndex, setIsHoveredIndex] = useState("");
  const handleOpen = (index) => {
    setIsHoveredIndex(index)
    setIsHovered(true)
  };
  const handleClose = () => {
    setIsHoveredIndex("")
    setIsHovered(false)
  };
  // 언어 선택
  const LanguageList = ['한국어', 'English', '中国人', 'Français', 'हिंदी'];

  useEffect(() => {
    // 모바일 판별 : 화면 창 트래킹
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1300);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDownload = () => {
    fetch("https://aurumnexus-imagebucket.s3.ap-northeast-2.amazonaws.com/File/%EA%B8%B0%EC%97%85%EC%86%8C%EA%B0%9C%EC%84%9C+%EC%98%88%EC%8B%9C.pdf", { method: 'GET' })
      .then(res => {
        return res.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `(주)오럼 기업 소개서.pdf`;
        document.body.appendChild(a);
        a.click();
        setTimeout(_ => {
          window.URL.revokeObjectURL(url);
        }, 60000);
        a.remove();
        setOpen(false);
      })
      .catch(err => {
        console.error('err: ', err);
      });
  };
  //로그아웃, 세션 삭제
  const LogOut = () => {
    sessionStorage.clear();
    alert("로그아웃 되었습니다.")
    window.location.reload();
  }
  //모바일 헤더 접는 공간 
  const headBackground = useRef();

  return (
    <HeaderDiv isMenuOpen={isMenuOpen}>
      {isMobile ? (
        // 모바일네비게이션
        <MobileHeader>
          <IconDiv>
            <MLogoDiv onClick={() => handleClick('/')}>
              <Logo/>
              Aurum
            </MLogoDiv>
            <MobileMyDiv>

              {!user &&
                <MyBtn onClick={() => handleClick("/Login")} >
                  로그인&nbsp;
                  <IoPersonOutline />
                </MyBtn>}
              {user &&
                <MyBtn onClick={() => LogOut()}>
                  로그아웃&nbsp;
                  <IoPersonRemoveOutline />
                </MyBtn>}
              {user &&
                <MyBtn onClick={() => handleClick("/LoggedPage")}>
                  유저 페이지&nbsp;
                  <IoPersonSharp />
                </MyBtn>}

              {isMenuOpen ? < CloseIcon onClick={() => setisMenuOpen(!isMenuOpen)} />
                : < HambergerIcon onClick={() => setisMenuOpen(!isMenuOpen)} />}
            </MobileMyDiv>
          </IconDiv>
          {isMenuOpen && <MobileWrapper ref={headBackground} onClick={e => {
            if (e.target === headBackground.current && isMenuOpen) {
              setisMenuOpen(false);
            }
          }} />}
          <NavBox isMenuOpen={isMenuOpen}>
            {HeaderList.map((nav, index) => (
              <MainNavDiv onClick={() => setOpenIndex(openIndex === index ? null : index)}
                isOpen={openIndex === index}
                id={`menu-${index}`} // 각 메뉴박스에 고유한 ID 부여
                key={index}>

                <MainDiv isOpen={openIndex === index}>
                  <div>{nav.Title}</div>
                  {openIndex === index ?
                    (<div><IoIosArrowUp /></div>) :
                    (<div><IoIosArrowDown /></div>)}
                </MainDiv>

                {openIndex === index && (
                  <SubNavDiv>
                    {nav.subMenu.map((subItem, subIndex) => (
                      <SubDiv key={subIndex} onClick={() => handleClick(subItem.url)}>
                        {subItem.title}
                      </SubDiv>
                    ))}
                  </SubNavDiv>
                )}

              </MainNavDiv>
            ))}
            <Btn onClick={() => handleDownload()} >
              기업 소개서 &nbsp;
              <IoCloudDownloadOutline />
            </Btn>
          </NavBox>
        </MobileHeader>
      ) : (

        // 데스크탑 네비게이션
        <DeskHeader
          isHovered={isHovered}
          onMouseLeave={() => handleClose("")}
        >
          <InnerHeader>
            <LogoDiv onClick={() => handleClick('/')}>
              {/* <Logo src={"/ImgSrc/Logo.png"} /> */}
              <Logo/>
              Aurum
            </LogoDiv>
            <HeaderNav>
              <NavContainer isHovered={isHovered}>
                {HeaderList.map((nav, index) => (
                    <HeaderTitle onMouseEnter={() => handleOpen(index)}>
                      <p>{nav.Title}</p>
                    </HeaderTitle>
                ))}

                <SubNavContainer isHovered={isHovered}>
                  {SubList.map((subItem, subIndex) => (
                    (isHoveredIndex === subIndex && <>
                      {subItem.subMenu.map((Sub) => (
                        <SubNav isHovered={isHovered}
                          key={subIndex} onClick={() => handleClick(Sub.url)}>
                          {Sub.title}
                        </SubNav>))}
                    </>)
                  ))}
                </SubNavContainer>
              </NavContainer >
            </HeaderNav>
            <ButtonDiv>
              {!user && <MynavButton onClick={() => handleClick("/Login")}><p>로그인</p><IoPersonOutline /></MynavButton>}
              {user && <MynavButton onClick={() => LogOut()}>로그아웃  <IoPersonRemoveOutline /></MynavButton>}
              {user && <MynavButton onClick={() => goToMine("/LoggedPage")}>유저 페이지<IoPersonSharp /></MynavButton>} 
            </ButtonDiv>
          </InnerHeader>
          <FixDiv>
          <MynavButton onClick={() => handleDownload()}><p>기업 소개서</p><IoCloudDownloadOutline /></MynavButton>
          <LanguageBtn>
                <Language>
                  {LanguageList.map((subtag, index) => (
                    <TagItem key={index} value={subtag}>{subtag}</TagItem>
                  ))}
                </Language>
                <IoLanguage />
              </LanguageBtn>
            </FixDiv>
        </DeskHeader>
      )}
    </HeaderDiv>
  );
}

export default Header;

const HeaderDiv = styled.div`
    overflow-x : hidden;
    height : auto;
  `;

// 여기부터 모바일

const MobileHeader = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    height : 60px;
    display : flex;
    justify-content : center;
    align-items :center;
    background : rgba(0,0,0,0.6);
    backdrop-filter : blur(10px);
  `;

const IconDiv = styled.div`
  display : flex;
  justify-content : space-between;
  align-items :center;
  color : white;
  width: 92%;
  height : 100%;
`
const MLogoDiv = styled.div`
  display : flex;
  justify-content : center;
  align-items :center;
  cursor: pointer;
  height: auto;
  width: auto;
  gap : 5px;
  ${({ theme }) => theme.fontStyle.Text_Point};
  `

const MobileMyDiv = styled.div`
  display : flex;
  gap : 10px;
  justify-content : flex-end;
  align-items :center;
`
const Btn = styled.div`
  display : flex;
  justify-content : flex-start;
  align-items :center;
  border-radius : 15px;
  font-size :14px; 
  ${({ theme }) => theme.fontStyle.Text_Xsmall};
  margin-top : 100px;
  position : absolute;
  bottom : 15%;
`
const MyBtn = styled.div`
  display : flex;
  justify-content : center;
  align-items :center;
  border-radius : 15px;
  padding : 3px 6px;
  font-size :12px; 
  background: rgba(5, 5, 5, 0.20);
  backdrop-filter: blur(5px);
`
const HambergerIcon = styled(IoMdMenu)`
    font-size : 24px;
    z-index : 10001;
  `
const CloseIcon = styled(IoClose)`
  font-size : 24px;
  z-index : 10001;
`
const MobileWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height : 100vh;

`

const NavBox = styled.div`
    position: fixed;
    top: 0;
    background : ${({ theme }) => theme.colors.BoxColor};
    transform:${({ isMenuOpen }) => (isMenuOpen ? 'scale(1)' : 'scale(1.2)')};
    width: 80%;
    height : 100vh;
    transition: 0.5s ease;  
    left:${({ isMenuOpen }) => (isMenuOpen ? '20%' : '120%')};
    color : white;
    padding: 70px 50px 50px 50px;
    display : flex;
    flex-flow : column wrap;
    gap : 10px;
    justify-content: flex-start;
`



const MainNavDiv = styled.div`
    display : flex;
    flex-flow : column wrap;
    justify-content: center;
    align-items: flex-start;
  `
const MainDiv = styled.div`
    width : 100%;
    display : flex;
    justify-content: space-between;
    align-items: center;
    margin : 10px 0px;
    ${({ theme }) => theme.fontStyle.Text_Point};
    border-bottom:${({ isOpen }) => (isOpen ? '1px solid grey' : '1px solid #141414')};
    transition : all linear 0.3s;
  `
const SubNavDiv = styled.div`
    display : flex;
    flex-flow : column wrap;
    align-items: flex-start;
  `
const SubDiv = styled.div`
    ${({ theme }) => theme.fontStyle.Text_Xsmall};
    margin : 3px 0;
  `
// 여기부터 데스크탑
const DeskHeader = styled.div`
    position : absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background: rgba(5, 5, 5, 0.50);
    backdrop-filter: blur(50px);
    color : white;
    height: ${({ isHovered }) => (isHovered ? '160px' : '95px')};
    transition : all ease 0.3s;
  `;

const InnerHeader = styled.div`
    height : 100%;  
    width : 100%;
    max-width: 1440px;
    margin: 0 auto;
    display : flex;
    justify-content : center;
    align-items : flex-start;
`

const LogoDiv = styled.div`
    cursor: pointer;
    display: flex;
    justify-content : center;
    align-items: center;
    height : 100%;
    max-height: 90px;
    width : 250px;
    ${({ theme }) => theme.fontStyle.Text_Point};
    gap : 5px;
  `
const Logo = styled.div`
  width : 15px;
  height : 15px;
  border-radius : 50%;
  background : white;
`

const HeaderNav = styled.div`
    display : flex;
    flex-flow : row nowrap;
    justify-content : center;
    align-items : center;
    cursor: pointer;
    flex : 1;
    height : 100%;
    max-height: 90px;
    font-size: 16px;
    word-break: keep-all;
  `;
const NavContainer = styled.div`
  display : flex;
  flex-flow : row wrap;
  justify-content : center;
  align-items : center;
  cursor: pointer;
  height : 100%;
  flex : 1;
    transition : all ease 0.4s;
    `;

const HeaderTitle = styled.div`
    display:flex;
    justify-content:center;
    align-items : center;
    text-align : center;
    width :100%;
    height : 100%;
    max-width: 160px;
    ${({ theme }) => theme.fontStyle.Text_SubMessage};
    transition : all ease 0.2s;
    font-weight : 700;
    &:hover{ 
      transform : scale(1.2);
    }
  `;

const SubNavContainer = styled.div`
    display : flex;
    width : 100%;
    transition : all ease 0.4s;
    justify-content: center;
    align-items: center;
    padding: ${({ isHovered }) => (isHovered ? '0 20%' : '0%')};
    height: ${({ isHovered }) => (isHovered ? '100%' : '0%')};
    opacity: ${({ isHovered }) => (isHovered ? '100%' : '0%')};
    ${({ theme }) => theme.fontStyle.Text_SubMessage};
    max-height: 60px;
  `;

const SubNav = styled.div`
    display : flex;
    flex : 1;
    height : 100%;
    transition : all ease 0.2s;
    justify-content: center;
    align-items: center;
    font-weight : 600;
    color : #D8D8D8;
    &:hover{ 
      color : white;
      transform : scale(1.2);
    }
`;


const ButtonDiv = styled.div`
    display: flex;
    flex-flow : row wrap;
    justify-content : center;
    align-items: center;
    height : 100%;
    max-height: 90px;
    width : 250px;
    gap : 5px;
`
const MynavButton = styled.button`
  width : 100px;
  gap : 5px;
  color : white;
  display:flex;
  justify-content: center;
  align-items: center;      
  outline:none;
  border : none;
  ${({ theme }) => theme.fontStyle.Text_Xsmall};
  transition : all ease 0.2s;
  &:hover{ 
      transform : scale(1.1);
      font-weight : 600;
    }

  `
  const FixDiv = styled.div`
  height :100%;
  max-height: 90px;
  width : 250px;
  gap : 5px;
  position : absolute;
  top : 0%;
  right : 0%;
  display : flex;
  align-items : center;
`
const LanguageBtn = styled.div`
  width : 100px;
  color : white;
  border : none;
  display:flex;
  justify-content: center;
  align-items: center;
  transition : all ease 0.2s;
`
const Language = styled.select`
  -webkit-appearance: none; /* 기본 화살표 숨기기 */
  -moz-appearance: none;
  appearance: none;
  outline:none;
  background : transparent;
  color : white;
  border : none;
  ${({ theme }) => theme.fontStyle.Text_Xsmall};
  `
const TagItem = styled.option`
  width : 100px;
  cursor: pointer;
  color:black;
  transition: background 0.3s;
  &:hover {
      background: #e6e6e6;
  }
`;

const HeaderList = [
  {
    index: "1",
    Title: "WeAre",
    subMenu: [
      { title: "기업 소개", url: "/Introduce" },
      { title: "메시지", url: "/Messages" },
      { title: "으랏차차", url: "/CoreValue" },
      { title: "브랜드", url: "/Brand" }
    ]
  },
  {
    index: "2",
    Title: "WeMake",
    subMenu: [
      { title: "마케팅", url: "/Marketing" },
      { title: "엔터테인먼트", url: "/Entertainment" },
      { title: "라이프 플랫폼", url: "/LifePlatform" }
    ]
  },
  {
    index: "3",
    Title: "OurStory",
    subMenu: [
      { title: "안내드립니다", url: "/Columns" },
      { title: "MEMBERS", url: "/Members" },
      { title: "발자국", url: "/FootPrint" },
      { title: "우리입니다", url: "/AboutUs" }
    ]
  },
  {
    index: "4 ",
    Title: "WithUs",
    subMenu: [
      { title: "회사 INFO", url: "/CoopInfo" },
      { title: "인재영입", url: "/Recruitment" },
      { title: "Careers", url: "/Careers" },
      { title: "문의사항", url: "/QnAList" }
    ]
  },
  {
    index: "5",
    Title: "Report",
    subMenu: [
      { title: "오럼 갤러리", url: "/Gallery" },
      { title: "흔적들", url: "/Legacy" },
      { title: "사내게시판", url: "/Community" }
    ]
  },
];



const SubList = [
  {
    index: "1",
    subMenu: [
      { title: "기업 소개", url: "/Introduce" },
      { title: "메시지", url: "/Messages" },
      { title: "으랏차차", url: "/CoreValue" },
      { title: "브랜드", url: "/Brand" }
    ]
  },
  {
    index: "2",
    subMenu: [
      { title: "마케팅", url: "/Marketing" },
      { title: "엔터테인먼트", url: "/Entertainment" },
      { title: "라이프", url: "/LifePlatform" }
    ]
  },
  {
    index: "3",
    subMenu: [
      { title: "안내드립니다", url: "/Columns" },
      { title: "MEMBERS", url: "/Members" },
      { title: "발자국", url: "/FootPrint" },
      { title: "우리입니다", url: "/AboutUs" }
    ]
  },
  {
    index: "4 ",
    subMenu: [
      { title: "회사 INFO", url: "/CoopInfo" },
      { title: "인재영입", url: "/Recruitment" },
      { title: "Careers", url: "/Careers" },
      { title: "문의사항", url: "/QnAList" }
    ]
  },
  {
    index: "5",
    subMenu: [
      { title: "오럼 갤러리", url: "/Gallery" },
      { title: "흔적들", url: "/Legacy" },
      { title: "사내게시판", url: "/Community" }
    ]
  },
];