import React, { useState } from 'react';
import styled from 'styled-components';
import api from '../../utils/api';
import { useNavigate } from 'react-router-dom'
import { IoIosArrowDown } from "react-icons/io";

const Register = () => {

  const [logid, setLogid] = useState("");
  const [name, setName] = useState("");
  const [phonenumber, setPhNumber] = useState("");
  const [password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [Level, setLevel] = useState("");
  const [Department, setDepartment] = useState("");

  const [error, setError] = useState("");
  const navigate = useNavigate();

  const onIDHandler = (event) => {
    setLogid(event.currentTarget.value);
  }
  const onPasswordHandler = (event) => {
    setPassword(event.currentTarget.value);
  }
  const onConfirmPasswordHandler = (event) => {
    setConfirmPassword(event.currentTarget.value);
    if (password !== ConfirmPassword) {
      return console.log('다른데?')
    }
  }
  const onNameHandler = (event) => {
    setName(event.currentTarget.value);
  }
  const onPhNumberHandler = (event) => {
    setPhNumber(event.currentTarget.value);
  }

  const handleLevelClick = (event) => {
    setLevel(event.currentTarget.value);
  }
  const handleDepartmentClick = (event) => {
    setDepartment(event.currentTarget.value);
  }
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      if (password !== ConfirmPassword) {
        throw new Error('비밀번호와 비밀번호 확인이 일치하지 않습니다.')
      }
      const response = await api.post('/user', { 
        logid, 
        password, 
        name, 
        phonenumber,
        Level,
        Department,
      });
      if (response.status == 200) {
        alert("사내 회원 승인 대기 중!")
      }
      else {
        throw new Error(response.data.error)
      }
    } catch (error) {
      setError(error.message);
    }

  }

  return (
    <Container>
      <ResisterBox>
        <PageInfo>
          <h1>회원가입 </h1>
          <p>오럼의 구성원만 회원가입이 가능합니다.</p>
          {error && <p style={{ color: "red" }}>{error}</p>}
        </PageInfo>

        <FormBox onSubmit={onSubmitHandler}>
          <ResisterDiv>
            <PlaceHold>아이디</PlaceHold>
            <InputBox
              type='text'
              name='ID'
              placeholder='아이디를 입력해주세요'
              value={logid}
              onChange={onIDHandler}
            />
          </ResisterDiv>
          <ResisterDiv>
            <PlaceHold>PW</PlaceHold>
            <InputBox
              type='password'
              name='PW'
              placeholder='비밀번호를 입력해주세요'
              value={password}
              onChange={onPasswordHandler}
            />
          </ResisterDiv>
          <ResisterDiv>
            <PlaceHold>PW 확인</PlaceHold>
            <InputBox
              type='password'
              name='비밀번호 확인'
              placeholder='비밀번호를 한번 더 입력해주세요'
              value={ConfirmPassword}
              onChange={onConfirmPasswordHandler}
            />
          </ResisterDiv>
          <ResisterDiv>
            <PlaceHold>이름</PlaceHold>
            <InputBox
              type='text'
              name='name'
              placeholder='이름을 입력해주세요'
              value={name}
              onChange={onNameHandler}
            />
          </ResisterDiv>
          <ResisterDiv>
            <PlaceHold>휴대폰</PlaceHold>
            <InputBox
              type='text'
              name='PhNumber'
              placeholder='숫자만 입력해주세요'
              value={phonenumber}
              onChange={onPhNumberHandler}
            />
          </ResisterDiv>
          <SelectContainer>
            <SelectDiscription><IoIosArrowDown/>본인의 소속과 단계를 선택하세요<IoIosArrowDown/></SelectDiscription>
            <Select required onChange={handleLevelClick}>
              <Option disabled selected value={null}>Level</Option>
              <Option value="A">A</Option>
              <Option value="B">B</Option>
              <Option value="C">C</Option>
              <Option value="D">D</Option>
              <Option value="E">E</Option>
              <Option value="F">F</Option>
              <Option value="G">G</Option>
              <Option value="H">H</Option>
            </Select>

            <Select required onChange={handleDepartmentClick}>
              <Option disabled selected value={null}>부서</Option>
              <Option value="인프라 사업부">인프라 사업부</Option>
              <Option value="마케팅 사업부">마케팅 사업부</Option>
              <Option value="IT 사업부">IT 사업부</Option>
              <Option value="미디어 사업부">미디어 사업부</Option>
              <Option value="전략기획실">전략기획실</Option>
              <Option value="경영지원실">경영지원실</Option>
              <Option value="문화 콘텐츠LAB">문화 콘텐츠LAB</Option>
              <Option value="데이터 아카이빙센터">데이터 아카이빙센터</Option>
            </Select>
          </SelectContainer>

          <StyleBtn type="submit">
            회원가입
          </StyleBtn>
        </FormBox>
      </ResisterBox>
    </Container>
  );
};

export default Register;

const Container = styled.div`
  background : ${({ theme }) => theme.colors.BoxColor};
  ${({ theme }) => theme.common.flexCenterColumn};
  width : 100%;
  height : 100vh;
  color : white;
  overflow-x : hidden;
  
`

const ResisterBox = styled.div`
${({ theme }) => theme.common.flexCenterColumn};
width : 100%;
padding : 10% 20%;
@media screen and (max-width: 900px) {
  padding : 5%;
}
`
const PageInfo = styled.div`
${({ theme }) => theme.common.flexCenterColumn};
width : 100%;
padding : 3% 10%;
text-align : center;
gap : 20px;
${({ theme }) => theme.fontStyle.Text_SubMessage};
`

const FormBox = styled.form`
${({ theme }) => theme.common.flexCenterColumn};
gap: 20px;
width : 100%;
padding : 3% 30%;
@media screen and (max-width: 900px) {
  padding : 0%;
}
`

const ResisterDiv = styled.div`
  display:flex;
  justify-content : center;
  align-items : center;
  width : 100%;
  ${({ theme }) => theme.fontStyle.Text_Xsmall};
  border: 1px solid #F0BB00;
  background: #212121;
  border-radius: 36px;
  padding: 14px;
  gap : 10px;
  overflow : hidden;
`
const PlaceHold = styled.label`
  display: flex;
  width : 100px;
  justify-content : center;
  align-items: center;
  color: white;
  background: transparent;  
  ${({ theme }) => theme.fontStyle.Text_Xsmall};

`
const InputBox = styled.input`
  flex : 1;
  color: white;
  display: flex;
  background-color: transparent;
  border : none;
  height : 100%;
  outline:none;
  ${({ theme }) => theme.fontStyle.Text_Xsmall};
  border-left : 1px solid grey;
  padding : 0 5px;
`
const StyleBtn = styled.button` 
  width: 100%;
  background: #F0BB00;
  color : black;
  border-radius: 4px;
  justify-content: center;
  align-items: center;  
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  border-radius: 36px;
  outline:none;
  border : none;
  padding: 14px;
`
const SelectContainer = styled.div`
  width : 100%;
  display : flex;
  flex-flow : row wrap;
  justify-content : space-between;
`
const SelectDiscription = styled.div`
  width : 100%;
  display:flex;
  justify-content : center;
  align-items : center;
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  color : #6E6E6E;
  padding: 0 14px;
  margin :20px 0 ;
  border-bottom : 1px solid grey;
  `
const Select = styled.select`
  display:flex;
  justify-content : center;
  align-items : center;
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  border: 1px solid #F0BB00;
  background: #212121;
  border-radius: 36px;
  gap : 10px;
  color : white;
  overflow : hidden;
  padding: 14px;
  -webkit-appearance: none; /* 기본 화살표 숨기기 */
  -moz-appearance: none;
  appearance: none;
  width : 40%;
  min-width : 100px;
  outline :none;
`
const Option = styled.option`
  display:flex;
  justify-content : center;
  align-items : center;
  text-align : center;
  padding : 5px;
`
