import { useRef, useState, useEffect } from "react";
import styled, { keyframes } from 'styled-components';
import HeroContainer from '../../Layouts/HeroContainer';
import { motion } from "framer-motion"
import ImgComponent from "../../Layouts/ImgComponent"
import ClippingImg from "../../Layouts/ClippingImg";
import { SlideSection } from "../../Global/OnViewAnimation";

function Introduce() {
  const firstDivRef = useRef(null);
  const handleInView = () => {
    // 뷰포트에 들어왔을 때 실행할 함수
    console.log("Onview");
  };
  return (
    <Container>
      <HeroContainer imgPath="/ImgSrc/Introduce.webp" pageName="기업 소개"/>
      <FirstDiv BackGroundImage="/ImgSrc/Introduce2.webp" >
        <SwitchDiv >
          <Front
            ref={firstDivRef}
            initial={{ opacity: 1 }}
            whileInView={{ opacity: 0 }}
            viewport={{ once: false }}
            transition={{
              ease: "easeInOut",
              duration: 0.4,
              delay: 1
            }}
            BackGroundImage="/ImgSrc/WeAreHero.webp"
          >
            <Text_Point>모두를 행복하게<br /> 할 수는 없지만</Text_Point>
          </Front>
          <Back BackGroundImage={"/ImgSrc/WeAre1.webp"}>
            <Text_Point>조금은 더, <br />
              행복하게 만들 수 있습니다.</Text_Point>
          </Back>
        </SwitchDiv>

        <Text_SubMessage>세상 모두가 </Text_SubMessage>
        <Text_Point>보다 더 따뜻하게,<br />보다 더 즐겁게</Text_Point>
        <Text_SubMessage>당신의 꿈에,항상 </Text_SubMessage>
        <Text_Message>오럼이 함께 하겠습니다</Text_Message>
      </FirstDiv>

      <SecondDiv BackGroundImage="/ImgSrc/Riverflow.webp">
        <SlideSection><Text_Message>흐르는 강물처럼</Text_Message></SlideSection>
        <InnerDiv>
          <ClippingImg BackGroundImage={"/ImgSrc/Weare3.webp"}/>
        </InnerDiv>
        <Text_SubMessage>모두의 삶에 도움이 되는 기능들</Text_SubMessage>
        <Text_SubMessage>언제나 항상 있어야 하는 그러한 서비스를 만듭니다</Text_SubMessage>
      </SecondDiv>

      <ThirdDiv Color={"#fff"}>
        {/* 여기는 위아래 칼럼 플렉스로 */}
        <Text_Message style={{ zIndex: "1" }}>
          공부해서 남 주냐?
        </Text_Message>
        <ImgComponent imagePath={"WeAre4.webp"}
          Mheight={""}
          Mwidth={"100%"}
          Dwidth={""}
          Dheight={"60%"} />
        <Text_Message style={{ zIndex: "1" }}>네.<br />
          오럼은, <SlideSection><PointSpan>남 주려고</PointSpan></SlideSection> 공부했습니다</Text_Message>
      </ThirdDiv>

      <LastDiv BackGroundImage={"/ImgSrc/WeAre5.webp"}>
        <Text_SubMessage>세상 모두가, 이전의 그 어떤 것보다</Text_SubMessage>
        <Text_SubMessage>행복하고, 즐거운 라이프를 만들 수 있는 경험.</Text_SubMessage>
        <Text_Message>오럼은, 멈추지 않겠습니다</Text_Message>
      </LastDiv>
    </Container>
  );
}

export default Introduce;

const Container = styled.div`
    background : ${({ theme }) => theme.colors.BoxColor};
    ${({ theme }) => theme.common.flexCenterColumn};
    width : 100%;
    color : white;
    overflow-x : hidden;
`

const FirstDiv = styled(motion.div)`
  color : white;
  height : 100vh;
  width : 100%;
  display : flex;
  flex-flow : column nowrap;
  align-items : center;
  justify-content : center;
  background: linear-gradient(to bottom, 
    rgba(0, 0, 0, 0.6) 0%, 
  rgba(0, 0, 0, 0.7) 100%), 
  url(${({ BackGroundImage }) => BackGroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align : center;
  gap :30px;
  `
const SwitchDiv = styled(motion.div)`
${({ theme }) => theme.common.SwitchDiv};
height : 600px;
width: 600px;
  @media screen and (max-width: 900px) {
    height : 350px;
    width: 350px;
   }
`
const Front = styled(motion.div)`
${({ theme }) => theme.common.FrontDiv};
${({ theme }) => theme.common.flexCenterColumn};
text-align : center;
background: linear-gradient(to bottom, 
  rgba(0, 0, 0, 0.5) 0%, 
rgba(0, 0, 0, 0.3) 100%), 
url(${({ BackGroundImage }) => BackGroundImage});
background-size: cover;
background-position: center;
background-repeat: no-repeat;
border-radius : 50%;
z-index :2;
`
const Back = styled(motion.div)`
${({ theme }) => theme.common.BackDiv};
${({ theme }) => theme.common.flexCenterColumn};
text-align : center;
background: linear-gradient(to bottom, 
  rgba(0, 0, 0, 0.5) 0%, 
rgba(0, 0, 0, 0.3) 100%), 
url(${({ BackGroundImage }) => BackGroundImage});
background-size: cover;
background-position: center;
background-repeat: no-repeat;
border-radius : 50%;
z-index:1;
`

const PointSpan = styled.span`
  color : ${({ theme }) => theme.colors.HashColor};
`


const SecondDiv = styled.div`
  ${({ theme }) => theme.common.flexCenterColumn};
  text-align : center;
  width : 100%;
  height : 100vh;
  justify-content : center;
  padding : 30px 0;
  background: linear-gradient(to bottom,
    rgba(0,0,102,1) 0%,
    rgba(0,0,102,0.8) 50%,
    rgba(0,0,0,1) 100%),url(${({ BackGroundImage }) => BackGroundImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: 900px) {
      justify-content : space-around;

    }
`;
const InnerDiv = styled.div`
    ${({ theme }) => theme.common.flexCenterColumn};
    text-align : center;
    width : 500px;
    height : 500px;
    border-radius : 50%;
    border : 5px solid #5C8AFF;
    overflow:hidden;
    @media screen and (max-width: 900px) {
      width : 400px;
      height : 400px;
    }
    margin-bottom : 20px;
  `;

const ThirdDiv = styled.div`
${({ theme }) => theme.common.SloganContainer};
  background : ${({ Color }) => Color};
  color : black;
  position : relative;
`
const LastDiv = styled.div`
${({ theme }) => theme.common.flexCenterColumn};
text-align : center;
width : 100%;
height : 100vh;
background: linear-gradient(to bottom, 
  rgba(0, 0, 0, 0.6) 0%, 
rgba(0, 0, 0, 0.3) 100%), 
url(${({ BackGroundImage }) => BackGroundImage});
background-size: cover;
background-position: center;
background-repeat: no-repeat;
gap : 10px;
  `;

const Text_Message = styled.h3`
  ${({ theme }) => theme.fontStyle.Text_Message};
`
const Text_SubMessage = styled.p`
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
`
const Text_Point = styled.p`
${({ theme }) => theme.fontStyle.Text_Point};
`
