import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../../utils/api';
import styled from 'styled-components';
import EmptyPart from '../../../Layouts/EmptyPart';
import { IoIosArrowBack } from "react-icons/io";

const QnADetail = ({user}) => {
    const { idx } = useParams();
    const [post, setPost] = useState({});
    const [Open, setOpen] = useState(false);

    const navigate = useNavigate();

    const getPost = async () => {
        try {
            const resp = await api.get(`/qna/${idx}`);
            const data = resp.data;
            setPost(data.data);
        } catch (error) {
            console.error("Error fetching column data:", error);
        }
    };
    const getUser = async () => {
        if(user){
            setOpen(true)
        }
    };
    
    useEffect(() => {
        getPost();
        getUser();
    }, [idx]);

    const [password, setPassword] = useState(null);
    const onPasswordHandler = (event) => {
        setPassword(event.currentTarget.value);
    }
    const isOpen=()=>{
        if(password===post.Password){
            setOpen(true)
        }
        else{
            alert("비밀번호를 잘못 입력했습니다.")
        }
    }
    const handleSubmitKeyDown = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();//새로 고침 막기
          isOpen();
        }
      };
    const formattedDate = new Date(post.createdAt).toLocaleDateString('ko-KR');

    return (
        <Container>
            <ContentDiv>
            <div style={{width:"100%", display : "flex", justifyContent : "flex-start", borderBottom:"1px solid black"}}>
            <Button onClick={()=>navigate("/QnAList")}><IoIosArrowBack/><>뒤로 가기</></Button>

            </div>
                
                {!Open &&<Content>
                    <ContentHead>
                        <p>비공개 게시글</p>
                        <About>
                        <AboutComponent>작성자 : {post.Name}</AboutComponent>
                        <AboutComponent>연락처 : ********</AboutComponent>
                        <AboutComponent>작성 일자 : {formattedDate}</AboutComponent>
                        </About>
                    </ContentHead>
                    {!Open && <ResisterDiv>
                    <InputBox
                        type='password'
                        name='비밀번호'
                        placeholder='비밀번호를 입력해주세요'
                        value={password}
                        onChange={onPasswordHandler}
                        onKeyDown={handleSubmitKeyDown}
                    />
                    <StyleBtn onClick={()=>isOpen()}>제출</StyleBtn>
                   
                </ResisterDiv>}
                    <ContentBody>
                        <p style={{color : "grey"}}>비공개 게시글입니다. 문의를 작성할 때 기입했던 비밀번호를 제출해주세요.</p>
                    </ContentBody>
                </Content>}
                
               { Open&&<Content>
                    <ContentHead>
                        <p>{post.Title}</p>
                        <About>
                        <AboutComponent>작성자 : {post.Name}</AboutComponent>
                        <AboutComponent>연락처 : {post.Email}</AboutComponent>
                        <AboutComponent>작성 일자 : {formattedDate}</AboutComponent>
                        </About>
                    </ContentHead>
                    <ContentBody>
                        <p>{post.Content}</p>
                    </ContentBody>
                </Content>}
               

            </ContentDiv>
        </Container>
    );
};

export default QnADetail;

const Container = styled.div`
background: ${({ theme }) => theme.colors.BoxColor};
${({ theme }) => theme.common.flexCenterColumn};
width: 100%;
height: auto;
padding : 10% 30%;
@media screen and (max-width: 900px) {
    padding : 20% 2% ;
}
`;
const ContentDiv = styled.div`
    background: #fff;
    ${({ theme }) => theme.common.flexCenterColumn};
    width : 100%;
    border-radius : 20px;
    padding: 2%;
    
`
const Content = styled.div`
    ${({ theme }) => theme.common.flexCenterColumn};
    justify-content : flex-start;
    align-items : flex-start;
    width : 100%;
    gap : 20px;
    padding : 10% 0;
    min-height: 80vh;
    @media screen and (max-width: 900px) {
        padding : 5% 0;
        gap : 5px;
    }
`
const ContentHead = styled.div`
    width : 100%;
    display : flex;
    flex-flow : column nowrap;
    justify-content : flex-start;
    align-items : flex-start;
    ${({ theme }) => theme.fontStyle.Text_Message};
    gap : 20px;
    border-bottom : 2px solid grey;
    padding : 10px;
    @media screen and (max-width: 900px) {
        padding : 0;
        gap : 5px;
    }
`
const About = styled.div`
    width : 100%;
    display : flex;
    flex-flow : row wrap;
    justify-content : flex-start;
    align-items : center;
    gap : 20px;
    border-radius : 20px;
    @media screen and (max-width: 900px) {
        gap : 5px;
    }
`
const AboutComponent = styled.div`
    ${({ theme }) => theme.fontStyle.Text_SubMessage};
    color :grey;
`
const ContentBody = styled.div`
    width : 100%;
    display : flex;
    flex-flow : column nowrap;
    justify-content : flex-start;
    align-items : flex-start;
    ${({ theme }) => theme.fontStyle.Text_Paragraph};
`
const ResisterDiv = styled.div`
  display:flex;
  justify-content : flex-start;
  align-items : center;
  width : 100%;
  border: 1px solid red;
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  border: 1px solid #F0BB00;
  background: #fff;
  border-radius: 6px;
  height: 50px;
  overflow : hidden;
  margin : 10px 0;
`

const InputBox = styled.input`
  display: flex;
  width : 70%;
  background-color: transparent;
  border : none;
  height : 100%;
  outline:none;
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  padding: 0 0 0 10px;
`
const StyleBtn = styled.button` 
  background: #F0BB00;
  color : black;
  justify-content: center;
  align-items: center;  
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  outline:none;
  border : none;
  height: 100%; 
  flex:1;
`
const Button = styled.button`
    background : transparent;
    color : black;
    border: none;
    cursor: pointer;
    outline : none;
    display : flex;
    align-items : center;
    ${({ theme }) => theme.fontStyle.Text_SubMessage};
  `;

