import styled from "styled-components";

// font size를 객체로 반환
const fontStyle = {
  Text_Hero: `
  font-family :"BMHANNAPro","Pretendard";
  font-size : 72px;
  line-height:100.8px;
  letter-spacing:-0.32px;
  
  word-spacing:0px;
  font-style:normal;
  font-variant:normal;
  text-transform:none;
  text-indent:0px;
  word-break :keep-all;
  text-align: center;
  font-weight:700;
  @media screen and (max-width: 900px) {
    font-size : 24px;
    line-height:36px;
  }
  margin-bottom: 20px
`,
Text_Message: `
  font-family :"Noto Sans KR","Pretendard";
  font-size : 36px;
  line-height:52px;
  letter-spacing:-0.3px
  word-spacing:0px;
  font-weight:400;
  font-style:normal;
  font-variant:normal;
  text-transform:none;
  text-indent:0px;
  word-break :keep-all;
  font-weight:700;
  @media screen and (max-width: 900px) {
    font-size : 24px;
    line-height:36px;
  }
  margin-bottom: 20px;
`,
Text_SubMessage: `
font-family :"Noto Sans KR","Pretendard";
font-size : 16px;
line-height:28px;
letter-spacing:-0.32px
word-spacing:0px;
font-weight:400;
font-style:normal;
font-variant:normal;
text-transform:none;
text-indent:0px;
word-break :keep-all;
text-align: center;
`,
Text_large: `
font-family :"BMHANNAPro","Pretendard";
font-size : 40px;
line-height:54px;
letter-spacing:-0.3px
word-spacing:0px;
font-weight:400;
font-style:normal;
font-variant:normal;
text-transform:none;
text-indent:0px;
word-break :keep-all;
@media screen and (max-width: 900px) {
  font-size : 32px;
  line-height:43.2px;
}
`,
Text_Point: `
font-family :"Noto Sans KR","Pretendard";
font-size : 20px;
line-height:32px;
letter-spacing:-0.32px
word-spacing:0px;
font-weight:700;
font-style:normal;
font-variant:normal;
text-transform:none;
text-indent:0px;
word-break :keep-all;
@media screen and (max-width: 900px) {
  font-size : 16px;
  line-height: 26px;
}
`,
Text_Paragraph: `
font-family :"Noto Sans KR","Pretendard";
font-size : 16px;
line-height:26px;
letter-spacing:-0.32px
word-spacing:0px;
font-weight:400;
font-style:normal;
font-variant:normal;
text-transform:none;
text-indent:0px;
word-break :keep-all;
@media screen and (max-width: 900px) {
  font-size : 16px;
  line-height: 26px;
}
`,
Text_BigParagraph: `
font-family :"Noto Sans KR","Pretendard";
font-size : 18px;
line-height:36px;
letter-spacing:-0.32px
word-spacing:0px;
font-weight:400;
font-style:normal;
font-variant:normal;
text-transform:none;
text-indent:0px;
word-break :keep-all;
@media screen and (max-width: 900px) {
  font-size : 16px;
  line-height:24px;
}
`,
Text_Xsmall: `
font-family :"Noto Sans KR","Pretendard";
font-size : 14px;
line-height:36px;
letter-spacing:-0.32px
word-spacing:0px;
font-weight:400;
font-style:normal;
font-variant:normal;
text-transform:none;
text-indent:0px;
word-break :keep-all;
@media screen and (max-width: 900px) {
  font-size : 12px;
  line-height:24px;
}
`,
};

// 자주 사용하는 색을 객체
const colors = {
  BoxColor: "#141414",
  commonTxt: "#ffffff",
  BlackTxt : "#070707",
  GreyTxt : "#939393",
  MainColor: "#999999",
  PointColor: "#27FF97", 
  HashColor : "#F0BB00"
};

// 자주 사용하는 스타일 속성
const common = {
  flexCenterRow: `
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  flexCenterColumn: `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  StyledButton: `
    border : none;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
    7px 7px 20px 0px rgba(0,0,0,.1),
    4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none;
  `,
  HeroContainer : `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background : #141414;
    width : 100%;
    height : 100vh;
    background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  `,
  SloganContainer : `
  width : 100%;
  display : flex;
  flex-flow : column nowrap;
  align-items : center;
  justify-content : center;
  text-align : center;
  height : 100vh;
  @media screen and (min-width: 901px) {
    padding : 10% 20%;
    gap : 70px;
  }
  @media screen and (max-width: 900px) {
    padding : 10% 5%;
    gap : 70px;
  }
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align : center;
  `,
  SwitchDiv : `
  position : relative;
  width : 100%;
  display : flex;
  flex-flow : column wrap;
  align-items : center;
  justify-content : center;
  `,
  FrontDiv : `
  position : absolute;
  height:100%;
  width:100%;
  top : 50%;
  left : 50%;
  transform: translate(-50%,-50%);
  `,
  BackDiv : `
  position : absolute;
  height:100%;
  width:100%;
  top : 50%;
  left : 50%;
  transform: translate(-50%,-50%);
  `,
  CommonGrid :`
  width: 100%;
  display : grid;
  @media screen and (min-width: 901px) {
    display : grid;
    grid-template-columns:  1fr 1fr;
  }
  @media screen and (max-width: 900px) {
    display : grid;
    grid-template-rows:  1fr 1fr;
  }
  `
};


// theme 객체에 감싸서 반환한다.
const theme = {
  fontStyle,
  colors,
  common,
};

export default theme;