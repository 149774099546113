import Home from "./View/Home";
/** WeAre 군락 페이지 **/
import Introduce from "./View/WeAre/Introduce";
import CoreValue from "./View/WeAre/CoreValue";
/** We Make 군락 페이지 **/
import Marketing from "./View/WeMake/Marketing";
import Entertainment from "./View/WeMake/Entertainment";
import LifePlatform from "./View/WeMake/LifePlatform";
/** Our Story 군락 페이지 **/
import Columns from "./View/OurStory/Columns";
import ColumnDetail from "./Layouts/ColumnDetail";
import TextEditor from "./View/OurStory/TextEditor";
import Members from "./View/OurStory/Members";
import FootPrint from "./View/OurStory/FootPrint";
import AboutUs from "./View/OurStory/AboutUs";
/** With Us 군락 페이지 **/
import CoopInfo from "./View/WithUs/CoopInfo";
import Recruitment from "./View/WithUs/Recruitment";
import Careers from "./View/WithUs/Careers";
import CareerEdit from "./View/WithUs/CareerEdit";
import CareersDetail from "./View/WithUs/CareersDetail";
import QnA from "./View/WithUs/QnA";
import QnAList from "./View/WithUs/QnA/QnAList";
import QnADetail from "./View/WithUs/QnA/QnADetail";
import Recruitment1 from "./View/WithUs/Recruitment1";
import Recruitment2 from "./View/WithUs/Recruitment2";
/** Aurum Report 군락 페이지 **/
import GalleryEditor from "./View/AurumReport/Gallery/GalleryEditor";
import Legacy from "./View/AurumReport/Legacy";
import LegacyDetail from "./View/AurumReport/Legacy/LegacyDetail";
import LegacyEditor from "./View/AurumReport/Legacy/LegacyEditor";
import CommunityDetail from "./View/AurumReport/Community/CommunityDetail";
import CommunityPost from "./View/AurumReport/Community/CommunityPost";
/** 회원가입 기능 군락 페이지 **/
import Login from "./View/Login/Login";
import Register from "./View/Login/Register";
import Mypage from "./View/Login/Mypage";
import LoggedPage from "./View/Login/LoggedPage";
import MemberManage from "./View/Login/MemberManage";
/** 글로벌 **/
import Header from "./Layouts/Header";
import Footer from "./Layouts/Footer";
/** 유저 정보 get **/
import api from "./utils/api";
/** 라우팅 관련 **/
import PrivateRoute from "./route/PrivateRoute";
import SecreatRoute from "./route/SecreatRoute";
import RouteChangeTracker from "./utils/RouteChangeTracker";
/** 스타일 임포트 **/
import GlobalStyle from "./Global/GlobalStyle";
import Theme from "./Global/Theme";
import styled, { ThemeProvider } from 'styled-components';
/** 리액트 훅&라이브러리 **/
import React, { useEffect, useState, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

/** 레이지 임포트 : 갤러리/ 메세지 / 브랜드 **/
const Messages = lazy(() => import('./View/WeAre/Message'));
const Gallery = lazy(() => import('./View/AurumReport/Gallery'));
const Brand = lazy(() => import('./View/WeAre/Brand'));
const Community = lazy(() => import('./View/AurumReport/Community'));

function RenderHeader({ children, setUser, user }) {
  const location = useLocation();
  const isIntroPage = location.pathname === '/';
  const BrandPage = location.pathname === '/Brand';

  return (
    <>
      {!isIntroPage && (<Header setUser={setUser} user={user} />)}
      {children}
      {(!isIntroPage && !BrandPage) && (<Footer />)}
    </>
  );
}

function App() {
  const [user, setUser] = useState(null);

  const getUser = async () => {
    try {
      const storedToken = sessionStorage.getItem("token");
      if (storedToken) {
        const response = await api.get("/user/me");
        setUser(response.data.user);
      }
    } catch (error) {
      setUser(null);
    }
  }

  useEffect(() => {
    getUser();
  }, []);


  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <Suspense fallback={<div>Loading...</div>}>
        <RenderHeader setUser={setUser} user={user} >
          <RouteChangeTracker />
          <Routes location={location}>
          
            <Route path="/" element={<Home />} />
            {/* WeAre 군락 페이지 */}
            <Route path="/Introduce" element={<Introduce />} />
            <Route path="/CoreValue" element={<CoreValue />} />
            {/* We Make 군락 페이지 */}
            <Route path="/Marketing" element={<Marketing />} />
            <Route path="/Entertainment" element={<Entertainment />} />
            <Route path="/LifePlatform" element={<LifePlatform />} />
            {/* Our Story 군락 페이지 */}
            <Route path="/Columns" element={<Columns setUser={setUser} user={user} />} />
            <Route path="/Columns/:idx" element={<ColumnDetail />} />
            <Route path="/Columnsdetail" element={<ColumnDetail />} />
            <Route path="/Members" element={<Members />} />
            <Route path="/FootPrint" element={<FootPrint />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            {/* With Us 군락 페이지 */}
            <Route path="/CoopInfo" element={<CoopInfo />} />
            <Route path="/Careers" element={<Careers />} />
            <Route path="/CareersDetail" element={<CareersDetail />} />
            <Route path="/Careers/:idx" element={<CareersDetail />} />
            <Route path="/QnA" element={<QnA />} />
            <Route path="/QnAList" element={<QnAList />} />
            <Route path="/QnA/:idx" element={<QnADetail setUser={setUser} user={user} />} />
            <Route path="/Recruitment" element={<Recruitment />} />
            <Route path="/Recruitment1" element={<Recruitment1 />} />
            <Route path="/Recruitment2" element={<Recruitment2 />} />
            {/* Aurum Report 군락 페이지 */}
            <Route path="/Legacy" element={<Legacy />} />
            <Route path="/Legacy/:idx" element={<LegacyDetail />} />
            <Route path="/Community/:idx" element={<CommunityDetail setUser={setUser} user={user} />} />
            {/* 회원 가입 관련 군락 페이지 */}
            <Route path="/Login" element={<Login setUser={setUser} user={user} />} />
            <Route path="/LoggedPage" element={<LoggedPage setUser={setUser} user={user} />} />
            <Route path="/register" element={<Register />} />
            <Route path="/Mypage" element={<PrivateRoute user={user}><Mypage setUser={setUser} user={user} /></PrivateRoute>} />
            {/* 회원 가입 관련 권한 페이지 */}
            <Route path="/MemberManage" element={<SecreatRoute user={user}>
              <MemberManage setUser={setUser} user={user} /></SecreatRoute>} />
            {/* 에디터 페이지 */}
            <Route path="/TextEditor" element={<PrivateRoute user={user}><TextEditor /></PrivateRoute>} />
            <Route path="/GalleryEditor" element={<PrivateRoute user={user}><GalleryEditor setUser={setUser} user={user} /></PrivateRoute>} />
            <Route path="/LegacyEditor" element={<PrivateRoute user={user}><LegacyEditor setUser={setUser} user={user} /></PrivateRoute>} />
            <Route path="/CareerEdit" element={<PrivateRoute user={user}><CareerEdit setUser={setUser} user={user} /></PrivateRoute>} />
            <Route path="/CommunityPost" element={<PrivateRoute user={user}><CommunityPost setUser={setUser} user={user} /></PrivateRoute>} />
            {/* 레이지 로딩 */}
            <Route path="/Messages" element={<Messages />} />
            <Route path="/Brand" element={<Brand />} />
            <Route path="/Gallery" element={<Gallery />} />
            <Route path="/Community" element={<Community setUser={setUser} user={user} />} />
          </Routes>
        </RenderHeader>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
