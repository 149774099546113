import styled from 'styled-components';
import HeroContainer2 from '../../Layouts/HeroContainer2';
import ImgComponent from '../../Layouts/ImgComponent';
import { IoIosArrowForward   } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import EmptyPart from '../../Layouts/EmptyPart';


function LifePlatform() {
  const navigate = useNavigate();
  const handleClick = (url) => {
    navigate(url);
  };

    return (
      <Container>
        <HeroContainer2 imgPath={"/ImgSrc/LifeplatformHero.webp"}>
          <HeroMessage>
              <Text_SubMessage>나 어릴 때,<br/>
              충북 옥천 우리 할아버지는<br/>
              스마트폰은 안쓰시는데, 에어컨은 쓰셔</Text_SubMessage>

              <Text_SubMessage>우리의 행동이 바뀌고, 문화가 바뀌는 것.<br/>
                그리고 삶이 바뀌는 것. 때로는 느리더라도,</Text_SubMessage>
              <Text_Message>더 나은 방식으로 바꾸게 하는 것.</Text_Message>

              <Text_SubMessage>그래, 그거 할 꺼야</Text_SubMessage>

              <Text_Paragraph>Aurum, <br/>
                -marketing department representative, cheif-</Text_Paragraph>
          </HeroMessage>
        </HeroContainer2>
        <ProductContainer>
          <AskToDiv>
            <AskFor>
              <Text_Hero>ASK FOR <br/>LIFE</Text_Hero>
            </AskFor>
            
            <StyledContainer>
              <StyledBox width={"15px"} color={'#01DF74'}/>
              <StyledBox width={"30px"} color={'#01DF74'}/>
              <StyledBox width={"80px"} color={'#01DF74'}/>
              <StyledBox width={"180px"} color={'#01DF74'}/>
            </StyledContainer>
            <ContactDiv onClick={() => handleClick('/QnA')}>
            <Text_Message style={{marginBottom:"0"}}>CONTACT US</Text_Message>
              <ContactArrow />
            </ContactDiv>
          </AskToDiv>
          <CodeContainer>
              <CodeDiv>
                <Text_Paragraph>***</Text_Paragraph>
                <Text_Paragraph>
                  오럼 고유 코드가 있으신 고객사 분들은 본 창구가 아닌 사전에 안내된 창구를 활용해주시기 바랍니다
                </Text_Paragraph>
              </CodeDiv>
          </CodeContainer>
          <ProductDiv>
            <ProductCard>
              <CardItem>
                <Text_Message>돈키호테</Text_Message>
                <>
                돈키호테 유닛은, 불특정 다수 대중들의 보편적인
                레저 형태에서 벗어나, 그동안 내가 겪어보지 않아서,
                생소했던 새로운 경험들과 재미들을 찾고, 
                 내 삶의 일부분으로 융합할 수 있는 라이프 서비스입니다.
                </>
                <HashDiv>
                  <HashTag><p>#모험적</p></HashTag>
                  <HashTag><p>#탐험</p></HashTag>
                  <HashTag><p>#새로움</p></HashTag>
                  <HashTag><p>#어드벤처</p></HashTag> 
                </HashDiv>
              </CardItem>
              <CardImg imagePath={"Lifeplatform1.webp"}/>
            </ProductCard>

            <ProductCard className='reverse'>
              <CardImg imagePath={"Lifeplatform2.webp"}/>

              <CardItem>
                <Text_Message>알렉산드리아</Text_Message>
                <>
                알렉산드리아 유닛은, 그 순간이 지나면 그대로 흘러가 
                주워담을 수 없는, 실제 삶 속의 나의 말과 행동, 
                흔적과 기록 중 내가 원하는 것들을 
                 아카이빙하여, 나의 라이프의 가치를 올려줍니다.
                </>
                <HashDiv>
                  <HashTag><p>#수집</p></HashTag>
                  <HashTag><p>#기록</p></HashTag>
                  <HashTag><p>#아카이빙</p></HashTag>
                  <HashTag><p>#기억</p></HashTag> 
                </HashDiv>
              </CardItem>    
            </ProductCard>
            <ProductCard>
              <CardItem>
                <Text_Message>사트라프</Text_Message>
                <>
                샤프라트 유닛은, 나의 삶을 세상과 가장 밀접한 형태로
                연결하여, 같이, 함께 하는 사회 속에서 나의 위치와 
                정체성을 공고히 하고 더 나아가 그 다음의 방향을 정확하게 
                탐색하는 라이프 서비스입니다.</>
                <HashDiv>
                  <HashTag><p>#연결</p></HashTag>
                  <HashTag><p>#상호작용</p></HashTag>
                  <HashTag><p>#방향</p></HashTag>
                  <HashTag><p>#긴밀함</p></HashTag> 
                </HashDiv>
              </CardItem>
              <CardImg imagePath={"Lifeplatform3.webp"}/>

            </ProductCard>
          </ProductDiv>
          <EmptyPart/>
        </ProductContainer>
      </Container>
    );
  }
  
export default LifePlatform;


const Container = styled.div`
    background : ${({ theme }) => theme.colors.BoxColor};
    ${({ theme }) => theme.common.flexCenterColumn};
    width : 100%;
    height : auto;
    color : white;
    overflow-x : hidden;
`
const HeroMessage = styled.div`
${({ theme }) => theme.common.flexCenterColumn};
  width : 100%;
  gap : 20px;
  `

const ProductContainer = styled.div`
    ${({ theme }) => theme.common.flexCenterColumn};
    width : 100%;
    height : auto;
`
const AskToDiv = styled.div`
    width : 100%;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;
    padding : 10%;
    text-align : left;
    @media screen and (max-width: 900px) {
      flex-direction : column;
      align-items : flex-start;
      background : #fff;
    }
`
const AskFor = styled.div`
    margin : 0 20% 0 0;
    @media screen and (max-width: 900px) {
      margin : 0;
      color : black;
    }
   
`

const StyledContainer = styled.div`
    display : flex;
    gap : 20px;
    @media screen and (max-width: 900px) {
      display : none;
    }
`
const StyledBox = styled.div`
    width :   ${({ width }) => width};
    height : 164px;
    background :   ${({ color }) => color};
`

const ContactDiv = styled.div`
  margin : 0 0 0 -2%;
  @media screen and (max-width: 900px) {
    width : 100%;
    margin : 5% 0 0 0%;
    color : #fff;
    background : #000;
    border-radius:15px;
    display : flex;
    justify-content : space-between;
    align-items : center;
    padding : 15px;
  }
`


const ContactArrow = styled(IoIosArrowForward)`
  display:none;
  color : #fff;
  @media screen and (max-width: 900px) {
    display:flex;
    font-size : 6vmin;
  }
`
const CodeContainer = styled.div`
  width : 100%;
  height : auto;
  display : flex;
  justify-content : center;
  padding : 10%;
  @media screen and (max-width: 500px) {
    padding : 5% 2%;
  }
`
const CodeDiv = styled.div`
  width : 80%;
  height : auto;
  display : flex;
  flex-direction : column;
  align-items : center;
  text-align : center;
  padding : 5% 10%;
  background: #1B1B1B;
  @media screen and (max-width: 500px) {
    padding : 5% 2%;
  }
`
const ProductDiv = styled.div`
  width : 100%;
  height : auto;
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content :center; 
  padding : 10% 30%;
  gap : 200px;
  @media screen and (max-width: 900px) {
    gap : 150px;
  padding : 10% 5%;
  }
`
const ProductCard = styled.div`
  width : 100%;
  height : auto;
  background :  ${({ color }) => color};
  display : flex; 
  justify-content : center;
  align-items : center;
  flex-flow : row wrap;
  gap : 50px;
  padding : 10%;
  @media screen and (max-width: 900px) {
    border-bottom:1px solid grey;
    padding : 10% 0%;
    display : flex; 
    justify-content : center;
    align-items : center;
    flex-flow : column wrap;
    &.reverse {
      flex-direction: column-reverse;
    }
  }
`
const CardItem = styled.div`
  display : flex; 
  flex : 1;
  flex-flow : column wrap;
  justify-content : center;
  align-items : flex-start;
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
  text-align : left;
  @media screen and (max-width: 900px) {
    width : 100%;
    flex : 1;
  }
`

const ImageContainer = styled.div`
  object-fit: cover;
  background-color: transparent;
  display : flex; 
  flex : 1;
  justify-content : center;
  align-items : center;
  border-radius: 40px;
  @media screen and (max-width: 900px) {
    width : 100%;
  }
`;

const CardImg = ({ imagePath}) => {
  return (
    <ImageContainer>
      <img
        src={`${process.env.PUBLIC_URL}/ImgSrc/${imagePath}`}
        alt="페이지 이미지"
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
        }}
      />
    </ImageContainer>
  );
};

const HashDiv=styled.div`
  display : flex;
  flex-flow : row wrap;
  color: #F0BB00;
  gap : 10px;
  margin : 20px 0;
`
const HashTag=styled.div`
  wordWrap: break-word;
  padding : 5px 5px;
  border: 1px #F0BB00 solid;
  justifyContent: center;
  alignItems: center;
  border-radius: 10px;
  ${({ theme }) => theme.fontStyle.Text_Xsmall};
`

const Text_Message = styled.h3`
  ${({ theme }) => theme.fontStyle.Text_Message};
`
const Text_SubMessage = styled.p`
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
`
const Text_Hero = styled.h2`
  ${({ theme }) => theme.fontStyle.Text_Hero};
  text-align : left;
`
const Text_Paragraph = styled.p`
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
`