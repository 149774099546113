import React, { useState, useMemo, useRef, useEffect } from 'react';
import styled from 'styled-components';
import api from '../../utils/api';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';

const CareerEdit = ({ user }) => {
    const [Title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [ApplicationDeadline, setApplicationDeadline] = useState("");

    const [Area, setArea] = useState("");
    const [EmploymentType, setEmploymentType] = useState("");
    const [Target, setTarget] = useState("");

    const [error, setError] = useState("");

    const navigate = useNavigate();

    const onTitleHandler = (event) => {
        setTitle(event.currentTarget.value);
    }
    const onContentHandler = (value) => {
        setContent(value);
    }

    const onApplicationDeadlineHandler = (event) => {
        setApplicationDeadline(event.currentTarget.value);
    }


    const ClickHandler = (event) => {
        console.log("subtag", event.currentTarget.className);
        if(event.currentTarget.className==="모집 분야"){
            setArea(event.currentTarget.value);
        }
        else if(event.currentTarget.className==="모집 대상"){
            setTarget(event.currentTarget.value);
        }
        else if(event.currentTarget.className==="고용 형태"){
            setEmploymentType(event.currentTarget.value);
        }
    }

  
    const quillRef = useRef(null);

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        try {
            const response = await api.post('/careers',
                {
                    Title,
                    content,
                    Area,
                    EmploymentType,
                    Target,
                    ApplicationDeadline
                });
            if (response.status == 200) {
                console.log("들어감")
                navigate('/Careers')
            }
            else {
                throw new Error(response.data.error)
            }
        } catch (error) {
            setError(error.message);
        }
    }
    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [{ size: ['small', false, 'large', 'huge'] }],
                    [{ align: [] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [
                        {
                            color: [],
                        },
                        { background: [] },
                    ],
                ],

            },
        };
    }, []);

    return (
        <Container>
            <PostDiv>
                <StyleForm
                    onSubmit={onSubmitHandler}
                    method="POST"
                    enctype="multipart/form-data">
                    <InputBox
                        type="Title"
                        placeholder="모집 제목"
                        value={Title}
                        onChange={onTitleHandler}
                    />
                    <TagDiv>
                        {TagList.map((tag) => (
                            <>
                                <label>{tag.Title}</label>
                                <select className={tag.Title} required onChange={ClickHandler}>
                                    {tag.SubTag.map((subtag) => (
                                        <option value={subtag.tag}>{subtag.tag}</option>
                                    ))}
                                </select>
                            </>
                        ))}
                    </TagDiv>
                    <InputBox
                        type="ApplicationDeadline"
                        placeholder="모집 마감 일자 : 2024-03-02"
                        value={ApplicationDeadline}
                        onChange={onApplicationDeadlineHandler}
                    />
                    <ReactQuill
                        theme="snow"
                        modules={modules}
                        onChange={onContentHandler}
                        ref={quillRef}
                        style={{ height: "500px", margin: "25px 0 ", width: '100%' }}

                    />
                    <SubmitButton style={{ height: "60px", margin: "30px 0", width: '30%' }} type='submit'>공고 등록</SubmitButton>
                </StyleForm>

            </PostDiv>
        </Container>
    );
};

export default CareerEdit;

const Container = styled.div`  
background: #F4F4F4;
${({ theme }) => theme.common.flexCenterColumn};
width: 100%;
height: auto;
color: white;
`;

const PostDiv = styled.div`
    ${({ theme }) => theme.common.flexCenterColumn};
    width: 100%;
    height: auto;
    padding : 10% 30%; 
    color: #000;
    ${({ theme }) => theme.fontStyle.Text_Paragraph};
    @media screen and (max-width: 900px) {
        padding : 25% 5%; 
    }
`

const StyleForm = styled.form`
    width: 100%;
    height: auto;
    border: 1px solid #ECECEC;
    display: flex;
    flex-flow : column nowrap;
    gap : 20px;
`

const InputBox = styled.input`
display: flex;
padding: 14px;
align-items: center;
align-self: stretch;
width : 100%;
border: 1px solid #ECECEC;
background: #F9F9F9;
@media screen and (max-width: 900px) {
  padding: 18px 18px;
}
`


const SubmitButton = styled.button`
    width: 100px;
    height: 50px;
    border: none;
    border-radius: 4px;
    background: #007bff;
    color: white;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
        background: #0056b3;
    }
`;

const TagDiv = styled.div`
 display  : flex;
 flex-flow : row wrap;
 gap : 10px;
`

const TagList = [
    {
        index: "1",
        Title: "모집 분야",
        SubTag: [
            { tag: "Marketing" },
            { tag: "Tech" },
            { tag: "H&R" },
        ]
    },
    {
        index: "2",
        Title: "모집 대상",
        SubTag: [
            { tag: "경력" },
            { tag: "신입" },
        ]
    },
    {
        index: "3",
        Title: "고용 형태",
        SubTag: [
            { tag: "계약직" },
            { tag: "정규직" },
            { tag: "인턴" },
            { tag: "파트타임" },
            { tag: "일용직" },
        ]
    },

];