import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import api from '../../../utils/api';
import ImageSlide from './ImageSlide';
import { IoHeartOutline } from "react-icons/io5";

const CommunityDetail = ({ user }) => {
    const { idx } = useParams();
    const [post, setPost] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [Content, setContent] = useState("");
    const [commentList, setCommentList] = useState([]);

    // const [Author, setAuthor] = useState(user.nickname);
    // const [AuthorProfile, setAuthorProfile] = useState(user.Profile);

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        try {
            if (!user) {
                alert("오럼의 구성원만 댓글을 작성할 수 있습니다.")
            }
            else {
                const Author = user.nickname;
                const AuthorProfile = user.Profile;
                const PostedId = idx;
                const response = await api.post('/comment/post',
                    { Author, AuthorProfile, Content, PostedId });
                if (response.status == 200) {
                    setContent("");
                    getComent(response); // 댓글 리스트 갱신
                }
                else {
                    throw new Error(response.data.error)
                }
            }
        } catch (error) { //에러가 발생했을 때
            console.error('Error uploading Comment:', error); //에러 메시지 출력
        }
    }
    const onCommentHandler = (event) => {
        setContent(event.currentTarget.value);
    }
    const getContent = async () => {
        try {
            const resp = await api.get(`/community/${idx}`);
            setPost(resp.data.data);
            setLoading(false);
            getComent();
        } catch (error) {
            console.error("Error fetching community data:", error);
            setLoading(false);
        }
    };
    const getComent = async () => {
        try {
            const resp = await api.get(`/comment/${idx}`);
            setCommentList(resp.data.data); // 댓글 데이터를 상태에 설정
        } catch (error) {
            console.error("Error fetching comment data:", error);
        }
    };

    useEffect(() => {
        getContent();
    }, [idx]);


    const formattedDate = new Date(post.createdAt).toLocaleDateString('ko-KR');

    return (
        <Container>
            <PostContainer>
                <HeaderDiv>
                    <Text_Message>사내게시판</Text_Message>
                </HeaderDiv>
                {loading ? (
                    <LoadingText>Loading...</LoadingText>
                ) : (
                    <ContentContainer>
                        <BackDiv> <BackBtn onClick={() => navigate('/community')}>이전</BackBtn></BackDiv>
                        <PostWrapper>
                            <AuthorDiv>
                                <Profile src={post.AuthorProfile ? post.AuthorProfile : "https://aurumnexus-imagebucket.s3.ap-northeast-2.amazonaws.com/userBasic.jpg"} />
                                <Text_Point>{post.Author}</Text_Point>
                                <Text_Xsmall>{formattedDate}</Text_Xsmall>
                            </AuthorDiv>
                            {post.Image && post.Image.length > 0 && (
                                <ImageSliderDiv>
                                    <ImageSlide Imaglist={post.Image} />
                                </ImageSliderDiv>
                            )}
                            <CaptionDiv>
                                <CaptionBox>
                                    <Text_Paragraph>{post.Caption}</Text_Paragraph>
                                    <Text_Xsmall style={{ color: "#5C8AFF" }}>{post.Tag}</Text_Xsmall>
                                </CaptionBox>
                                <CommentDiv>
                                    <PostingLiked>
                                        <IoHeartOutline />
                                        {post.Like.length !== 0 &&
                                            <Text_Xsmall><b>{post.Like[0]}</b> 외 {post.Like.length}명이 좋아합니다.</Text_Xsmall>
                                        }
                                    </PostingLiked>
                                    {commentList.map((com) => (
                                        <CommentBox>
                                            <CommentProfile src={com.AuthorProfile ? com.AuthorProfile : "https://aurumnexus-imagebucket.s3.ap-northeast-2.amazonaws.com/userBasic.jpg"} />
                                            <Text_Xsmall>{com.Author}</Text_Xsmall>
                                            <Text_Xsmall>{com.Content}</Text_Xsmall>
                                            <Text_Xsmall style={{ color: "grey" }}>{new Date(com.createdAt).toLocaleDateString('ko-KR')}</Text_Xsmall>
                                        </CommentBox>
                                    ))}
                                </CommentDiv>
                                <CommentInputDiv onSubmit={onSubmitHandler}>
                                    <div style={{ width: "100%" }}>
                                        <CommentInput
                                            type="text"
                                            value={Content}
                                            onChange={onCommentHandler}
                                            placeholder="댓글 달기..."
                                        />
                                        <CommentSubmit type='submit'>게시</CommentSubmit>
                                    </div>
                                </CommentInputDiv>
                            </CaptionDiv>
                        </PostWrapper>
                    </ContentContainer>
                )}
            </PostContainer>
        </Container>
    );
};

export default CommunityDetail;

const Container = styled.div`
    background: ${({ theme }) => theme.colors.BoxColor};
    ${({ theme }) => theme.common.flexCenterColumn};
    width: 100%;
    height: auto;
    color: white;
`;

const LoadingText = styled.p`
  color: #fff;
`;


const PostContainer = styled.div`
  width : 100%;
  display: flex;
  flex-flow : column wrap;
  justify-content :center;
  align-items : flex-start;
  padding : 5% 20%;
  color : white;
  @media screen and (max-width: 900px) {
    padding : 15% 2% ;
  }
`;

const HeaderDiv = styled.div`
  width : 100%;
  display: flex;
  flex-flow : row wrap;
  justify-content : space-between;
  align-items : center;
  @media screen and (max-width: 900px) {
    padding : 0% 2%;
  }
`;

const Text_Message = styled.p`
${({ theme }) => theme.fontStyle.Text_Message};
`
const Text_Paragraph = styled.p`
${({ theme }) => theme.fontStyle.Text_Paragraph};
`
const Text_Point = styled.p`
${({ theme }) => theme.fontStyle.Text_Point};
`
const Text_Xsmall = styled.p`
${({ theme }) => theme.fontStyle.Text_Xsmall};
margin :0;
`


const BackDiv = styled.div`
width : 100%;
padding : 0 5%;
display: flex;
@media screen and (max-width: 900px) {
padding : 0 2%;

}
`
const BackBtn = styled.button`
display: flex;
justify-content : center;
align-items : center;
border : none;
background : none;
padding : 15px;
color : white;
${({ theme }) => theme.fontStyle.Text_Paragraph};
`
const ContentContainer = styled.div`
  width : 100%;
  display: flex;
  flex-flow : column wrap;
  justify-content : flex-start;
  align-items : flex-start;
  border :1px solid grey;
  border-radius : 20px;
`

const PostWrapper = styled.div`
width : 100%;
display: flex;
flex-flow : row wrap;
justify-content : flex-start;
align-items : flex-start;
padding : 2% 5%;
gap : 20px;
@media screen and (max-width: 900px) {
    flex-flow : column wrap;
    padding : 2%;
    align-items : center;
}
`

const ImageSliderDiv = styled.div`
display: flex;
flex-flow : row nowrap;
justify-content : center;
align-items : center;
overflow : hidden;
border-radius : 25px;
max-width : 50%;
@media screen and (max-width: 900px) {
    max-width : 100%;
}
`
const CaptionDiv = styled.div`
    display: flex;
    flex : 1;
    flex-flow : column nowrap;
    justify-content : flex-start;
    align-items : flex-start;
    padding : 3%;
    gap : 20px;
`
const CaptionBox = styled.div`
width : 100%;
display: flex;
flex-flow : row wrap;
justify-content : flex-start;
align-items : flex-start;
`
const AuthorDiv = styled.div`
width : 100%;
display: flex;
flex-flow : row nowrap;
justify-content : flex-start;
align-items : center;
overflow : hidden;
gap : 20px;
${({ theme }) => theme.fontStyle.Text_Paragraph};
`

const Profile = styled.img`
width : 40px;
height : 40px;
display: flex;
justify-content : center;
align-items : center;
border-radius : 50%;
overflow : hidden;
border : 2px solid #0174DF;
`


const CommentDiv = styled.div`
    ${({ theme }) => theme.fontStyle.Text_Xsmall};
    width : 100%;
    max-height: 250px;
    color : white;
    overflow : scroll;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
`
const CommentBox = styled.div`
    width : 100%;
    background : transparent;
    display : flex;
    flex-flow : row wrap;
    justify-content : flex-start;
    align-items : center;
    gap : 5px;
    margin-bottom : 10px;
`
const CommentProfile = styled.img`
    width : 30px;
    height : 30px;
    background : transparent;
    display : flex;
    justify-content : center;
    align-items : center;
    border-radius : 50%;
`
const CommentInputDiv = styled.form`
    ${({ theme }) => theme.fontStyle.Text_Paragraph};
    width :100%;
    max-width : 600px;
    height: auto;
    display : flex;
    flex-flow: column nowrap;
    justify-content : center;
    align-items : flex-start; 
    gap : 5%;
`

const PostingLiked = styled.div`
width : 100%;
height : auto;
display: flex;
flex-flow : row wrap;
justify-content : flex-start;
align-items : center;
gap : 10px;
font-size : 3vmin;
margin-bottom : 10px;
`

const CommentInput = styled.input`
    ${({ theme }) => theme.fontStyle.Text_Xsmall};
    width : 70%;
    padding: 15px;
    color :#fff;
    background : #161616;
    border : none;
    box-shadow : 1px 1px 1px #666;
`
const CommentSubmit = styled.button`
    ${({ theme }) => theme.fontStyle.Text_Point};
    width : 20%;
    padding: 15px;
    background : #161616;
    color :#fff;
    border : none;
    outline:none;
`