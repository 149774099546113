import React, { useEffect, useState, useRef } from 'react';
import api from "../../utils/api";
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { IoPencil } from "react-icons/io5";
const Mypage = () => {
    const [userData, setUserData] = useState({});
    const [userName, setUserName] = useState("");
    const [userNickName, setUserNickName] = useState("");
    const [userlogid, setUserlogid] = useState("");
    const [userphone, setUserphone] = useState("");
    const [userLevel, setUserLevel] = useState("");
    const [userDepartment, setUserDepartment] = useState("");
    const [userIntroduce, setUserIntroduce] = useState("");
    const [userSlogan, setUserSlogan] = useState("");

    const [userProfile, setUserProfile] = useState("");

    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const modalBackground = useRef();
    const getUser = async () => {
        try {
            const response = await api.get("/user/me");
            console.log(response.data.user);
            setUserData(response.data.user);
            setUserName(response.data.user.name);
            setUserNickName(response.data.user.nickname);
            setUserlogid(response.data.user.logid);
            setUserphone(response.data.user.phonenumber);
            setUserLevel(response.data.user.Level);
            setUserDepartment(response.data.user.Department);
            setUserIntroduce(response.data.user.Introduce);
            setUserProfile(response.data.user.Profile);
            setUserSlogan(response.data.user.Slogan);
        } catch (error) {
            console.error("Error fetching User:", error);
        }
    }

    const updateUser = async () => {
        try {
            const response = await api.put("/user/update", {
                name: userName,
                nickname: userNickName,
                logid: userlogid,
                phonenumber: userphone,
                Profile: userProfile,
                Level: userLevel,
                Department: userDepartment,
                Introduce: userIntroduce,
                Slogan : userSlogan,
            });

            console.log("User updated successfully:", response.data);
            navigate("/LoggedPage");
        } catch (error) {
            console.error("Error updating User:", error);
        }
    }

    const onNameHandler = (event) => {
        setUserName(event.currentTarget.value);
    }
    const onNickNameHandler = (event) => {
        setUserNickName(event.currentTarget.value);
    }
    const onlogidHandler = (event) => {
        setUserlogid(event.currentTarget.value);
    }
    const onphoneHandler = (event) => {
        setUserphone(event.currentTarget.value);
    }
    const onLevelHandler = (event) => {
        setUserLevel(event.currentTarget.value);
    }
    const onDepartmentHandler = (event) => {
        setUserDepartment(event.currentTarget.value);
    }
    const onIntroduceHandler = (event) => {
        setUserIntroduce(event.currentTarget.value);
    }
    const onSloganHandler = (event) => {
        setUserSlogan(event.currentTarget.value);
    }
    const HandleProfile = (img) => {
        setUserProfile(img)
        setModalOpen(false)
    }
    const onSubmitHandler = async (event) => {
        event.preventDefault();
        updateUser();
    }

    useEffect(() => {
        getUser();
    }, []);

    return (
        <Container>
            <MyInfo onSubmit={onSubmitHandler}>
                {modalOpen &&
                    <ProfileSelectionWrap ref={modalBackground} onClick={e => {
                        if (e.target === modalBackground.current) {
                            setModalOpen(false);
                        }
                    }}>
                        <ProfileSelection isOpen={modalOpen}>
                            {
                                ImageSelect.map((img) => (
                                    <SelectionImageBox>
                                        <SelectionImage src={img.src} onClick={() => HandleProfile(img.src)} />
                                        <p>{img.nick}</p>
                                    </SelectionImageBox>
                                ))
                            }
                        </ProfileSelection>

                    </ProfileSelectionWrap>}
                <FormTitle>
                    <h1>회원 정보</h1>
                </FormTitle>
                <ProfileContainer>
                    <ProfileDiv>
                        <EditProfile onClick={() => setModalOpen(true)}><IoPencil /></EditProfile>
                        <ProfileImage src={userProfile} />
                    </ProfileDiv>

                </ProfileContainer>
                <InfoContainer>
                    <InfoDiv>
                        <Label>이름</Label>
                        <InfoInput
                            value={userName}
                            onChange={onNameHandler}
                        />
                    </InfoDiv>
                    <InfoDiv>
                        <Label>닉네임</Label>
                        <InfoInput
                            value={userNickName}
                            onChange={onNickNameHandler}
                        />
                    </InfoDiv>
                    <InfoDiv>
                        <Label>아이디</Label>
                        <InfoInput
                            value={userlogid}
                            onChange={onlogidHandler}
                            readonly="readonly"
                        />
                    </InfoDiv>
                    <InfoDiv>
                        <Label>전화번호</Label>
                        <InfoInput
                            value={userphone}
                            onChange={onphoneHandler}
                        />
                    </InfoDiv>
                    <InfoSelect>
                        <Label>Level : </Label>
                        <Select
                            onChange={onLevelHandler}
                        >
                            <Option disabled selected value={userLevel}>{userLevel}</Option>

                            <Option value="A">A</Option>
                            <Option value="B">B</Option>
                            <Option value="C">C</Option>
                            <Option value="D">D</Option>
                            <Option value="E">E</Option>
                            <Option value="F">F</Option>
                            <Option value="G">G</Option>
                            <Option value="H">H</Option>
                        </Select>
                    </InfoSelect>
                    <InfoSelect>
                        <Label>부서 : </Label>
                        <Select
                            onChange={onDepartmentHandler}
                        >
                            <Option disabled selected value={userDepartment}>{userDepartment}</Option>
                            <Option value="인프라 사업부">인프라 사업부</Option>
                            <Option value="마케팅 사업부">마케팅 사업부</Option>
                            <Option value="IT 사업부">IT 사업부</Option>
                            <Option value="미디어 사업부">미디어 사업부</Option>
                            <Option value="전략기획실">전략기획실</Option>
                            <Option value="경영지원실">경영지원실</Option>
                            <Option value="문화 콘텐츠LAB">문화 콘텐츠LAB</Option>
                            <Option value="데이터 아카이빙센터">데이터 아카이빙센터</Option>
                        </Select>
                    </InfoSelect>
                    <ItroduceContainer>
                        <Label>소개</Label>
                        <IntroduceInput
                            value={userIntroduce}
                            onChange={onIntroduceHandler}
                            maxLength={"35"}
                            placeholder='35자 제한'
                        />
                    </ItroduceContainer>
                    <ItroduceContainer>
                        <Label>슬로건</Label>
                        <IntroduceInput
                            value={userSlogan}
                            onChange={onSloganHandler}
                            maxLength={"35"}
                            placeholder='35자 제한'
                        />
                    </ItroduceContainer>
                </InfoContainer>

                <BtnContainer>
                    <SubmitButton onClick={() => navigate("/LoggedPage")}>뒤로</SubmitButton>
                    <SubmitButton type="submit">수정</SubmitButton>
                </BtnContainer>

            </MyInfo>

        </Container>
    );
};

export default Mypage;

const Container = styled.div`
  background: ${({ theme }) => theme.colors.BoxColor};
  ${({ theme }) => theme.common.flexCenterColumn};
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  width: 100%;
  height: auto;
  color: white;
  padding: 10% 30%;
  @media screen and (max-width: 900px) {
    padding : 20% 0;
  }
`

const MyInfo = styled.form`
  border: 1px solid #F0BB00;
  ${({ theme }) => theme.common.flexCenterColumn};
  width: 100%;
  height: auto;
  border-radius: 15px;
  display : flex;
  flex-flow : row wrap;
  justify-content : flex-start;
  align-items : center;
  color :white;
  padding: 5%;
  gap : 20px;
`;

const FormTitle = styled.div`
    width: 100%;
`


const ProfileContainer = styled.div`
    display : flex;
    flex-flow :column wrap;
    height : 100%;
    position : relative;
`
const ProfileDiv = styled.div`
    width : 150px;
    height : 150px;
    border-radius : 50%;
    display : flex;
    justify-content : center;
    align-items : center;
    background-image: linear-gradient(to top right, blue, orange);
    position : relative;
`
const EditProfile = styled.div`
    width : 30px;
    height : 30px;
    border-radius : 50%;
    position : absolute;
    background: rgba(0,0,0,0.5);
    top : 5%;
    left : 5%;
    border : 1px solid grey;
    color :white;
    display : flex;
    justify-content :center;
    align-items: center;
`
const ProfileImage = styled.img`
    width : 95%;
    height : 95%;
    border-radius : 50%;
`
const ProfileSelectionWrap = styled.div`
    position : absolute;
    width : 100%;
    height : 100%;
    top:50%;
    left : 50%;
    transform: translate(-50%,-50%);
    z-index : 1;
    background : rgba(0,0,0,0.3);
`
const ProfileSelection = styled.div`
    width : 50%;
    height : 50%;
    border-radius : 20px;
    position : absolute;
    top:50%;
    left : 50%;
    transform: translate(-50%,-50%);
    transition : all ease 1s;
    display :flex;
    flex-flow :row wrap;
    justify-content : center;
    align-items :center;
    background : #fff;
    padding: 3%  5%;;
    gap : 20px;
    overflow : scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    @media screen and (max-width: 900px) {
        width : 90%;
        height : 80%;
        padding: 5%;
    }
`
const SelectionImageBox = styled.div`
    padding : 10px;
    border-radius : 50%;
    display :flex;
    flex-flow :column wrap;
    justify-content : center;
    align-items :center;
    color : black;
`
const SelectionImage = styled.img`
    width : 150px;
    height : 150px;
    border-radius : 50%;
    @media screen and (max-width: 900px) {
        width : 100px;
        height : 100px;
    }
`
const InfoContainer = styled.div`
    width: 100%;
    flex : 1;
    display : flex;
    flex-flow : row wrap;
    justify-content :flex-start;
    align-items: center;
    gap : 10px;
`

const InfoDiv = styled.div`
    display : flex;
    flex-flow : row nowrap;
    justify-content : flex-start;
    align-items: center;
    border: 1px solid #F0BB00;
    background: #212121;
    border-radius: 36px;
    gap : 10px;
    color: white;
    overflow : hidden;
    width : 300px;
    padding : 14px;
`
const InfoInput = styled.input`
    display: flex;
    align-items: center;
    background: #232323;
    border : none;
    color: white;
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
    outline :none;
`
const InfoSelect = styled.div`
    display : flex;
    flex-flow : row nowrap;
    justify-content : flex-start;
    align-items: center;
    border: 1px solid #F0BB00;
    background: #212121;
    border-radius: 36px;
    gap : 10px;
    padding : 14px;
    overflow : hidden;
    background: #F0BB00;
    color : black;
    ${({ theme }) => theme.fontStyle.Text_Paragraph};

`
const Select = styled.select`
    min-width : 80px;
    display: flex;
    align-items: center;
    text-align : center;
    color : black;
    background : transparent;
    outline: none;
    border : none;
    -webkit-appearance: none; /* 기본 화살표 숨기기 */
    -moz-appearance: none;
    appearance: none;
    outline :none;
    ${({ theme }) => theme.fontStyle.Text_Paragraph};
`
const Option = styled.option`
  display:flex;
  justify-content : center;
  align-items : center;
  text-align : center;
`

const Label = styled.label`
    display: flex;
    align-items: center;
    text-align : left;
`

const ItroduceContainer = styled.div`
    display : flex;
    flex-flow : row nowrap;
    justify-content : flex-start;
    align-items: center;
    border: 1px solid #F0BB00;
    background: #212121;
    border-radius: 36px;
    gap : 10px;
    color: white;
    overflow : hidden;
    padding : 14px;
    width :100%;
`
const IntroduceInput = styled.input`
    display: flex;
    flex : 1;
    align-items: center;
    background: #232323;
    border : none;
    color: white;
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
    outline :none;
`
const BtnContainer = styled.div`
    display: flex;
    width : 100%;
    justify-content : space-between;
`
const SubmitButton = styled.button`
background: #F0BB00;
color : black;
justify-content: center;
align-items: center;  
${({ theme }) => theme.fontStyle.Text_Paragraph};
outline:none;
border : none;
padding: 14px;
`


const ImageSelect = [
    {
        nick: "고릴라",
        src: "https://aurumnexus-imagebucket.s3.ap-northeast-2.amazonaws.com/File/MembersA.webp",
    },
    {
        nick: "B",
        src: "https://aurumnexus-imagebucket.s3.ap-northeast-2.amazonaws.com/File/MembersB.webp",
    },
    {
        nick: "C",
        src: "https://aurumnexus-imagebucket.s3.ap-northeast-2.amazonaws.com/File/MembersC.webp",
    },
    {
        nick: 1,
        src: "https://aurumnexus-imagebucket.s3.ap-northeast-2.amazonaws.com/File/MembersD.webp",
    },
    {
        nick: 1,
        src:"https://aurumnexus-imagebucket.s3.ap-northeast-2.amazonaws.com/File/MembersE.webp",
    },
    {
        nick: 1,
        src: "https://aurumnexus-imagebucket.s3.ap-northeast-2.amazonaws.com/File/MembersF.webp",
    },
    {
        nick: 1,
        src: "https://aurumnexus-imagebucket.s3.ap-northeast-2.amazonaws.com/File/MembersG.webp",
    },
    {
        nick: 1,
        src: "https://aurumnexus-imagebucket.s3.ap-northeast-2.amazonaws.com/File/MembersH.webp",
    },
    {
        nick: 1,
        src: "https://aurumnexus-imagebucket.s3.ap-northeast-2.amazonaws.com/File/MembersI.webp",
    },
    {
        nick: 1,
        src: "https://aurumnexus-imagebucket.s3.ap-northeast-2.amazonaws.com/File/MembersJ.webp",
    },
    {
        nick: 1,
        src: "https://aurumnexus-imagebucket.s3.ap-northeast-2.amazonaws.com/File/MembersK.webp",
    },
];







