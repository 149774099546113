import React, { useState, useMemo, useRef } from 'react';
import styled from 'styled-components';
import api from '../../utils/api';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "axios";
import { Navigate, useNavigate } from 'react-router-dom';
import ImageResize from '@looop/quill-image-resize-module-react';

Quill.register('modules/imageResize', ImageResize)


const TextEditor = () => {
    const quillRef = useRef(null);
    const [Title, setTitle] = useState("");
    const [error, setError] = useState("");
    const [content, setContent] = useState("");
    const validTags = ['Media', 'Service', 'Cooperation', 'Invest', 'Recruit', 'Social', 'Admin'];
    const [Tag, setTag] = useState(validTags[0]);
    const [isTagOpen, setIsTagOpen] = useState(false);
    const [Thumbnail,setThumbnail]= useState(null);

    const navigate = useNavigate();
    const handelThumbnail =(url)=>{
        if(Thumbnail){
            console.log("이미 있소!")
            return;
        }
        else{
            console.log("이미지 넣는다!")
            setThumbnail(url)
        }
    }

    const onTitleHandler = (event) => {
        setTitle(event.currentTarget.value);
    }

    const onTagHandler = (tag) => {
        setTag(tag);
    }

    const onSubmitHandler = async (event) => {
        event.preventDefault();

        try {
            const response = await api.post('/columns', { Title, content, Tag, Thumbnail });
            if (response.status == 200) {
                navigate("/columns")
            }
            else {
                throw new Error(response.data.error)
            }
        } catch (error) {
            setError(error.message);
        }
    }


    //이미지 핸들러 탈취!

    const imageHandler = () => {
        // console.log('imageHandler');
        const input = document.createElement('input'); //input 태그 생성
        input.setAttribute('type', "file"); //input 타입을 파일로 설정
        input.setAttribute('accept', 'image/*'); //input 파일 형식을 이미지로 설정 (이미지만 업로드 가능)
        input.click(); //input을 클릭

        input.addEventListener('change', async () => { //input에 파일을 선택했을 때
            const file = input.files[0]; //선택한 파일을 가져옴

            try {
                const formData = new FormData(); //formdata 객체 생성 파일을 전달할때 보통 formdata를 사용
                formData.append('file', file); //formdata에 파일을 추가 (키, 값)

                const response = await axios.post('api/image/upload', formData, { //서버에 파일을 업로드
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                });
                if (response.status == 200) { //응답이 정상적으로 왔을 때
                    const data = response.data; //응답을 json으로 변환
                    const imgUrl = data.imageUrl; //이미지 url을 가져옴
                    handelThumbnail(imgUrl);
                    const editor = quillRef.current.getEditor(); //quill 객체를 가져옴
                    const range = editor.getSelection(); //현재 커서 위치를 가져옴
                    editor.insertEmbed(range.index, 'image', imgUrl); //커서 위치에 이미지를 삽입
                    editor.setSelection(range.index + 1); //커서를 이미지 다음으로 이동
                } else { //응답이 정상적이지 않을 때
                    console.error('Error uploading image:', response.statusText); //에러 메시지 출력
                }
            } catch (error) { //에러가 발생했을 때
                setError('Error uploading image:', error); //에러 메시지 출력
            }
        });
    };

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [{ size: ['small', false, 'large', 'huge'] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ["image"],
                    [{ align: [] }, { color: [] }, { background: [] }],
                ],
                handlers: {
                    image: imageHandler,
                    ImageResize: { modules: ['Resize'] },
                },
            },
            
        };
    }, []);

    return (
        <Container>
            <PostDiv>
                {error && <p style={{ color: "red" }}><b>{error}</b></p>}
                <SubmitForm
                    onSubmit={onSubmitHandler}
                    method="POST"
                    enctype="multipart/form-data">
                    <TagContainer>
                        <select onChange={onTagHandler}>
                            {validTags.map((tag)=>(
                                <option value={tag}>{tag}</option>
                            ))}
                        </select>
                    </TagContainer>

                    <InputBox
                        type='Title'
                        name='ID'
                        placeholder='제목'
                        value={Title}
                        onChange={onTitleHandler}
                    />
                    <ReactQuill
                        theme="snow"
                        modules={{
                            ...modules,
                            imageResize: {
                              parchment: Quill.import("parchment"),
                              modules: ["Resize", "DisplaySize", "Toolbar"],
                            },
                          }}
                        onChange={setContent}
                        ref={quillRef}
                        style={{ height: "350px", margin: "25px 0 ", width: '100%' }}
                    />

                    <div style={{ height: "100px", margin: "30px 0", width: '100%' }} >
                        <SubmitButton type='submit'>submit</SubmitButton>
                    </div>
                </SubmitForm>

            </PostDiv>
        </Container>
    );
};

export default TextEditor;

const Container = styled.div`
background: ${({ theme }) => theme.colors.BoxColor};
${({ theme }) => theme.common.flexCenterColumn};
width: 100%;
height: auto;
color: white;
`;

const PostDiv = styled.div`
    background: ${({ theme }) => theme.colors.BoxColor};
    ${({ theme }) => theme.common.flexCenterColumn};
    width: 100%;
    height: auto;
    padding : 20% 20%;
    @media screen and (max-width: 900px) {
        padding : 15% 0%;
    }
`;

const SubmitForm = styled.form`
    ${({ theme }) => theme.common.flexCenterColumn};
    background: #F9F9F9;
    width: 100%;
    height: auto;
    color : black;
    padding : 5%;
`

const InputBox = styled.input`
display: flex;
width: 100%;
padding: 14px;
align-items: center;
border : none;
border-radius: 4px;
margin :25px 0px;
border : 1px solid grey;

`


const TagContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin :25px 0px;
`;



const SubmitButton = styled.button`
    align-self : start;
    width: 100px;
    height: 50px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
    background: #81BEF7;

    @media screen and (max-width: 900px) {
       margin : 50px 0;
    }
`;