import React, { useEffect, useState } from 'react';
import api from '../utils/api';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { IoMdMenu, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const ColumnItem = ({ selectedTag }) => {
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] = useState([]); // 초기값을 빈 배열로 변경
    const filteredColumns = selectedTag ? columns.filter(column => column.Tag === selectedTag) : columns;
    const [Thumbnail, setThumbnail] = useState("https://aurumnexus-imagebucket.s3.ap-northeast-2.amazonaws.com/Logo.png")
    const navigate = useNavigate();
    const GotoDetail = (id) => {
        navigate(`/columns/${id}`)
    }

    const getColumns = async () => {
        try {
            const response = await api.get("/columns");
            setColumns(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching columns:", error);
            setLoading(false); // 데이터를 불러오지 못한 경우에도 로딩을 false로 변경
        }
    }

    //4*7 카드만 보여야함
    useEffect(() => {
        getColumns();
    }, []);

    //페이지네이션
    const [currentPage, setCurrentPage] = useState(1);
    const colPerPage = 6;
    const indexOfLastCol = currentPage * colPerPage;//7까지
    const indexOfFirstCol = 0;//0부터
    //9개씩 나누기
    const currentCol = filteredColumns.slice(indexOfFirstCol, indexOfLastCol);

    const Moreclick = () => {
        setCurrentPage(currentPage + 1);
    }
    return (
        <>
            <ItemBoard>
                {loading ? (
                    <LoadingText>Loading...</LoadingText>
                ) : currentCol.length === 0 ? (
                    <NoDataText>No data available</NoDataText>
                ) : (
                    currentCol.map((column) => (
                        <ItemCard key={column._id} onClick={() => GotoDetail(column._id)}>
                            <ImgComponent imagePath={column.Thumbnail ? column.Thumbnail : Thumbnail} />
                            <ItemP>
                                <Text_SubMessage><b>{column.Title}</b></Text_SubMessage>
                                <Text_Xsmall dangerouslySetInnerHTML={{ __html: truncate(column.content, 80)}}/>
                                <Text_Xsmall style={{color : "grey"}}>{new Date(column.createdAt).toLocaleDateString('ko-KR')}</Text_Xsmall>
                                <ItemTag>#{column.Tag}</ItemTag>
                            </ItemP>
                        </ItemCard>
                    ))
                )}
            </ItemBoard>
            <StyleBtn onClick={() => Moreclick()}><p>더보기</p><IoIosArrowDown></IoIosArrowDown></StyleBtn>

        </>
    );
};
export default ColumnItem;

const ItemBoard = styled.div`
    display: grid;
    width: 100%;
    gap : 10px;
    padding :10% 0%;
    @media screen and (min-width: 901px) {
        grid-template-columns : 1fr 1fr;
        max-width: 980px;
    }
    @media screen and (max-width: 900px) {
        grid-template-columns : 1fr;
    }
`
const ItemCard = styled.div`
    display: flex;
    flex-flow : column;
    align-items: center; 
    width: 100%;
    height : auto;
    position: relative;
    border-bottom : 1px solid #6E6E6E;
    border-radius : 5px;
    &:hover {
        transform: scale(1.05); /* 확대 효과 적용 */
    }
    transition: all 0.3s ease-in-out;
    margin-bottom: 80px;
`;

const ImageContainer = styled.div`
  background-color: transparent;
  margin-bottom: 20px;
  overflow : hidden;
  height : 320px;
  width : 100%;
`;

const ImgComponent = ({ imagePath }) => {
    return (
        <ImageContainer>
            <img
                src={imagePath}
                alt="페이지 이미지"
                style={{
                   width : "100%",
                   height : "100%",
                   objectFit : "cover"
                }}
            />
        </ImageContainer>
    );
};

const ItemP = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    width : 100%;
    text-align : left;
    @media screen and (max-width: 900px) {
        text-align : center;
        gap : 10px;
        padding : 5%;
    }
    gap : 10px;
    margin-bottom: 20px;
`
const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
};

const ItemTag = styled.div`
    ${({ theme }) => theme.fontStyle.Text_Xsmall};
    width : auto;
    color: #5C8AFF;
    font-size : 12px;
`

const StyleBtn = styled.button`
    padding : 10px;
    ${({ theme }) => theme.fontStyle.Text_Paragraph};
    border : none;
    color : white;
    background : transparent;
`

const LoadingText = styled.p`
    color: #fff;
`;

const NoDataText = styled.p`
    color: #fff;
`;

const Text_Message = styled.p`
${({ theme }) => theme.fontStyle.Text_Message};
text-align : left;
    @media screen and (max-width: 900px) {
        text-align : center;
    }
`
const Text_SubMessage = styled.p`
${({ theme }) => theme.fontStyle.Text_SubMessage};
text-align : left;
    @media screen and (max-width: 900px) {
        text-align : center;
    }
`

const Text_Xsmall = styled.p`
${({ theme }) => theme.fontStyle.Text_Xsmall};
text-align : left;
    @media screen and (max-width: 900px) {
        text-align : center;
    }
`