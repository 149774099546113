import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import HeroContainer from '../../Layouts/HeroContainer';
import ColumnItem from '../../Layouts/ColumnItem'
import { useNavigate } from 'react-router-dom';
import { IoMdMenu, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoArrowDown } from 'react-icons/io5';
import EmptyPart from "../../Layouts/EmptyPart"
function Columns({ setUser, user }) {
  const [selectedTag, setSelectedTag] = useState(''); // 선택된 태그를 추적합니다.
  const navigate = useNavigate();
  const [isOpen, SetIsOpen] = useState(true);
  const handleTag = (tag) => {
    MenuOpen();
    setSelectedTag(tag);
  };
  const goToEdit = (link) => {
    if (user) {
      navigate(link);
    }
    else {
      alert("오럼의 구성원만 글을 작성할 수 있습니다.")
    }
  };

  const MenuOpen = () => {
    if (isOpen) {
      SetIsOpen(false)
    }
    else {
      SetIsOpen(true)
    }
  };

  return (
    <Container>
      <HeroContainer imgPath={"/ImgSrc/ColumnsHero.webp"} pageName={"안내드립니다"} />
      <TagDiv>
      {Category.map((Menu, index) => (
            <StyleBtn  key={Menu.index}
            onClick={() => handleTag(Menu.Key)} selected={selectedTag === Menu.Key}>{Menu.Category}</StyleBtn>
          ))}
      </TagDiv>
      
      <ToggleDiv> 
        <ToggleMenuDiv isOpen={isOpen}>
        <MenuBtn onClick={() => MenuOpen()}  isOpen={isOpen} style={{color:"#000"}}> 
        { selectedTag === "" ? '전체' : selectedTag}<Arrow isOpen={isOpen}/>
        </MenuBtn>
          {Category.map((Menu, index) => (
            <MenuBtn  key={Menu.index}
            onClick={() => handleTag(Menu.Key)} selected={selectedTag === Menu.Key}>{Menu.Category}</MenuBtn>
          ))}
        </ToggleMenuDiv>
      </ToggleDiv>
      <ColumnItem selectedTag={selectedTag} />
          <EmptyPart/>
    </Container>
  );
}

export default Columns;

const Container = styled.div`
background: ${({ theme }) => theme.colors.BoxColor};
${({ theme }) => theme.common.flexCenterColumn};
width: 100%;
height: auto;
color: white;
`;

const ToggleDiv = styled.div`
  background: ${({ theme }) => theme.colors.BoxColor};
  display: flex;
  width: 100%;
  color: white;
  position: relative;
  height : 100px;
  @media screen and (min-width: 901px) {
    display:none;
    }
`;
const ToggleMenuDiv = styled.div`
  display : flex; 
  flex-flow : column wrap;
  justify-content:flex-start;
  align-items:center;
  position : absolute;
  z-index :2;
  top : 50%;
  left : 5%;
  translate : transform(-50%,-50%);
  transition : all ease 0.5s;
  overflow: hidden;
  width  : 35%;
  height :  ${({ isOpen }) => isOpen ? '40px' : '500px'}; 
  overflow: hidden;
  border: none;

  `;

  const MenuBtn = styled.button`
  width : 100%;
  height : 40px;
  display: flex;
  background: #fff;
  ${({ theme }) => theme.fontStyle.Text_Xsmall};
  color: ${({ selected }) => selected ? '#00000' : '#6E6E6E'}; // 선택된 상태일 때 파란색, 아니면 흰색
  justify-content: space-between;
  padding : 3px 10px 3px 10px;
  align-items: center;
  border: none;
  `;

  const Arrow = styled(IoIosArrowDown)`
  transform :  ${({ isOpen }) => isOpen ? 'rotate(0deg)' : 'rotate(900deg)'};
  transition : all ease 0.5s;
  font-size : 18px;
`;


const TagDiv = styled.div`
background: ${({ theme }) => theme.colors.BoxColor};
${({ theme }) => theme.common.flexCenterRow};
justify-content : center;
height: auto;
width : 100%;
color: white;
padding : 2% 0%; 
border-bottom : 1px solid grey;
@media screen and (max-width: 900px) {
  display : none;
  }
`;

const StyleBtn = styled.button`
  background: transparent;
  color: ${({ selected }) => selected ? '#fff' : '#6E6E6E'}; // 선택된 상태일 때 파란색, 아니면 흰색
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  padding: 0 25px;
  border : none;
  display : flex
  justify-content: center;
  align-items: center;
  border: none;
  border-right: 2px solid #6E6E6E;
`
const Category = [
  {
    index: "1",
    Category: "전체",
    Key: ""
  },
  {
    index: "2",
    Category: "Media",
    Key: "Media"
  },
  {
    index: "3",
    Category: "Service",
    Key: "Service"
  },
  {
    index: "4",
    Category: "Cooperation",
    Key: "Cooperation"
  },
  {
    index: "5",
    Category: "Investment",
    Key: "Investment"
  },
  {
    index: "6",
    Category: "Recruit",
    Key: "Recruit"
  },
  {
    index: "7",
    Category: "Social",
    Key: "Social"
  },
  {
    index: "8",
    Category: "Admin",
    Key: "Admin"
  },
];