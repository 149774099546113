import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { IoIosArrowForward } from "react-icons/io";
import api from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import EmptyPart from '../../Layouts/EmptyPart';
import {IoIosArrowDown } from "react-icons/io";

function Legacy() {
  const [posting, setPosting] = useState([]); // 초기값을 빈 배열로 변경
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const GetLegacy = async () => {
    try {
      const response = await api.get("/legacy");
      console.log("Fetched data:", response.data.data); // 데이터를 확인하는 로그
      setPosting(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching legacy:", error);
      setLoading(false);
    }
  }

  useEffect(() => {
    GetLegacy();
  }, []);

  const GotoDetail = (id) => {
    navigate(`/Legacy/${id}`)
  }
  //페이지네이션
  const [currentPage, setCurrentPage] = useState(1);
  const postingPerPage = 8;
  const indexOfLastPost = currentPage * postingPerPage;//12까지
  const indexOfFirstPost = 0;//0부터
  //12개씩 나누기
  const slicedPosting = posting.slice(indexOfFirstPost, indexOfLastPost);

  const Moreclick =()=>{
      setCurrentPage(currentPage + 1);
  }

  return (
    <Container>
      <HeaderDiv>
        <HeaderWrapper>
        <Header>흔적들</Header>
        <Record>
          <p>Aurum Legacy</p>
          <h3><span>Essay Of</span> <span>Aurum.Inc</span></h3>
        </Record>
        </HeaderWrapper>
      </HeaderDiv>

      <WrapContainer>
        {loading ? (
          <LoadingText>Loading...</LoadingText>
        ) : slicedPosting && slicedPosting.length === 0 ? (
          <NoDataText>No data available</NoDataText>
        ) : (
          slicedPosting && slicedPosting.map((post) => (
            <ColumnCard>
              <Title>{truncate(post.Title, 20)}</Title>
              {post.Image[0] && <ContentImg src={post.Image[0]}/>}
              <ContentDiv>
                <Titlediv>
                  <Author>@{post.Author} </Author>
                  <DateTxt>{new Date(post.createdAt).toLocaleDateString('ko-KR')}</DateTxt>
                </Titlediv>
                {post.Image.length>0&&<Content>{truncate(post.Content, 120)}</Content>}
                {post.Image.length===0&&<Content>{truncate(post.Content, 250)}</Content>}
              </ContentDiv>
              <MoreBtn onClick={() => GotoDetail(post._id)}>더 알아보기<IoIosArrowForward /></MoreBtn>
              
              <AfterColumnCard onClick={() => GotoDetail(post._id)}>
                <Content>{truncate(post.Content, 450)}</Content>
              </AfterColumnCard>


            </ColumnCard>
          ))
        )}
      </WrapContainer>
      {slicedPosting>postingPerPage&&<StyleBtn onClick={() => Moreclick()}><p>더보기</p><IoIosArrowDown></IoIosArrowDown></StyleBtn>}

      <EmptyPart />
    </Container>
  );
}

export default Legacy;

const Container = styled.div`
background: ${({ theme }) => theme.colors.BoxColor};
${({ theme }) => theme.common.flexCenterColumn};
width: 100%;
height: auto;
color: white;
overflow-x : hidden;
`;

const HeaderDiv = styled.div`
  width: 100%;
  padding : 10% 20% 0% 20%;
  @media screen and (max-width: 900px) {
    padding : 20% 5% 5% 5%;
  }
  `;
const HeaderWrapper = styled.div`
  background: ${({ theme }) => theme.colors.BoxColor};
  ${({ theme }) => theme.common.flexCenterRow};
  flex-flow : row wrap;
  width: 100%;
  justify-content : flex-start;
  align-items : center;
  gap : 20px;
  border-left : 5px solid grey;
  padding : 0 20px;
  @media screen and (max-width: 900px) {
    flex-flow : column wrap;
    align-items : flex-start;
    border-left : none;
    border-bottom : 1px solid grey;
    gap : 5px;
  }
  `;
const Header = styled.div`
  ${({ theme }) => theme.fontStyle.Text_Hero};
  margin:0;
  display : flex;
`;

const Record = styled.div`
  ${({ theme }) => theme.fontStyle.Text_SubMessage};
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start; /* 올바르게 수정됨 */
  text-align: left;
`;

const WrapContainer = styled.div`
width: 100%;
height: auto;
color: white;
padding : 5% 20%;
display: flex;
flex-flow : wrap;
justify-content : flex-start;
gap : 20px;
@media screen and (max-width: 900px) {
  padding : 10% 5%;
 }
`;

const ColumnCard = styled.div`
  width: 45%;
  height: auto;
  display : flex;
  flex-flow : row wrap;
  justify-content : flex-start;
  align-items : flex-start;
  color: black;
  background : white;
  padding: 2%;
  gap : 20px;
  position : relative;
  box-shadow: 0 3px 6px rgba(255,255,255,0.16), 0 3px 6px rgba(255,255,255,0.23);
  @media screen and (max-width: 900px) {
    flex-flow : column wrap;
    justify-content : flex-start;
    align-items : flex-start;
    gap :20px;
    width: 100%;
    border-radius : 10px;
  }
`;
const AfterColumnCard = styled.div`
  position : absolute;
  transform : translate(-50%,-50%);
  top : 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  display : flex;
  flex-flow : row wrap;
  align-items : center;
  color: white;
  padding: 2%;
  cursor :pointer;
  transition : all ease 0.5s;
  opacity : 0%;
  &:hover{
    background : black;
    opacity : 85%;
    box-shadow: 0 14px 28px rgba(255,255,255,0.25), 0 10px 10px rgba(255,255,255,0.22);
  }
  @media screen and (max-width: 900px) {
    display : none;
  }
`;
const ContentImg = styled.img`
object-fit : cover;
width : 250px;
height : 250px;
box-shadow: 0 1px 3px rgba(0,0,0,0.16), 0 1px 3px rgba(0,0,0,0.23);
@media screen and (max-width: 900px) {
    width: 100%;
}
`
const ContentDiv = styled.div`
flex : 1;
height :100%;
@media screen and (max-width: 900px) {
  height : auto;
}
`
const Titlediv = styled.div`
  display : flex;
  justify-content : flex-start;
  align-items : center;
  gap :10px;
  border-left : 3px solid #FF8000;
  padding :0 15px;
  margin-bottom : 30px;
  width : auto;
  background-color : rgb(255,165,0,0.1);
  @media screen and (max-width: 900px) {
    margin-bottom : 10px;
    padding :0 5px;
  }
  `;
const Title = styled.div`
  ${({ theme }) => theme.fontStyle.Text_Message};
  width : 100%;
  display : flex;
  align-items : center;
  margin-bottom : 30px;
  @media screen and (max-width: 900px) {
    border-bottom : 2px solid grey;
    margin-bottom : 5px;
  }
`;
const Author = styled.div`
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  display : flex;
  align-items : center;
  margin : 0;
  font-weight : 600;
  color : black;
  text-shadow : 1px 1px 0px rgb(0,0,0,0.1);
  `;

const DateTxt = styled.div`
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  display : flex;
  align-items : center;
  margin : 0;
  color :#6E6E6E;
`;
const Content = styled.div`
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
`;

const MoreBtn = styled.button`
  color : #FF8000;
  border : 2px solid  #FF8000;
  padding : 5px;
  border-radius : 10px;
  ${({ theme }) => theme.fontStyle.Text_Paragraph};
  background : transparent;
  display : none;
  @media screen and (max-width: 900px) {
    display : flex;
    align-items : center;
    align-self: flex-end;
    box-shadow: 0 1px 1px rgba(0,0,0,0.16), 0 1px 1px rgba(0,0,0,0.23);
  }
`

const truncate = (str, n) => {
  return str?.length > n ? str.substr(0, n - 1) + "..." : str;
};
const LoadingText = styled.p`
  color: #fff;
`;

const NoDataText = styled.p`
  color: #fff;
`;


const StyleBtn = styled.button`
    padding : 10px;
    ${({ theme }) => theme.fontStyle.Text_Paragraph};
    border : none;
    color : white;
    background : transparent;
`
